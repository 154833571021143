import React from 'react';
import { LatLngExpression } from 'leaflet';
import { Popup, Polyline } from "react-leaflet";
import { Feature, Section } from '../trackPolyines/trackTypes';
import { getLineState, getRenderDetails, getPositions } from '../mapUtils/mapUtils';

type MapLineProps = {
  section: Section;
  date: Date;
  showAllLines: boolean;
  showUnderConstruction: boolean
  name: string;
};

export const MapSegment = ({ section, date, showAllLines, showUnderConstruction, name }: MapLineProps) => {
  const lineState = getLineState(date, section, showAllLines, showUnderConstruction);
  const renderState = getRenderDetails(lineState);

  return (
    <>
      {lineState.opened && section.segments.map((segment) => {
        const positions = getPositions(date, segment);
        const isTunnelOuter = (segment as Feature).type === 'tunnel'
        return positions.map((item) => {
          const isTunnel = (item as Feature).type === 'tunnel' ? true : isTunnelOuter;
          const positions = (item as Feature).type ? getPositions(date, item) : item;
          return (
            <Polyline
              positions={positions as unknown as LatLngExpression[][]}
              pathOptions={{
                color: renderState.colour,
                weight: renderState.weight,
                lineJoin: 'bevel',
                dashArray: isTunnel ? '6' : '0'
              }}
            >
              {/* <Popup>{name}</Popup> */}
            </Polyline>
          );
        });
      })}
    </>
  );
};

import { Line } from "../../../../trackTypes";

const fairfieldTramway = [
  [-37.77879, 145.01816],
  [-37.75827, 145.02159]
];

const sandringhamBeumaris = [
  [-37.95070, 145.00419],
  [-37.95222, 145.00392],
  [-37.95979, 145.01062],
  [-37.96012, 145.01082],
  [-37.96363, 145.01215],
  [-37.96398, 145.01222],
  [-37.96731, 145.01227],
  [-37.96763, 145.01226],
  [-37.96969, 145.01185],
  [-37.97000, 145.01183],
  [-37.97024, 145.01186],
  [-37.97048, 145.01197],
  [-37.97501, 145.01564],
  [-37.97589, 145.01620],
  [-37.97656, 145.01638],
  [-37.97690, 145.01650],
  [-37.98130, 145.01858],
  [-37.98162, 145.01884],
  [-37.98177, 145.01907],
  [-37.98232, 145.02057],
  [-37.98261, 145.02146],
  [-37.98275, 145.02172],
  [-37.98291, 145.02195],
  [-37.98489, 145.02439],
  [-37.98754, 145.02731],
  [-37.98778, 145.02755],
  [-37.98805, 145.02772],
  [-37.98926, 145.02829],
  [-37.98968, 145.02856],
  [-37.99271, 145.03100],
  [-37.99291, 145.03120],
  [-37.99305, 145.03146],
  [-37.99402, 145.03375],
  [-37.99347, 145.03442],
  [-37.99326, 145.03480],
  [-37.99315, 145.03517],
  [-37.99305, 145.03570],
  [-37.99306, 145.03620],
  [-37.99330, 145.03814],
  [-37.99327, 145.03846],
  [-37.99314, 145.03871],
  [-37.99211, 145.03999],
  [-37.99182, 145.04018],
  [-37.99148, 145.04026]
];

const beumarisCheltenham = [
  [-37.99148, 145.04026],
  [-37.99017, 145.04053],
  [-37.98995, 145.04065],
  [-37.98976, 145.04087],
  [-37.98895, 145.04272],
  [-37.98877, 145.04295],
  [-37.98855, 145.04305],
  [-37.97883, 145.04492],
  [-37.97982, 145.05304],
  [-37.96789, 145.05523]
];

const boxHillDoncaster = [
  [-37.81781, 145.12398],
  [-37.80649, 145.12606],
  [-37.80534, 145.12617],
  [-37.79955, 145.12728],
  [-37.79869, 145.12723],
  [-37.79529, 145.12621],
  [-37.79389, 145.12603],
  [-37.78758, 145.12493]
];

const royalPark = [
  [-37.78468, 144.95909],
  [-37.78449, 144.95641],
  [-37.78432, 144.95597],
  [-37.78211, 144.95323],
  [-37.78178, 144.95273],
  [-37.78155, 144.95193]
];

export const melbourneIndependentTrams: Line = {
  name: 'Melbourne Trams (Independent)',
  state: 'VIC',
  segments: [
    {
      segments: [fairfieldTramway],
      history: {
        opened: {
          date: '1884-12-20',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1886-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandringhamBeumaris],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1914-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [beumarisCheltenham],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1912-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boxHillDoncaster],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1896-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [royalPark],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1923-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../../../trackTypes";

const flemingtonRdConnector = [
  [-37.79367, 144.94755],
  [-37.79340, 144.94721]
];

const westBrunswick = [
  [-37.81763, 144.95912],
  [-37.80964, 144.95545],
  [-37.80921, 144.95540],
  [-37.80124, 144.95680],
  [-37.80090, 144.95671],
  [-37.79367, 144.94755],
  [-37.79354, 144.94749],
  [-37.79338, 144.94757],
  [-37.79281, 144.94825],
  [-37.79253, 144.94853],
  [-37.79096, 144.94962],
  [-37.79058, 144.94979],
  [-37.79023, 144.94982],
  [-37.78952, 144.94979],
  [-37.78889, 144.94984],
  [-37.78843, 144.94980],
  [-37.78408, 144.94868],
  [-37.78360, 144.94867],
  [-37.78323, 144.94877],
  [-37.78278, 144.94903],
  [-37.78219, 144.94950],
  [-37.78198, 144.94957],
  [-37.78130, 144.94954],
  [-37.78111, 144.94965],
  [-37.78101, 144.94987],
  [-37.78089, 144.95102],
  [-37.78075, 144.95128],
  [-37.78057, 144.95136],
  [-37.78000, 144.95138],
  [-37.77934, 144.95128],
  [-37.77862, 144.95098],
  [-37.77772, 144.95031],
  [-37.77621, 144.94897],
  [-37.77597, 144.94883],
  [-37.77567, 144.94878],
  [-37.77016, 144.94984],
  [-37.76944, 144.94385],
  [-37.76929, 144.94346],
  [-37.76905, 144.94319],
  [-37.76883, 144.94307],
  [-37.76855, 144.94304],
  [-37.76613, 144.94342],
  [-37.76575, 144.94360],
  [-37.75874, 144.94485]
];

const westCoburg = [
  [-37.75874, 144.94485],
  [-37.75379, 144.94582],
  [-37.75355, 144.94578],
  [-37.75334, 144.94574],
  [-37.74950, 144.94645],
  [-37.74932, 144.94645],
  [-37.74916, 144.94638],
  [-37.74706, 144.94448],
  [-37.74692, 144.94441],
  [-37.74678, 144.94440],
  [-37.73938, 144.94569]
];

const haymarketConnector = [
  [-37.80165, 144.95738],
  [-37.80131, 144.95713],
  [-37.80090, 144.95671]
];

export const north8: Line = {
  name: 'North8',
  state: 'VIC',
  segments: [
    {
      segments: [flemingtonRdConnector],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [westBrunswick],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [westCoburg],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [haymarketConnector],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};

import { Line } from "../../../../trackTypes";

const linePart1 = [
  [-34.92437, 138.59963],
  [-34.92488, 138.58930],
  [-34.92489, 138.58785],
  [-34.92494, 138.58770],
  [-34.92503, 138.58761],
  [-34.92510, 138.58752],
  [-34.93074, 138.58800]
]

const linePart2 = [
  [-34.93352, 138.58818],
  [-34.93326, 138.59333],
  [-34.93321, 138.59343],
  [-34.93315, 138.59348],
  [-34.93308, 138.59352],
  [-34.93225, 138.59347],
  [-34.93181, 138.59412],
  [-34.93043, 138.59401]
];

const linePart3 = [
  [-34.92895, 138.59391],
  [-34.92867, 138.59922]
];

const victoriaSquare = [
  [-34.92867, 138.59922],
  [-34.92864, 138.60002]
];

const franklinStLoopSouth = [
  [-34.93010, 138.60012],
  [-34.93012, 138.59926],
  [-34.92867, 138.59922]
];

const franklinStLoop = [
  [-34.92867, 138.59922],
  [-34.92720, 138.59906],
  [-34.92747, 138.59380]
];

export const west3: Line = {
  name: 'West3',
  state: 'SA',
  segments: [
    {
      segments: [linePart1, linePart2, linePart3],
      history: {
        opened: {
          date: '1911-10-04',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaSquare],
      history: {
        opened: {
          date: '1911-10-04',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1966-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [franklinStLoopSouth],
      history: {
        opened: {
          date: '1952-02-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1966-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [franklinStLoop],
      history: {
        opened: {
          date: '1952-02-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-04-25',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../../../trackTypes";

const pointOrmond = [
  [-37.88177, 144.97855],
  [-37.88384, 144.99699]
];

const brightonRoad = [
  [-37.88384, 144.99699],
  [-37.88433, 145.00127]
];

const elsternwickGlenhuntly = [
  [-37.88433, 145.00127],
  [-37.88602, 145.01495],
  [-37.88971, 145.04460]
];

const elsternwickCaulfield = [
  [-37.88602, 145.01495],
  [-37.87915, 145.01631],
  [-37.88140, 145.03465],
  [-37.87686, 145.03547],
  [-37.87700, 145.03674],
  [-37.87691, 145.03789],
  [-37.87690, 145.03922],
  [-37.87714, 145.04060]
];

const caufieldCarnegie = [
  [-37.88971, 145.04460],
  [-37.89084, 145.05400],
  [-37.89090, 145.05411],
  [-37.89103, 145.05416],
  [-37.89130, 145.05412],
  [-37.89193, 145.05414],
  [-37.89255, 145.05436],
  [-37.89328, 145.05491],
  [-37.89373, 145.05555],
  [-37.89395, 145.05600]
];

export const east10: Line = {
  name: 'East10',
  state: 'VIC',
  segments: [
    {
      segments: [elsternwickGlenhuntly],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1902-01-01',
          status: 'closed'
        }, {
          date: '1913-01-01',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [pointOrmond],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brightonRoad],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [elsternwickCaulfield],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1902-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [caufieldCarnegie],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};

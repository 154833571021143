import { Line } from "../../../trackTypes";

const stageOne = [
  [-27.9612, 153.3816],
  [-27.9635, 153.3850],
  [-27.9639, 153.3853],
  [-27.9646, 153.3854],
  [-27.9654, 153.3856],
  [-27.9664, 153.3862],
  [-27.9672, 153.3870],
  [-27.9677, 153.3882],
  [-27.9686, 153.3927],
  [-27.9692, 153.3937],
  [-27.9700, 153.3936],
  [-27.9712, 153.4021],
  [-27.9718, 153.4063],
  [-27.9675, 153.4142],
  [-27.9731, 153.4157],
  [-27.9733, 153.4172],
  [-27.9734, 153.4181],
  [-27.9736, 153.4195],
  [-27.9738, 153.4199],
  [-27.9741, 153.4202],
  [-27.9794, 153.4218],
  [-27.9808, 153.4224],
  [-27.9859, 153.4267],
  [-27.9878, 153.4277],
  [-27.9893, 153.4280],
  [-27.9905, 153.4280],
  [-27.9910, 153.4287],
  [-27.9921, 153.4291],
  [-27.9933, 153.4292],
  [-27.9948, 153.4294],
  [-27.9957, 153.4293],
  [-27.9981, 153.4285],
  [-27.9996, 153.4285],
  [-28.0001, 153.4284],
  [-28.0005, 153.4282],
  [-28.0009, 153.4282],
  [-28.0025, 153.4286],
  [-28.0036, 153.4287],
  [-28.0044, 153.4288],
  [-28.0051, 153.4288],
  [-28.0054, 153.4288],
  [-28.0065, 153.4292],
  [-28.0105, 153.4295],
  [-28.0131, 153.4297],
  [-28.0156, 153.4292],
  [-28.0164, 153.4292],
  [-28.0185, 153.4293],
  [-28.0222, 153.4286],
  [-28.0231, 153.4286],
  [-28.0257, 153.4292],
  [-28.0309, 153.4303],
  [-28.0317, 153.4303],
  [-28.0339, 153.4308],
  [-28.0344, 153.4308],
  [-28.0361, 153.4312]
];

const stageTwo = [
  [-27.9255, 153.3393],
  [-27.9275, 153.3389],
  [-27.9296, 153.3389],
  [-27.9326, 153.3392],
  [-27.9361, 153.3400],
  [-27.9410, 153.3423],
  [-27.9482, 153.3451],
  [-27.9505, 153.3457],
  [-27.9509, 153.3459],
  [-27.9513, 153.3465],
  [-27.9515, 153.3468],
  [-27.9523, 153.3475],
  [-27.9560, 153.3499],
  [-27.9592, 153.3513],
  [-27.9601, 153.3519],
  [-27.9608, 153.3529],
  [-27.9614, 153.3544],
  [-27.9638, 153.3698],
  [-27.9639, 153.3710],
  [-27.9638, 153.3739],
  [-27.9637, 153.3744],
  [-27.9634, 153.3748],
  [-27.9621, 153.3760],
  [-27.9598, 153.3779],
  [-27.9596, 153.3781],
  [-27.9595, 153.3783],
  [-27.9595, 153.3786],
  [-27.9595, 153.3789],
  [-27.9599, 153.3797],
  [-27.9607, 153.3807],
  [-27.9612, 153.3816]
];

const stageThree = [
  [-28.0361, 153.4312],
  [-28.0366, 153.4318],
  [-28.0389, 153.4333],
  [-28.0395, 153.4336],
  [-28.0578, 153.4381],
  [-28.0587, 153.4381],
  [-28.0609, 153.4372],
  [-28.0617, 153.4371],
  [-28.0643, 153.4382],
  [-28.0696, 153.4425],
  [-28.0701, 153.4428],
  [-28.0726, 153.4440],
  [-28.0846, 153.4485],
  [-28.0881, 153.4503],
  [-28.0884, 153.4507],
  [-28.0886, 153.4523],
  [-28.0889, 153.4527],
  [-28.0894, 153.4529],
  [-28.0899, 153.4528],
  [-28.0904, 153.4530],
  [-28.0910, 153.4537]
];

export const goldCoastLightRail: Line = {
  name: 'Gold Coast Light Rail',
  state: 'QLD',
  segments: [
    {
      segments: [stageOne],
      history: {
        opened: {
          date: '2014-07-20',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [stageTwo],
      history: {
        opened: {
          date: '2017-12-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [stageThree],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    }
  ]
}

import { Line } from "../../../../trackTypes";

const victoriaParadeVictoriaBridge = [
  [-37.80829, 144.97674],
  [-37.80978, 144.99067],
  [-37.80977, 144.99116],
  [-37.80969, 144.99191],
  [-37.80971, 144.99249],
  [-37.81141, 145.00937],
  [-37.81144, 145.01006],
  [-37.81188, 145.01375]
];

const victoriaBridgeBooroondaraCemetary = [
  [-37.81188, 145.01375],
  [-37.81205, 145.01474],
  [-37.81311, 145.02352],
  [-37.80762, 145.02870],
  [-37.80746, 145.02896],
  [-37.80681, 145.03071],
  [-37.80254, 145.04256]
];

const booroondaraCemetaryHighStreet = [
  [-37.80254, 145.04256],
  [-37.80120, 145.04631],
  [-37.79357, 145.06370]
];

const highStreetExtension = [
  [-37.79357, 145.06370],
  [-37.79339, 145.06417],
  [-37.79255, 145.07000]
];

const northBalwyn = [
  [-37.79255, 145.07000],
  [-37.79257, 145.07030],
  [-37.79417, 145.07979],
  [-37.79466, 145.08467]
];

const connector = [
  [-37.81997, 145.01649],
  [-37.81991, 145.01669],
  [-37.81311, 145.02352]
];

export const east2: Line = {
  name: 'East2',
  state: 'VIC',
  segments: [
    {
      segments: [victoriaParadeVictoriaBridge],
      history: {
        opened: {
          date: '1886-11-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1929-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [victoriaBridgeBooroondaraCemetary],
      history: {
        opened: {
          date: '1887-11-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1915-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [booroondaraCemetaryHighStreet],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [highStreetExtension],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [northBalwyn],
      history: {
        opened: {
          date: '1938-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [connector],
      history: {
        opened: {
          date: '1923-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};

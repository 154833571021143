import { Line } from "../../../../trackTypes";

const sturtStreet = [
  [-37.82052, 144.96884],
  [-37.82079, 144.96828],
  [-37.82358, 144.96733]
];

const southbankBvld = [
  [-37.82330, 144.97012],
  [-37.82366, 144.96892],
  [-37.82367, 144.96860],
  [-37.82347, 144.96765],
  [-37.82358, 144.96733]
];

const line = [
  [-37.82358, 144.96733],
  [-37.83175, 144.96486],
  [-37.83289, 144.96539],
  [-37.83310, 144.96538],
  [-37.83420, 144.96481],
  [-37.83434, 144.96464],
  [-37.83522, 144.96160],
  [-37.83912, 144.96339],
  [-37.83930, 144.96334],
  [-37.84033, 144.96170],
  [-37.84098, 144.96083],
  [-37.84168, 144.96006],
  [-37.84228, 144.95944],
  [-37.84239, 144.95940],
  [-37.84251, 144.95941],
  [-37.84390, 144.96024],
  [-37.84402, 144.96026],
  [-37.84416, 144.96020],
  [-37.84936, 144.95590],
  [-37.84949, 144.95588],
  [-37.84958, 144.95595],
  [-37.85356, 144.96360],
  [-37.85382, 144.96388],
  [-37.85733, 144.97065]
];

const parkStreet = [
  [-37.85733, 144.97065],
  [-37.85762, 144.97118],
  [-37.85778, 144.97139],
  [-37.86133, 144.97470]
];

const beaconsfieldParade = [
  [-37.85733, 144.97065],
  [-37.85822, 144.96988],
  [-37.85846, 144.97025],
  [-37.86202, 144.97361]
];

const connector = [
  [-37.83230, 144.97177],
  [-37.83342, 144.96780],
  [-37.83078, 144.96516]
];

const millsStreetSiding = [
  [-37.84936, 144.95590],
  [-37.85016, 144.95525]
];

export const south7: Line = {
  name: 'South7',
  state: 'VIC',
  segments: [
    {
      segments: [
        {
          date: '1970-01-01',
          original: [sturtStreet],
          deviation: [southbankBvld]
        },
        line,
        {
          date: '1959-01-01',
          original: [beaconsfieldParade],
          deviation: [parkStreet]
        },
        connector,
        millsStreetSiding
      ],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};

import { Line } from "../../../trackTypes";

const portAhuruiLine = [
  [-39.4944, 176.9058],
  [-39.4917, 176.9163],
  [-39.4912, 176.9183],
  [-39.4902, 176.9185],
  [-39.4882, 176.9175],
  [-39.4878, 176.9152],
  [-39.4867, 176.9127],
  [-39.4865, 176.9125],
  [-39.4862, 176.9124],
  [-39.4858, 176.9127],
  [-39.4855, 176.9127],
  [-39.4848, 176.9126],
  [-39.4847, 176.9124],
  [-39.4844, 176.9107],
  [-39.4840, 176.9102],
  [-39.4824, 176.9096],
  [-39.4819, 176.9096],
  [-39.4811, 176.9097],
  [-39.4806, 176.9097],
  [-39.4800, 176.9095],
  [-39.4793, 176.9087],
  [-39.4788, 176.9072],
  [-39.4799, 176.8997],
  [-39.4804, 176.8988],
  [-39.4806, 176.8981],
  [-39.4811, 176.8978]
];

const thackerayStreetLine = [
  [-39.4917, 176.9163],
  [-39.4936, 176.9171],
  [-39.4941, 176.9168],
  [-39.4948, 176.9142]
];

const hastingsStreetExtension = [
  [-39.4912, 176.9183],
  [-39.4940, 176.9181],
  [-39.4940, 176.9190],
  [-39.4997, 176.9185]
];

export const napierTrams: Line = {
  name: 'Napier Trams',
  state: 'NZ',
  segments: [
    {
      segments: [portAhuruiLine],
      history: {
        opened: {
          date: '1913-09-08',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1931-02-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [thackerayStreetLine],
      history: {
        opened: {
          date: '1913-09-08',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1931-02-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hastingsStreetExtension],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1931-02-03',
          status: 'closed'
        }]
      }
    }
  ]
}

import { Line } from "../../../../trackTypes";

const docklandsStadium = [
  [-37.81501, 144.94498],
  [-37.81317, 144.95142]
];

const lonsdaleStreet = [
  [-37.81228, 144.96227],
  [-37.81512, 144.95233]
];

const upperSpencerStreet = [
  [-37.81512, 144.95233],
  [-37.81317, 144.95142]
];

const spencerStreetSiding = [
  [-37.81317, 144.95142],
  [-37.81218, 144.95098]
];

const westMelbourne = [
  [-37.81218, 144.95098],
  [-37.81048, 144.95019],
  [-37.80928, 144.94940],
  [-37.80841, 144.94865],
  [-37.80759, 144.94770],
  [-37.80705, 144.94692],
  [-37.80617, 144.94488],
  [-37.80261, 144.94555]
];

const laTrobeStreet = [
  [-37.81317, 144.95142],
  [-37.80759, 144.97062],
  [-37.80758, 144.97102],
  [-37.80778, 144.97198],
  [-37.80819, 144.97589]
];

export const west2: Line = {
  name: 'West2',
  state: 'VIC',
  segments: [
    {
      segments: [docklandsStadium],
      history: {
        opened: {
          date: '2000-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [lonsdaleStreet],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lonsdaleStreet],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [upperSpencerStreet],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }, {
          date: '1951-01-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [spencerStreetSiding],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }, {
          date: '1993-12-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [westMelbourne],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [laTrobeStreet],
      history: {
        opened: {
          date: '1951-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};

import { Line } from "../../../trackTypes";

const loop = [
  [-23.3878, 150.5020],
  [-23.3826, 150.4983],
  [-23.3796, 150.5031],
  [-23.3748, 150.5109],
  [-23.3799, 150.5147],
  [-23.3878, 150.5020]
];

const showgrounds = [
  [-23.3796, 150.5031],
  [-23.3726, 150.4979],
  [-23.3687, 150.4985],
  [-23.3683, 150.4985],
  [-23.3677, 150.4980]
];

const wandal = [
  [-23.3687, 150.4985],
  [-23.3674, 150.4891]
];

const gardens = [
  [-23.3878, 150.5020],
  [-23.3887, 150.5007],
  [-23.3952, 150.4996],
  [-23.3955, 150.4993],
  [-23.3976, 150.4990],
  [-23.3970, 150.4947],
  [-23.3995, 150.4943]
];

const dawsonRoad = [
  [-23.3878, 150.5020],
  [-23.3890, 150.5029],
  [-23.4020, 150.5007]
];

export const rockhamptonTrams: Line = {
  name: 'Rockhampton Trams',
  state: 'QLD',
  segments: [
    {
      segments: [loop, showgrounds, wandal, gardens, dawsonRoad],
      history: {
        opened: {
          date: '1909-06-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-06-24',
          status: 'closed'
        }]
      }
    }
  ]
}

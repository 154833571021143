import React, { useState } from "react";
import History from './History';
// import Modal from "./components/Modal";

function App() {
  // const [modalOpen, setModalOpen] = useState(true);
  return (
    <>
      {/* <Modal isOpen={modalOpen} onClick={() => {setModalOpen(false);}}>
        This app allows you to explore the history of the Australian and New Zealand railways.
        <br/><br/>
        You can use the slider to change the current year being displayed.
        <br/><br/>
        If you find any errors or missing data, please .........
      </Modal> */}
      <History />
    </>
  );
}

export default App;

import { Line } from "../../../trackTypes";

const fendaltonLine = [
  [-43.5208, 172.6280],
  [-43.5207, 172.6244],
  [-43.5222, 172.6191],
  [-43.5208, 172.6181],
  [-43.5212, 172.6169],
  [-43.5196, 172.6157],
  [-43.5205, 172.6128],
  [-43.5217, 172.6101],
  [-43.5214, 172.6089],
  [-43.5205, 172.6080],
  [-43.5203, 172.6072],
  [-43.5188, 172.5939],
  [-43.5185, 172.5907]
];

const opawaLine = [
  [-43.5447, 172.6366],
  [-43.5447, 172.6462],
  [-43.5447, 172.6468],
  [-43.5468, 172.6534],
  [-43.5489, 172.6559],
  [-43.5513, 172.6619],
  [-43.5538, 172.6659]
];

const stMartinsLine = [
  [-43.5447, 172.6468],
  [-43.5507, 172.6467],
  [-43.5513, 172.6470],
  [-43.5537, 172.6508],
  [-43.5627, 172.6575],
  [-43.5637, 172.6600],
  [-43.5623, 172.6644]
];

const cityCathedralSquare = [
  [-43.5399, 172.6366],
  [-43.5399, 172.6279],
  [-43.5475, 172.6279],
  [-43.5479, 172.6261],
  [-43.5478, 172.6247],
  [-43.5474, 172.6230],
  [-43.5474, 172.6218],
  [-43.5494, 172.6218]
];

const cathedralSquareSpreydon = [
  [-43.5494, 172.6218],
  [-43.5516, 172.6180],
  [-43.5556, 172.6224],
  [-43.5567, 172.6205],
  [-43.5575, 172.6192]
];

const spreyonBalloonLoop = [
  [-43.5575, 172.6192],
  [-43.5566, 172.6182],
  [-43.5559, 172.6195],
  [-43.5567, 172.6205]
];

const lincolnRoadLine = [
  [-43.5342, 172.6366],
  [-43.5342, 172.6327],
  [-43.5343, 172.6322],
  [-43.5348, 172.6315],
  [-43.5349, 172.6306],
  [-43.5344, 172.6277],
  [-43.5345, 172.6271],
  [-43.5350, 172.6262],
  [-43.5468, 172.6062]
];

const lincolnRoadExtension = [
  [-43.5468, 172.6062],
  [-43.5547, 172.5928]
];

const cityStanmoreRoad = [
  [-43.5331, 172.6382],
  [-43.5330, 172.6571]
];

const stanmoreRoadBurwood = [
  [-43.5330, 172.6571],
  [-43.5243, 172.6570],
  [-43.5222, 172.6564],
  [-43.5176, 172.6563],
  [-43.5175, 172.6598],
  [-43.5151, 172.6598],
  [-43.5143, 172.6605],
  [-43.5129, 172.6605],
  [-43.5119, 172.6615],
  [-43.5100, 172.6628],
  [-43.5094, 172.6630],
  [-43.5080, 172.6628],
  [-43.5079, 172.6654],
  [-43.5076, 172.6675],
  [-43.5073, 172.6679],
  [-43.5067, 172.6682],
  [-43.5064, 172.6690]
];

const burwoodNewBrightonPier = [
  [-43.5064, 172.6690],
  [-43.5063, 172.6719],
  [-43.5053, 172.6749],
  [-43.5053, 172.6757],
  [-43.5054, 172.6766],
  [-43.5051, 172.6802],
  [-43.5047, 172.6813],
  [-43.5039, 172.6822],
  [-43.5031, 172.6827],
  [-43.5013, 172.6835],
  [-43.5007, 172.6842],
  [-43.5001, 172.6857],
  [-43.5001, 172.6878],
  [-43.4964, 172.6884],
  [-43.4925, 172.6915],
  [-43.4938, 172.7059],
  [-43.4949, 172.7083],
  [-43.4954, 172.7131],
  [-43.4969, 172.7158],
  [-43.4975, 172.7163],
  [-43.4951, 172.7265],
  [-43.5069, 172.7308]
];

const cityPapanui = [
  [-43.5315, 172.6366],
  [-43.5302, 172.6366],
  [-43.5287, 172.6366],
  [-43.5244, 172.6305],
  [-43.5208, 172.6280],
  [-43.5121, 172.6217],
  [-43.4972, 172.6080],
  [-43.4962, 172.6062],
  [-43.4951, 172.6062]
];

const papanuiNorthcoteRoad = [
  [-43.4972, 172.6080],
  [-43.4965, 172.6079],
  [-43.4960, 172.6081],
  [-43.4882, 172.6156],
  [-43.4873, 172.6160],
  [-43.4849, 172.6162]
];

const cathedralSquareChristchurchStation = [
  [-43.5315, 172.6366],
  [-43.5320, 172.6366],
  [-43.5342, 172.6366],
  [-43.5399, 172.6366],
  [-43.5399, 172.6396]
];

const riccartonLine = [
  [-43.5350, 172.6262],
  [-43.5326, 172.6183],
  [-43.5312, 172.6162],
  [-43.5310, 172.6156],
  [-43.5308, 172.6150],
  [-43.5303, 172.6142],
  [-43.5294, 172.6135],
  [-43.5288, 172.6122],
  [-43.5319, 172.5721],
  [-43.5262, 172.5533],
  [-43.5329, 172.5495], //POINT
  [-43.5323, 172.5478]
];

const plumtonParkExtension = [
  [-43.5319, 172.5721],
  [-43.5323, 172.5670],
  [-43.5413, 172.5494],
  [-43.5436, 172.5498]
];

const citySydenham = [
  [-43.5399, 172.6366],
  [-43.5536, 172.6368]
];

const sydenhamCashmereHills = [
  [-43.5536, 172.6368],
  [-43.5650, 172.6371],
  [-43.5655, 172.6369],
  [-43.5673, 172.6350]
];

const cashmereHillsExtension = [
  [-43.5673, 172.6350],
  [-43.5674, 172.6345],
  [-43.5671, 172.6335],
  [-43.5668, 172.6331],
  [-43.5663, 172.6324],
  [-43.5662, 172.6320],
  [-43.5663, 172.6316],
  [-43.5676, 172.6295],
  [-43.5675, 172.6282],
  [-43.5682, 172.6256],
  [-43.5690, 172.6238],
  [-43.5701, 172.6237],
  [-43.5732, 172.6247],
  [-43.5739, 172.6245],
  [-43.5750, 172.6250],
  [-43.5763, 172.6240],
  [-43.5767, 172.6242],
  [-43.5776, 172.6255],
  [-43.5773, 172.6267],
  [-43.5777, 172.6283],
  [-43.5772, 172.6301],
  [-43.5768, 172.6307],
  [-43.5767, 172.6316],
  [-43.5766, 172.6322],
  [-43.5767, 172.6329],
  [-43.5770, 172.6332],
  [-43.5776, 172.6334],
  [-43.5783, 172.6331],
  [-43.5786, 172.6334],
  [-43.5788, 172.6339],
  [-43.5792, 172.6345],
  [-43.5803, 172.6353],
  [-43.5818, 172.6356]
];

const cityWollston = [
  [-43.5320, 172.6366],
  [-43.5331, 172.6382],
  [-43.5363, 172.6427],
  [-43.5399, 172.6571],
  [-43.5405, 172.6599],
  [-43.5457, 172.6733],
  [-43.5468, 172.6783],
  [-43.5500, 172.6842]
];

const woolstonHeathcote = [
  [-43.5500, 172.6842],
  [-43.5542, 172.6924],
  [-43.5557, 172.6950],
  [-43.5565, 172.6969],
  [-43.5584, 172.7078]
];

const heathcoteSumner = [
  [-43.5584, 172.7078],
  [-43.5582, 172.7097],
  [-43.5580, 172.7103],
  [-43.5564, 172.7125],
  [-43.5555, 172.7158],
  [-43.5564, 172.7317],
  [-43.5628, 172.7400],
  [-43.5648, 172.7413],
  [-43.5662, 172.7440],
  [-43.5663, 172.7445],
  [-43.5663, 172.7467],
  [-43.5659, 172.7476],
  [-43.5638, 172.7491],
  [-43.5636, 172.7496],
  [-43.5647, 172.7543],
  [-43.5684, 172.7591],
  [-43.5695, 172.7590],
  [-43.5749, 172.7682]
];

const oxfordTerraceLinwoodCemetary = [
  [-43.5302, 172.6366],
  [-43.5309, 172.6380],
  [-43.5309, 172.6690],
  [-43.5336, 172.6731],
  [-43.5263, 172.6826]
];

const cashelStreetDeviation = [
  [-43.5330, 172.6571],
  [-43.5330, 172.6722]
];

const linwoodNewBrighton = [
  [-43.5263, 172.6826],
  [-43.5239, 172.6860],
  [-43.5236, 172.6870],
  [-43.5236, 172.6882],
  [-43.5234, 172.6890],
  [-43.5214, 172.6949],
  [-43.5172, 172.7017],
  [-43.5092, 172.7210],
  [-43.5069, 172.7308]
];

const manchesterStreet = [
  [-43.5342, 172.6397],
  [-43.5399, 172.6396]
];

const stAlbansParkLine = [
  [-43.5287, 172.6366],
  [-43.5208, 172.6366],
  [-43.5208, 172.6459],
  [-43.5134, 172.6459]
];

const stAlbansParkExtension = [
  [-43.5134, 172.6459],
  [-43.5081, 172.6459]
];

const canfordLine = [
  [-43.5208, 172.6366],
  [-43.5134, 172.6366],
  [-43.5134, 172.6380],
  [-43.5122, 172.6382],
  [-43.5088, 172.6382],
  [-43.5064, 172.6362]
];

const dallingtonLine = [
  [-43.5309, 172.6690],
  [-43.5301, 172.6700],
  [-43.5291, 172.6686],
  [-43.5256, 172.6728],
  [-43.5218, 172.6727]
];

const cityCircit = [
  [-43.5287, 172.6276],
  [-43.5309, 172.6275],
  [-43.5309, 172.6338],
  [-43.5309, 172.6354],
  [-43.5304, 172.6365],
  [-43.5304, 172.6368],
  [-43.5309, 172.6379],
  [-43.5309, 172.6387],
  [-43.5287, 172.6387],
  [-43.5287, 172.6276]
];

const cityCircitExtensionOne = [
  [-43.5309, 172.6379],
  [-43.5315, 172.6366],
  [-43.5319, 172.6366],
  [-43.5331, 172.6381],
  [-43.5341, 172.6396],
  [-43.5351, 172.6409],
  [-43.5350, 172.6413],
  [-43.5342, 172.6413],
  [-43.5341, 172.6396]
];

const cityCircitExtensionTwo = [
  [-43.5309, 172.6338],
  [-43.5331, 172.6338],
  [-43.5331, 172.6381]
];

export const christchurchTrams: Line = {
  name: 'Christchurch Trams',
  state: 'NZ',
  segments: [
    {
      segments: [fendaltonLine],
      history: {
        opened: {
          date: '1907-05-03',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1909-11-20',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-02-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [opawaLine],
      history: {
        opened: {
          date: '1907-03-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1909-09-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-02-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stMartinsLine],
      history: {
        opened: {
          date: '1914-04-07',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1941-01-05',
          status: 'closed'
        }, {
          date: '1946-05-19',
          status: 'closed'
        }, {
          date: '1942-07-06',
          status: 'open'
        }]
      }
    },
    {
      segments: [cityCathedralSquare],
      history: {
        opened: {
          date: '1911-08-03',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-06-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cathedralSquareSpreydon],
      history: {
        opened: {
          date: '1922-08-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-06-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [spreyonBalloonLoop],
      history: {
        opened: {
          date: '1911-08-03',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lincolnRoadLine],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1906-02-08',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-07-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lincolnRoadExtension],
      history: {
        opened: {
          date: '1896-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1906-02-08',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-07-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cityStanmoreRoad],
      history: {
        opened: {
          date: '1893-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1910-08-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1931-07-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stanmoreRoadBurwood],
      history: {
        opened: {
          date: '1894-10-17',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1910-08-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1931-07-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [burwoodNewBrightonPier],
      history: {
        opened: {
          date: '1894-10-25',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1914-09-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1931-07-05',
          status: 'closed'
        }, {
          date: '1927-08-22',
          status: 'closed'
        }, {
          date: '1928-10-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [cityPapanui],
      history: {
        opened: {
          date: '1880-06-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-06-05',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [papanuiNorthcoteRoad],
      history: {
        opened: {
          date: '1913-02-28',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1930-08-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cathedralSquareChristchurchStation],
      history: {
        opened: {
          date: '1880-03-16',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-06-05',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [riccartonLine],
      history: {
        opened: {
          date: '1905-11-02',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1906-03-12',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-06-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [plumtonParkExtension],
      history: {
        opened: {
          date: '1915-12-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-06-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [citySydenham],
      history: {
        opened: {
          date: '1880-08-06',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-08-16',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sydenhamCashmereHills],
      history: {
        opened: {
          date: '1898-12-07',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-08-16',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cashmereHillsExtension],
      history: {
        opened: {
          date: '1911-05-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cityWollston],
      history: {
        opened: {
          date: '1882-07-07',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1907-04-25',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-12-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [woolstonHeathcote],
      history: {
        opened: {
          date: '1882-12-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1907-04-25',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-12-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [heathcoteSumner],
      history: {
        opened: {
          date: '1888-11-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1907-04-25',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-12-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oxfordTerraceLinwoodCemetary],
      history: {
        opened: {
          date: '1886-03-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1906-03-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-10-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cashelStreetDeviation],
      history: {
        opened: {
          date: '1910-11-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-10-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [linwoodNewBrighton],
      history: {
        opened: {
          date: '1887-01-08',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1906-08-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-10-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [manchesterStreet],
      history: {
        opened: {
          date: '1905-06-06',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1932-04-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stAlbansParkLine],
      history: {
        opened: {
          date: '1906-12-24',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-06-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stAlbansParkExtension],
      history: {
        opened: {
          date: '1915-07-19',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-06-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [canfordLine],
      history: {
        opened: {
          date: '1910-07-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-07-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dallingtonLine],
      history: {
        opened: {
          date: '1912-11-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1936-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cityCircit],
      history: {
        opened: {
          date: '1995-02-04',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [cityCircitExtensionOne],
      history: {
        opened: {
          date: '2015-02-12',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [cityCircitExtensionTwo],
      history: {
        opened: {
          date: '2015-02-12',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    }
  ]
}

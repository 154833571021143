import { Line } from "../../trackTypes";

const stKildaJunctionStKilda = [
  [-37.8266, 144.9555],
  [-37.8271, 144.9551],
  [-37.8288, 144.9545],
  [-37.8305, 144.9545],
  [-37.8320, 144.9548],
  [-37.8418, 144.9594],
  [-37.8457, 144.9619],
  [-37.8492, 144.9651],
  [-37.8519, 144.9683],
  [-37.8551, 144.9727],
  [-37.8572, 144.9753],
  [-37.8576, 144.9757],
  [-37.8595, 144.9776]
];

const stKildaWindsor = [
  [-37.8576, 144.9757],
  [-37.8571, 144.9756],
  [-37.8568, 144.9756],
  [-37.8548, 144.9760],
  [-37.8543, 144.9762],
  [-37.8539, 144.9766],
  [-37.8534, 144.9773],
  [-37.8524, 144.9794],
  [-37.8523, 144.9807],
  [-37.8525, 144.9820],
  [-37.8531, 144.9863],
  [-37.8534, 144.9872],
  [-37.8553, 144.9911]
];

export const stKilda: Line = {
  name: 'St Kilda',
  state: 'VIC',
  segments: [
    {
      segments: [stKildaJunctionStKilda],
      history: {
        opened: {
          date: '1857-05-13',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1919-08-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1987-11-21',
          trackType: 'tram',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [stKildaWindsor],
      history: {
        opened: {
          date: '1859-12-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1862-10-01',
          status: 'closed'
        }]
      }
    }
  ]
}

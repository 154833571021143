import { Line } from "../../../trackTypes";

const line = [
  [-38.3367, 144.7440],
  [-38.3370, 144.7436],
  [-38.3372, 144.7433],
  [-38.3374, 144.7424],
  [-38.3375, 144.7421],
  [-38.3387, 144.7414],
  [-38.3389, 144.7412],
  [-38.3400, 144.7364],
  [-38.3441, 144.7316],
  [-38.3445, 144.7310],
  [-38.3449, 144.7291],
  [-38.3451, 144.7288],
  [-38.3456, 144.7286],
  [-38.3457, 144.7284],
  [-38.3458, 144.7282],
  [-38.3459, 144.7281],
  [-38.3461, 144.7281]
];

export const sorentoTram: Line = {
  name: 'Sorento Tram',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1889-12-25',
          tracks: 1,
          gauge: 'standard', //horse tram
          trackType: 'tram'
        },
        trackChange: [{
          date: '1920-03-01',
          status: 'closed'
        }]
      }
    }
  ]
}

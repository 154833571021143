import { Line } from "../../../../trackTypes";

const sirEdwardSmithAve = [
  [-34.91573, 138.59888],
  [-34.91555, 138.59894],
  [-34.91537, 138.59906],
  [-34.91526, 138.59916],
  [-34.91499, 138.59956],
  [-34.91477, 138.59980],
  [-34.91450, 138.60003],
  [-34.91414, 138.60020],
  [-34.91235, 138.60075]
];

const melbourneStreetWest = [
  [-34.91235, 138.60075],
  [-34.91064, 138.60123],
  [-34.91046, 138.60132],
  [-34.91028, 138.60149],
  [-34.90774, 138.60607]
];

const finnissStreet = [
  [-34.91235, 138.60075],
  [-34.91225, 138.60093],
  [-34.90904, 138.60662],
  [-34.90897, 138.60668],
  [-34.90888, 138.60669],
  [-34.90877, 138.60669],
  [-34.90792, 138.60602],
  [-34.90784, 138.60601],
  [-34.90774, 138.60607]
];

const melbourneStreetEast = [
  [-34.90774, 138.60607],
  [-34.90521, 138.61065],
  [-34.90512, 138.61072],
  [-34.90502, 138.61069],
  [-34.90413, 138.61002]
];

const mannTerrace = [
  [-34.90413, 138.61002],
  [-34.90315, 138.60923],
  [-34.90300, 138.60917],
  [-34.90281, 138.60919],
  [-34.90184, 138.60922],
  [-34.90166, 138.60930]
];

const parkTerrace = [
  [-34.90413, 138.61002],
  [-34.90399, 138.60998],
  [-34.90387, 138.61005],
  [-34.90344, 138.61087],
  [-34.90335, 138.61094],
  [-34.90320, 138.61092],
  [-34.90239, 138.61041],
  [-34.90218, 138.61010],
  [-34.90194, 138.60945],
  [-34.90187, 138.60935],
  [-34.90177, 138.60930],
  [-34.90166, 138.60930]
];

const walkerville = [
  [-34.90166, 138.60930],
  [-34.90135, 138.60964],
  [-34.89528, 138.61725],
  [-34.89514, 138.61731],
  [-34.89502, 138.61728],
  [-34.89298, 138.61499]
];

const northAdelaideJunction = [
  [-34.91195, 138.59883],
  [-34.91235, 138.60075]
];

const smithStreet = [
  [-34.89298, 138.61499],
  [-34.89059, 138.61237]
];

const hampsteadHotel = [
  [-34.89059, 138.61237],
  [-34.89037, 138.61230],
  [-34.89026, 138.61231],
  [-34.89017, 138.61234],
  [-34.89007, 138.61238],
  [-34.88948, 138.61311],
  [-34.88439, 138.61981]
];

export const north2: Line = {
  name: 'North2',
  state: 'SA',
  segments: [
    {
      segments: [sirEdwardSmithAve, melbourneStreetEast, walkerville],
      history: {
        opened: {
          date: '1882-11-06',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-04-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1955-11-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [melbourneStreetWest, mannTerrace],
      history: {
        opened: {
          date: '1909-04-07',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-11-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northAdelaideJunction, finnissStreet, parkTerrace],
      history: {
        opened: {
          date: '1882-11-06',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-04-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [smithStreet],
      history: {
        opened: {
          date: '1911-01-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-11-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hampsteadHotel],
      history: {
        opened: {
          date: '1924-09-28',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-11-12',
          status: 'closed'
        }]
      }
    }
  ]
};

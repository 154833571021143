import { Line } from "../../../../trackTypes";

const hilton = [
  [-34.92373, 138.56977],
  [-34.92566, 138.56984],
  [-34.93189, 138.57036],
  [-34.93227, 138.56151]
];

const richmond = [
  [-34.93227, 138.56151],
  [-34.93224, 138.56029],
  [-34.93278, 138.55251],
  [-34.94282, 138.55324]
];

export const west4: Line = {
  name: 'West4',
  state: 'SA',
  segments: [
    {
      segments: [hilton],
      history: {
        opened: {
          date: '1917-01-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [richmond],
      history: {
        opened: {
          date: '1939-06-29',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    }
  ]
};

import React, { useState } from 'react';
import styled from 'styled-components';
import Info from './Info';
import ChangeLogModal from './ChangeLogModal';
import Button from './Button';
import { COLOUR } from '../mapUtils/colours';

const Container = styled.div`
  padding: 1rem;
  height: calc(100% - 6rem);
  overflow-y: scroll;
`;

const LineItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

type ColourBlobProps = {
  colour: string;
};

const ColourBlob = styled.div<ColourBlobProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 5px;
  background-color: ${({ colour }) => colour};
`;

const Legend = () => {
  const [isChangelogOpen, setIsChangelogOpen] = useState(false);

  return (
    <Container>
      <p>Legend</p>
      <LineItem>
        <ColourBlob colour={COLOUR.blue}/>
        <p>Standard Gauge</p><Info tooltip="Rail width of 1,435mm"/>
      </LineItem>
      <LineItem>
        <ColourBlob colour={COLOUR.red}/>
        <p>Broad Gauge</p><Info tooltip="Rail width of 1,600mm"/>
      </LineItem>
      <LineItem>
        <ColourBlob colour={COLOUR.green}/>
        <p>Narrow Gauge</p><Info tooltip="Rail width of 1,067mm or less"/>
      </LineItem>
      <LineItem>
        <ColourBlob colour={COLOUR.purple}/>
        <p>Dual Gauge</p><Info tooltip="Railway track that contains 2 gauges on the one track"/>
      </LineItem>
      <LineItem>
        <ColourBlob colour={COLOUR.yellow}/>
        <p>Electrified Track (DC)</p><Info tooltip="Track is electrified using Direct Current"/>
      </LineItem>
      <LineItem>
        <ColourBlob colour={COLOUR.orange}/>
        <p>Electrified Track (AC)</p><Info tooltip="track is electrified using Alternating Current"/>
      </LineItem>
      <LineItem>
        <ColourBlob colour={COLOUR.pink}/>
        <p>Parallel Standard Gauge</p><Info tooltip="A second Standard Gauge track is parallel to the existing one"/>
      </LineItem>
      <LineItem>
        <ColourBlob colour={COLOUR.darkGrey}/>
        <p>Steam | Horse Tram</p><Info tooltip="A passenger tram that is powered by steam or pulled by horse"/>
      </LineItem>
      <LineItem>
        <ColourBlob colour={COLOUR.lightGrey}/>
        <p>Tram (Cable)</p><Info tooltip="A tram designed for passengers, pulled by cables"/>
      </LineItem>
      <LineItem>
        <ColourBlob colour={COLOUR.yellowTinge}/>
        <p>Tram (Electrified)</p><Info tooltip="A tram designed for passengers, powered by electricity"/>
      </LineItem>
      <LineItem>
        <ColourBlob colour={COLOUR.darkGrey}/>
        <p>Industrial Tram</p><Info tooltip="A tram designed for industrial use (timber,  mines, etc.)"/>
      </LineItem>
      <br/>
      <Button onClick={() => setIsChangelogOpen(true)}>
        View Changelog
      </Button>
      <ChangeLogModal
        isOpen={isChangelogOpen}
        close={() => setIsChangelogOpen(false)}
      />
    </Container>
  );
};

export default Legend;

import { Line } from "../../../../trackTypes";

const marketStreetConnection = [
  [-37.81763, 144.95912],
  [-37.81862, 144.95957],
  [-37.81831, 144.96067]
];

const queensBridge = [
  [-37.81831, 144.96067],
  [-37.81940, 144.96115],
  [-37.82005, 144.96136],
  [-37.82145, 144.96138],
  [-37.82561, 144.96007]
];

const queensBridgeConnector = [
  [-37.82561, 144.96007],
  [-37.82609, 144.95989],
  [-37.82764, 144.95809]
];

const kingsway = [
  [-37.82561, 144.96007],
  [-37.83078, 144.96516]
];

const spencerStreetConnection = [
  [-37.82108, 144.95507],
  [-37.82764, 144.95809]
];

const southMelbourne = [
  [-37.82764, 144.95809],
  [-37.83522, 144.96159],
  [-37.83737, 144.95417],
  [-37.84069, 144.95570],
  [-37.84127, 144.95372],
  [-37.84144, 144.95345],
  [-37.84746, 144.94847]
];

export const south3: Line = {
  name: 'South3',
  state: 'VIC',
  segments: [
    {
      segments: [marketStreetConnection],
      history: {
        opened: {
          date: '1946-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [queensBridge],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }, {
          date: '1946-01-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [queensBridgeConnector],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingsway],
      history: {
        opened: {
          date: '1946-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [spencerStreetConnection],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [southMelbourne],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1937-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};

import { Line } from "../../../../trackTypes";

const flemingtonBridge = [
  [-37.80625, 144.95949],
  [-37.80513, 144.94927],
  [-37.80305, 144.94962],
  [-37.80261, 144.94555],
  [-37.79403, 144.94706],
  [-37.79404, 144.94706],
  [-37.79365, 144.94725],
  [-37.79354, 144.94727],
  [-37.79340, 144.94721],
];

const flemingtonRoad = [
  [-37.79340, 144.94721],
  [-37.78938, 144.94215],
  [-37.78844, 144.94099],
  [-37.78802, 144.94062]
];

const flemingtonBridgeEssendon = [
  [-37.78802, 144.94062],
  [-37.78464, 144.93800],
  [-37.78426, 144.93747],
  [-37.78382, 144.93654],
  [-37.78274, 144.93428],
  [-37.78209, 144.93335],
  [-37.77867, 144.93051],
  [-37.77491, 144.92804],
  [-37.77301, 144.92704],
  [-37.76711, 144.92485],
  [-37.76654, 144.92487],
  [-37.76622, 144.92504],
  [-37.75817, 144.92644],
  [-37.75593, 144.92609],
  [-37.75499, 144.91770],
  [-37.75484, 144.91743],
  [-37.75431, 144.91694],
  [-37.74643, 144.91155]
];

const essendonExtension = [
  [-37.74643, 144.91155],
  [-37.74464, 144.91033],
  [-37.74446, 144.91009],
  [-37.74240, 144.90488]
];

const essendonExtension2 = [
  [-37.74240, 144.90488],
  [-37.73872, 144.89597]
];

const essendonAerodrome = [
  [-37.73872, 144.89597],
  [-37.73614, 144.88942],
  [-37.73598, 144.88927],
  [-37.73582, 144.88920],
  [-37.72672, 144.89075],
  [-37.72570, 144.89092]
];

const matthewsAveExtension = [
  [-37.72570, 144.89092],
  [-37.72173, 144.89154]
];

const airportWest = [
  [-37.72173, 144.89154],
  [-37.71736, 144.89220],
  [-37.71638, 144.89217],
  [-37.71556, 144.89187],
  [-37.71490, 144.89140],
  [-37.71384, 144.89065]
];

const AerodromeSpur = [
  [-37.72570, 144.89092],
  [-37.72594, 144.89570]
];

const mooneePondsStationSpur = [
  [-37.76711, 144.92485],
  [-37.76660, 144.91987]
];

const napierStreetSiding = [
  [-37.75500, 144.91777],
  [-37.75308, 144.91812]
];

export const west1: Line = {
  name: 'West1',
  state: 'VIC',
  segments: [
    {
      segments: [flemingtonBridge],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [flemingtonRoad],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1925-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [flemingtonBridgeEssendon],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [essendonExtension],
      history: {
        opened: {
          date: '1923-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [essendonExtension2],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [essendonAerodrome],
      history: {
        opened: {
          date: '1943-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [matthewsAveExtension],
      history: {
        opened: {
          date: '1976-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [airportWest],
      history: {
        opened: {
          date: '1992-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [AerodromeSpur],
      history: {
        opened: {
          date: '1943-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1976-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mooneePondsStationSpur],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1915-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [napierStreetSiding],
      history: {
        opened: {
          date: '1923-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '2004-01-01',
          status: 'closed'
        }]
      }
    },
  ]
};

import { Line } from "../../../../trackTypes";

const line = [
  [-37.79994, 144.90117],
  [-37.80018, 144.90334],
  [-37.80046, 144.90329],
  [-37.80059, 144.90315],
  [-37.80230, 144.89912],
  [-37.80413, 144.89876],
  [-37.80331, 144.89238],
  [-37.80666, 144.89180],
  [-37.80651, 144.89043],
  [-37.81346, 144.88921],
  [-37.81284, 144.88363]
];

export const footscraySouth: Line = {
  name: 'Footscray South',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1962-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};

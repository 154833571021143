import { Section, Deviation, NewSegment, Feature } from '../trackPolyines/trackTypes';
import { COLOUR } from "./colours";

export const getLineState = (date: Date, segment: Section, showAllLines: boolean, showUnderConstruction: boolean) => {
  const openedDate = new Date(segment.history.opened.date);
  let gauge = segment.history.opened.gauge;
  let tracks = segment.history.opened.tracks;
  let electrified = segment.history.opened.electrified;
  let trackType = segment.history.opened.trackType;
  let hasParallelStandard = segment.history.opened.parallelStandard || false;
  let opened = openedDate.getFullYear() <= date.getFullYear();

  if (segment.history.trackChange) {
    segment.history.trackChange.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    segment.history.trackChange.forEach((changeSet) => {
      const changeDate = new Date(changeSet.date);
      if (date.getFullYear() >= changeDate.getFullYear()) {
        if ('electrified' in changeSet) {
          electrified = changeSet.electrified;
        }
        if (changeSet.gauge) {
          gauge = changeSet.gauge;
        }
        if (changeSet.tracks) {
          tracks = changeSet.tracks;
        }
        if ('parallelStandard' in changeSet) {
          hasParallelStandard = changeSet.parallelStandard || false;
        }
        if (changeSet.status) {
          if (!showAllLines) {
            opened = changeSet.status === 'closed' ? false : true;
          }
        }
        if (changeSet.trackType) {
          trackType = changeSet.trackType;
        }
      }
    });
  }

  if (showUnderConstruction === true && segment.underConstruction === true) {
    opened = true;
  }
  if (hasParallelStandard === true) {
    tracks += 1;
  }

  return {
    opened,
    tracks,
    electrified,
    trackType,
    gauge,
    hasParallelStandard
  };
};

export const getPositions = (date: Date, segment: Deviation | Feature | NewSegment) => {
  if ((segment as Feature).type) {
    return [(segment as Feature).segment];
  }

  if ((segment as Deviation).original) {
    const deviationDate = new Date((segment as Deviation).date);
    if (date.getFullYear() >= deviationDate.getFullYear()) {
      return (segment as Deviation).deviation;
    }
    return (segment as Deviation).original;
  }
  return [segment as NewSegment];
};

export const getRenderDetails = (lineState: ReturnType<typeof getLineState>) => {
  let colour = COLOUR.red;
  let weight = lineState.tracks;
  if (lineState.gauge === 'standard') { colour = COLOUR.blue };
  if (lineState.gauge === 'narrow') { colour = COLOUR.green };
  if (lineState.gauge === 'dual') { colour = COLOUR.purple };
  if (lineState.hasParallelStandard === true) { colour = COLOUR.pink; weight += 1 };
  if (lineState.electrified) {
    if (lineState.electrified.current === 'DC') {
      colour = COLOUR.yellow;
    }
    if (lineState.electrified.current === 'AC') {
      colour = COLOUR.orange;
    }
  };
  if (lineState.trackType === 'industrialTram') { colour = COLOUR.darkGrey };
  if (lineState.trackType === 'cableTram') { colour =  COLOUR.lightGrey; weight = 2; }
  if (lineState.trackType === 'tram') {
    weight = 2;
    if (lineState.electrified) { colour = COLOUR.yellowTinge }
    else { colour = COLOUR.darkGrey; }
  }
  if (lineState.trackType === 'funicular' || lineState.trackType === 'monorail') { colour = COLOUR.offWhite };
  if (lineState.trackType === 'hybrid') { colour = COLOUR.offWhite };

  return {
    colour,
    weight: weight / 2
  }
};

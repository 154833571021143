import { Line } from "../../trackTypes";

const southKensingtonFootscray = [
  [-37.7991, 144.9228],
  [-37.7980, 144.9152],
  [-37.7979, 144.9114],
  [-37.7985, 144.9081],
  [-37.7997, 144.9052],
  [-37.8012, 144.9029]
];

const southernCrossCraigieburnJunction = [
  [-37.8182, 144.9518],
  [-37.8159, 144.9499],
  [-37.8135, 144.9480],
  [-37.8130, 144.9474],
  [-37.8111, 144.9452],
  [-37.8059, 144.9410],
  [-37.8044, 144.9394],
  [-37.8040, 144.9389],
  [-37.8032, 144.9378],
  [-37.8015, 144.9345],
  [-37.8012, 144.9337]
];

const craigieburnJunctionSouthKensington = [
  [-37.8012, 144.9337],
  [-37.8011, 144.9334],
  [-37.8003, 144.9307],
  [-37.7991, 144.9228]
];

const footscrayBrooklynJunction = [
  [-37.8012, 144.9029],
  [-37.8023, 144.9018],
  [-37.8034, 144.9008],
  [-37.8134, 144.8915],
  [-37.8145, 144.8906],
  [-37.8154, 144.8901],
  [-37.8161, 144.8897],
  [-37.8174, 144.8892],
  [-37.8201, 144.8884],
  [-37.8386, 144.8838]
];

const brooklynJunctionNewport = [
  [-37.8386, 144.8838],
  [-37.8400, 144.8836],
  [-37.8414, 144.8835],
  [-37.8427, 144.8835]
];

const newportNewportWorkshops = [
  [-37.8427, 144.8835],
  [-37.8456, 144.8839],
  [-37.8475, 144.8844],
  [-37.8551, 144.8880]
];

const newportWorkshopsWilliamstown = [
  [-37.8551, 144.8880],
  [-37.8601, 144.8904],
  [-37.8618, 144.8916],
  [-37.8627, 144.8926],
  [-37.8636, 144.8939],
  [-37.8646, 144.8958],
  [-37.8648, 144.8964],
  [-37.8677, 144.9052]
];

const willamstownWilliamstownPier = [
  [-37.8677, 144.9052],
  [-37.8679, 144.9060],
  [-37.8683, 144.9075],
  [-37.8683, 144.9085],
  [-37.8681, 144.9093],
  [-37.8676, 144.9100],
  [-37.8670, 144.9105],
  [-37.8658, 144.9106]
];

export const williamstown: Line = {
  name: 'Williamstown',
  state: 'VIC',
  segments: [
    {
      segments: [southernCrossCraigieburnJunction],
      history: {
        opened: {
          date: '1859-01-17',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-04-01',
          parallelStandard: true
        }, {
          date: '1976-11-21',
          tracks: 4
        },{
          date: '2014-01-20',
          tracks: 6
        }, {
          date: '1919-05-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [craigieburnJunctionSouthKensington],
      history: {
        opened: {
          date: '1859-01-17',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [
        {
          date: '1962-04-01',
          parallelStandard: true
        }, {
          date: '1976-11-21',
          tracks: 4
        },{
          date: '2014-01-20',
          tracks: 6
        }, {
          date: '1920-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [southKensingtonFootscray],
      history: {
        opened: {
          date: '1859-01-17',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-11-21',
          tracks: 4
        }, {
          date: '2014-01-20',
          tracks: 6
        }, {
          date: '1920-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [footscrayBrooklynJunction],
      history: {
        opened: {
          date: '1859-01-17',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1920-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [brooklynJunctionNewport],
      history: {
        opened: {
          date: '1859-01-17',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1920-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1995-05-23',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [newportWorkshopsWilliamstown],
      history: {
        opened: {
          date: '1857-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1905-01-08',
          tracks: 2
        },{
          date: '1920-08-27',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [willamstownWilliamstownPier],
      history: {
        opened: {
          date: '1857-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1920-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1987-03-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newportNewportWorkshops],
      history: {
        opened: {
          date: '1859-01-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1885-03-18',
          tracks: 2
        }, {
          date: '1920-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}

import { Line } from "../../../../trackTypes";

const leedsStreet = [
  [-37.80145, 144.90090],
  [-37.79994, 144.90117]
];

const line = [
  [-37.79994, 144.90117],
  [-37.79976, 144.89966],
  [-37.79238, 144.89351],
  [-37.79118, 144.88952]
];

const ballaratRoad = [
  [-37.79118, 144.88952],
  [-37.78864, 144.88324]
];

export const footscrayNorth: Line = {
  name: 'Footscray North',
  state: 'VIC',
  segments: [
    {
      segments: [leedsStreet],
      history: {
        opened: {
          date: '1962-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [line],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [ballaratRoad],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1962-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../../../trackTypes";

const westPreston = [
  [-37.75177, 144.99503],
  [-37.75105, 144.98825],
  [-37.75096, 144.98811],
  [-37.75078, 144.98804],
  [-37.73309, 144.99090],
  [-37.72897, 144.99163]
];

export const north7: Line = {
  name: 'North7',
  state: 'VIC',
  segments: [
    {
      segments: [westPreston],
      history: {
        opened: {
          date: '1920-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};

import { Line } from "../../trackTypes";

const bennettBrookRailway = [
  [-31.8453, 115.9467],
  [-31.8453, 115.9464],
  [-31.8451, 115.9461],
  [-31.8441, 115.9452],
  [-31.8432, 115.9442],
  [-31.8428, 115.9440],
  [-31.8424, 115.9439],
  [-31.8414, 115.9440],
  [-31.8407, 115.9441],
  [-31.8403, 115.9444],
  [-31.8400, 115.9447],
  [-31.8394, 115.9450],
  [-31.8379, 115.9453],
  [-31.8372, 115.9453],
  [-31.8349, 115.9443],
  [-31.8321, 115.9428],
  [-31.8306, 115.9424],
  [-31.8301, 115.9425],
  [-31.8294, 115.9428],
  [-31.8286, 115.9429],
  [-31.8279, 115.9426],
  [-31.8274, 115.9419],
  [-31.8273, 115.9412],
  [-31.8277, 115.9402],
  [-31.8283, 115.9396],
  [-31.8304, 115.9370],
  [-31.8307, 115.9363],
  [-31.8310, 115.9347],
  [-31.8310, 115.9341],
  [-31.8307, 115.9336],
  [-31.8288, 115.9308],
  [-31.8285, 115.9300],
  [-31.8287, 115.9289],
  [-31.8293, 115.9283],
  [-31.8301, 115.9282],
  [-31.8308, 115.9286],
  [-31.8329, 115.9312],
  [-31.8334, 115.9319],
  [-31.8335, 115.9325],
  [-31.8337, 115.9345],
  [-31.8340, 115.9353],
  [-31.8346, 115.9358],
  [-31.8351, 115.9365],
  [-31.8359, 115.9399],
  [-31.8369, 115.9424],
  [-31.8369, 115.9430],
  [-31.8366, 115.9438],
  [-31.8360, 115.9443],
  [-31.8349, 115.9443]
];

const perthElectricTramwayLineOne = [
  [-31.8453, 115.9349],
  [-31.8454, 115.9444],
  [-31.8454, 115.9447],
  [-31.8453, 115.9457],
  [-31.8453, 115.9462],
  [-31.8459, 115.9494],
  [-31.8459, 115.9497],
  [-31.8458, 115.9499],
  [-31.8452, 115.9503],
  [-31.8450, 115.9504],
  [-31.8446, 115.9503],
  [-31.8444, 115.9503],
  [-31.8439, 115.9505],
  [-31.8438, 115.9508],
  [-31.8436, 115.9524],
  [-31.8425, 115.9553],
  [-31.8422, 115.9555],
  [-31.8419, 115.9554],
  [-31.8355, 115.9486],
  [-31.8352, 115.9482],
  [-31.8352, 115.9476],
  [-31.8354, 115.9472],
  [-31.8357, 115.9468],
  [-31.8359, 115.9466],
  [-31.8360, 115.9456],
  [-31.8359, 115.9451],
  [-31.8355, 115.9448],
  [-31.8345, 115.9442]
];

const perthElectricTramwayLineTwo = [
  [-31.8425, 115.9553],
  [-31.8397, 115.9611],
  [-31.8392, 115.9617],
  [-31.8383, 115.9621],
  [-31.8376, 115.9622],
  [-31.8371, 115.9624],
  [-31.8363, 115.9632]
];

export const bennettBrook: Line = {
  name: 'Bennett Brook',
  state: 'WA',
  segments: [
    {
      segments: [bennettBrookRailway],
      history: {
        opened: {
          date: '1984-12-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [perthElectricTramwayLineOne, perthElectricTramwayLineTwo],
      history: {
        opened: {
          date: '1986-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};

import { Line } from "../../../../trackTypes";

const rundleStreet = [
  [-34.91839, 138.62247],
  [-34.91816, 138.62278],
  [-34.91795, 138.62288],
  [-34.91589, 138.62275],
  [-34.91567, 138.62281],
  [-34.91547, 138.62289]
];

const paynehamRoad = [
  [-34.91547, 138.62289],
  [-34.90960, 138.62779]
];

const nelsonStreet = [
  [-34.90960, 138.62779],
  [-34.90956, 138.62790],
  [-34.90955, 138.62802],
  [-34.90958, 138.62815],
  [-34.90980, 138.62833],
  [-34.91507, 138.63148],
  [-34.91514, 138.63160],
  [-34.91519, 138.63175],
  [-34.91522, 138.63194]
];

const oldMagilRoadJunction = [
  [-34.91589, 138.62275],
  [-34.91579, 138.62281],
  [-34.91573, 138.62290],
  [-34.91567, 138.62302]
];

const newMagilRoadJunction = [
  [-34.91578, 138.62250],
  [-34.91571, 138.62275],
  [-34.91567, 138.62302]
];

const magillRoad = [
  [-34.91567, 138.62302],
  [-34.91522, 138.63194],
  [-34.91518, 138.63258]
];

const maylands = [
  [-34.91518, 138.63258],
  [-34.91507, 138.63469]
];

const magill = [
  [-34.91507, 138.63469],
  [-34.91302, 138.67584]
];

const fifthAvenue = [
  [-34.90926, 138.62781],
  [-34.90542, 138.62417]
];

const fifthAvenueJunction = [
  [-34.90960, 138.62779],
  [-34.90945, 138.62784],
  [-34.90938, 138.62783],
  [-34.90926, 138.62781]
];

const rugbyStreet = [
  [-34.91579, 138.62245],
  [-34.91568, 138.62252],
  [-34.91558, 138.62249],
  [-34.91381, 138.62068],
  [-34.91370, 138.62065],
  [-34.91361, 138.62066],
  [-34.91170, 138.62276]
];

const rugbyStreetDeviation = [
  [-34.91336, 138.62465],
  [-34.91326, 138.62466],
  [-34.91316, 138.62465],
  [-34.91306, 138.62460],
  [-34.91170, 138.62276]
];

const harrowsRoad = [
  [-34.91170, 138.62276],
  [-34.91024, 138.62084]
];

const harrowRoadNorth = [
  [-34.91024, 138.62084],
  [-34.90958, 138.61996]
];

const stPeters = [
  [-34.90958, 138.61996],
  [-34.90949, 138.61992],
  [-34.90941, 138.61994],
  [-34.90542, 138.62417]
];

const fourthAve = [
  [-34.91024, 138.62084],
  [-34.91017, 138.62083],
  [-34.91012, 138.62085],
  [-34.90377, 138.62625]
];

const parkStreet = [
  [-34.91302, 138.67584],
  [-34.91285, 138.67930]
];

const nortonSummitRoad = [
  [-34.91285, 138.67930],
  [-34.91275, 138.68115]
];

const sixthAve = [
  [-34.90958, 138.61996],
  [-34.90914, 138.61938],
  [-34.89849, 138.62763]
];

export const east2: Line = {
  name: 'East2',
  state: 'SA',
  segments: [
    {
      segments: [rundleStreet],
      history: {
        opened: {
          date: '1882-12-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paynehamRoad],
      history: {
        opened: {
          date: '1882-12-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-05-05',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nelsonStreet],
      history: {
        opened: {
          date: '1882-12-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-05-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fifthAvenue],
      history: {
        opened: {
          date: '1882-12-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fifthAvenueJunction],
      history: {
        opened: {
          date: '1882-12-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-05-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oldMagilRoadJunction],
      history: {
        opened: {
          date: '1883-01-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newMagilRoadJunction],
      history: {
        opened: {
          date: '1909-05-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [magillRoad],
      history: {
        opened: {
          date: '1883-01-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-05-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maylands],
      history: {
        opened: {
          date: '1883-08-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-05-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [magill],
      history: {
        opened: {
          date: '1883-08-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-12-23',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rugbyStreet],
      history: {
        opened: {
          date: '1889-11-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rugbyStreetDeviation],
      history: {
        opened: {
          date: '1911-07-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [harrowsRoad],
      history: {
        opened: {
          date: '1889-11-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-23',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [harrowRoadNorth],
      history: {
        opened: {
          date: '1889-11-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-23',
          status: 'closed'
        }, {
          date: '1924-05-25',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stPeters],
      history: {
        opened: {
          date: '1889-11-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fourthAve],
      history: {
        opened: {
          date: '1911-07-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1924-05-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [parkStreet],
      history: {
        opened: {
          date: '1912-01-25',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nortonSummitRoad],
      history: {
        opened: {
          date: '1912-02-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sixthAve],
      history: {
        opened: {
          date: '1924-05-25',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../../trackTypes";

const chatswoodLine = [
  [-33.8277, 151.2008],
  [-33.8204, 151.2009],
  [-33.8196, 151.2007],
  [-33.8165, 151.2013],
  [-33.8134, 151.1996],
  [-33.8129, 151.1995],
  [-33.8079, 151.1994],
  [-33.8057, 151.1991],
  [-33.8035, 151.1995],
  [-33.8033, 151.1982],
  [-33.8000, 151.1989],
  [-33.7924, 151.1955]
];

const chatswoodStationExtension = [
  [-33.7924, 151.1955],
  [-33.7960, 151.1838],
  [-33.7962, 151.1831],
  [-33.7967, 151.1814]
];

const neutralBayLine = [
  [-33.8315, 151.2223],
  [-33.8333, 151.2220],
  [-33.8350, 151.2217],
  [-33.8353, 151.2214],
  [-33.8356, 151.2213],
  [-33.8366, 151.2216],
  [-33.8369, 151.2219],
  [-33.8371, 151.2219],
  [-33.8379, 151.2219],
  [-33.8409, 151.2209],
  [-33.8411, 151.2206],
  [-33.8409, 151.2189],
  [-33.8419, 151.2188]
];

const spitLine = [
  [-33.8245, 151.2408],
  [-33.8226, 151.2436],
  [-33.8209, 151.2439],
  [-33.8196, 151.2438],
  [-33.8188, 151.2439],
  [-33.8182, 151.2439],
  [-33.8173, 151.2436],
  [-33.8165, 151.2441],
  [-33.8151, 151.2446],
  [-33.8146, 151.2449],
  [-33.8140, 151.2451],
  [-33.8134, 151.2455],
  [-33.8130, 151.2459],
  [-33.8128, 151.2459],
  [-33.8125, 151.2459],
  [-33.8121, 151.2462],
  [-33.8117, 151.2462],
  [-33.8113, 151.2462],
  [-33.8106, 151.2462],
  [-33.8101, 151.2466],
  [-33.8084, 151.2480],
  [-33.8081, 151.2481],
  [-33.8079, 151.2480],
  [-33.8071, 151.2472],
  [-33.8062, 151.2467],
  [-33.8043, 151.2462],
  [-33.8036, 151.2458]
];

const northbridgeLine = [
  [-33.8289, 151.2089],
  [-33.8227, 151.2102],
  [-33.8211, 151.2094],
  [-33.8209, 151.2094],
  [-33.8198, 151.2096],
  [-33.8200, 151.2108],
  [-33.8193, 151.2111],
  [-33.8188, 151.2115],
  [-33.8186, 151.2119],
  [-33.8185, 151.2124],
  [-33.8183, 151.2126],
  [-33.8181, 151.2126],
  [-33.8178, 151.2125]
];

const northBridgeExtension = [
  [-33.8178, 151.2125],
  [-33.8167, 151.2122],
  [-33.8157, 151.2120],
  [-33.8152, 151.2122],
  [-33.8143, 151.2120],
  [-33.8141, 151.2117],
  [-33.8139, 151.2113],
  [-33.8138, 151.2110],
  [-33.8131, 151.2105],
  [-33.8128, 151.2104],
  [-33.8108, 151.2108],
  [-33.8120, 151.2184]
];

const cremorneLine = [
  [-33.8281, 151.2303],
  [-33.8352, 151.2288],
  [-33.8350, 151.2270],
  [-33.8354, 151.2269],
  [-33.8356, 151.2265],
  [-33.8376, 151.2262],
  [-33.8384, 151.2258],
  [-33.8391, 151.2259],
  [-33.8426, 151.2274],
  [-33.8435, 151.2274],
  [-33.8461, 151.2300],
  [-33.8463, 151.2300],
  [-33.8467, 151.2300],
  [-33.8470, 151.2301],
  [-33.8476, 151.2311]
];

const mosmanWharfLine = [
  [-33.8245, 151.2408],
  [-33.8257, 151.2435],
  [-33.8272, 151.2440],
  [-33.8300, 151.2442],
  [-33.8321, 151.2378],
  [-33.8327, 151.2367],
  [-33.8333, 151.2362],
  [-33.8340, 151.2356],
  [-33.8342, 151.2350],
  [-33.8345, 151.2346],
  [-33.8346, 151.2341],
  [-33.8350, 151.2336],
  [-33.8356, 151.2335],
  [-33.8357, 151.2334],
  [-33.8359, 151.2332],
  [-33.8361, 151.2330],
  [-33.8380, 151.2331],
  [-33.8383, 151.2329]
];

const balmoralLine = [
  [-33.8316, 151.2443],
  [-33.8321, 151.2448],
  [-33.8329, 151.2457],
  [-33.8331, 151.2464],
  [-33.8331, 151.2497],
  [-33.8314, 151.2501],
  [-33.8315, 151.2512],
  [-33.8303, 151.2515],
  [-33.8301, 151.2518],
  [-33.8299, 151.2518],
  [-33.8297, 151.2516],
  [-33.8298, 151.2510],
  [-33.8298, 151.2501],
  [-33.8300, 151.2494],
  [-33.8299, 151.2492],
  [-33.8297, 151.2490],
  [-33.8295, 151.2492],
  [-33.8292, 151.2499],
  [-33.8291, 151.2505],
  [-33.8289, 151.2507],
  [-33.8283, 151.2511],
  [-33.8283, 151.2511],
  [-33.8280, 151.2516],
  [-33.8281, 151.2520],
  [-33.8280, 151.2523],
  [-33.8278, 151.2523],
  [-33.8266, 151.2514],
  [-33.8246, 151.2508],
  [-33.8241, 151.2508]
];

const atholWharfLine = [
  [-33.8423, 151.2438],
  [-33.8434, 151.2444],
  [-33.8436, 151.2445],
  [-33.8446, 151.2444],
  [-33.8451, 151.2446],
  [-33.8462, 151.2455],
  [-33.8465, 151.2455],
  [-33.8466, 151.2453],
  [-33.8465, 151.2451],
  [-33.8463, 151.2448],
  [-33.8461, 151.2445],
  [-33.8459, 151.2432],
  [-33.8458, 151.2430],
  [-33.8453, 151.2424],
  [-33.8451, 151.2419],
  [-33.8452, 151.2414],
  [-33.8456, 151.2407],
  [-33.8457, 151.2401],
  [-33.8457, 151.2394]
];

const tarongaZooLine = [
  [-33.8300, 151.2442],
  [-33.8316, 151.2443],
  [-33.8323, 151.2443],
  [-33.8354, 151.2463],
  [-33.8359, 151.2465],
  [-33.8361, 151.2465],
  [-33.8368, 151.2460],
  [-33.8376, 151.2451],
  [-33.8380, 151.2450],
  [-33.8386, 151.2450],
  [-33.8390, 151.2448],
  [-33.8400, 151.2440],
  [-33.8410, 151.2436],
  [-33.8423, 151.2438],
  [-33.8421, 151.2422]
];

const crowsNestLine = [
  [-33.8289, 151.2089],
  [-33.8277, 151.2008],
  [-33.8248, 151.1982]
];

const crowsNestDeviation = [
  [-33.8387, 151.2068],
  [-33.8379, 151.2062],
  [-33.8345, 151.2049],
  [-33.8335, 151.2047],
  [-33.8318, 151.2039],
  [-33.8304, 151.2027],
  [-33.8277, 151.2008]
];

const goreHillLine = [
  [-33.8248, 151.1982],
  [-33.8236, 151.1970],
  [-33.8232, 151.1957],
  [-33.8233, 151.1949],
  [-33.8221, 151.1944],
  [-33.8217, 151.1944],
];

const laneCoveLine = [
  [-33.8233, 151.1949],
  [-33.8245, 151.1908],
  [-33.8247, 151.1896],
  [-33.8244, 151.1882],
  [-33.8238, 151.1875],
  [-33.8227, 151.1869],
  [-33.8215, 151.1859],
  [-33.8179, 151.1824],
  [-33.8176, 151.1819],
  [-33.8171, 151.1798],
  [-33.8164, 151.1788],
  [-33.8160, 151.1785],
  [-33.8146, 151.1778],
  [-33.8143, 151.1776],
  [-33.8131, 151.1760],
  [-33.8124, 151.1755],
  [-33.8122, 151.1728],
  [-33.8125, 151.1712],
  [-33.8128, 151.1708],
  [-33.8133, 151.1702],
  [-33.8140, 151.1699]
];

const ridgeStreetSpitJunction = [
  [-33.8328, 151.2081],
  [-33.8289, 151.2089],
  [-33.8298, 151.2154],
  [-33.8292, 151.2156],
  [-33.8294, 151.2172],
  [-33.8315, 151.2223],
  [-33.8315, 151.2232],
  [-33.8298, 151.2274],
  [-33.8290, 151.2283],
  [-33.8281, 151.2303],
  [-33.8275, 151.2312],
  [-33.8253, 151.2334],
  [-33.8250, 151.2339],
  [-33.8245, 151.2351],
  [-33.8243, 151.2362],
  [-33.8243, 151.2392],
  [-33.8245, 151.2408]
];

const mcMahonsPointLine = [
  [-33.8387, 151.2068],
  [-33.8408, 151.2064],
  [-33.8431, 151.2047],
  [-33.8448, 151.2042],
  [-33.8464, 151.2040],
  [-33.8471, 151.2037],
  [-33.8486, 151.2037],
  [-33.8485, 151.2048],
  [-33.8488, 151.2060]
];

const milsonsPointOriginal = [
  [-33.8426, 151.2104],
  [-33.8414, 151.2092],
  [-33.8411, 151.2084],
  [-33.8408, 151.2064],
  [-33.8387, 151.2068]
];

const milsonsPointDeviation = [
  [-33.8387, 151.2068],
  [-33.8391, 151.2088],
  [-33.8406, 151.2085],
  [-33.8414, 151.2092]
];

const milsonsPointGlenStreetLine = [
  [-33.8457, 151.2113],
  [-33.8450, 151.2111],
  [-33.8441, 151.2106],
  [-33.8426, 151.2104]
];

const milsonsPointWharf = [
  [-33.8505, 151.2125],
  [-33.8496, 151.2123],
  [-33.8495, 151.2118],
  [-33.8457, 151.2113]
];

const ridgeStreet = [
  [-33.8387, 151.2068],
  [-33.8328, 151.2081]
];

const harbourBridgeWynyardTunnel = [
  [-33.8426, 151.2104],
  [-33.8436, 151.2115],
  [-33.8474, 151.2127],
  [-33.8487, 151.2128],
  [-33.8495, 151.2126],
  [-33.8590, 151.2066]
];

const wynyardTunnel = [
  [-33.8590, 151.2066],
  [-33.8621, 151.2054],
  [-33.8631, 151.2054],
  [-33.8668, 151.2059]
];

export const sydneyTramsNorth: Line = {
  name: 'Sydney Trams (North)',
  state: 'NSW',
  segments: [
    {
      segments: [chatswoodLine],
      history: {
        opened: {
          date: '1898-04-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [chatswoodStationExtension],
      history: {
        opened: {
          date: '1908-07-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [neutralBayLine],
      history: {
        opened: {
          date: '1900-06-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [spitLine],
      history: {
        opened: {
          date: '1900-10-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northbridgeLine],
      history: {
        opened: {
          date: '1909-10-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1948-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northBridgeExtension],
      history: {
        opened: {
          date: '1914-03-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1936-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cremorneLine],
      history: {
        opened: {
          date: '1911-12-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-04-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mosmanWharfLine],
      history: {
        opened: {
          date: '1911-12-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1955-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [balmoralLine],
      history: {
        opened: {
          date: '1922-05-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [atholWharfLine],
      history: {
        opened: {
          date: '1917-10-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tarongaZooLine],
      history: {
        opened: {
          date: '1916-10-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goreHillLine],
      history: {
        opened: {
          date: '1900-02-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [laneCoveLine],
      history: {
        opened: {
          date: '1909-03-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ridgeStreetSpitJunction],
      history: {
        opened: {
          date: '1893-09-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mcMahonsPointLine],
      history: {
        opened: {
          date: '1909-09-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1932-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [milsonsPointDeviation],
      history: {
        opened: {
          date: '1909-09-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1932-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [milsonsPointOriginal],
      history: {
        opened: {
          date: '1886-05',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1900-02-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [milsonsPointGlenStreetLine],
      history: {
        opened: {
          date: '1886-05',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1900-02-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1932-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [milsonsPointWharf],
      history: {
        opened: {
          date: '1886-05',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1900-02-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1924-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ridgeStreet],
      history: {
        opened: {
          date: '1886-05',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1900-02-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [crowsNestDeviation],
      history: {
        opened: {
          date: '1909-09-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [crowsNestLine],
      history: {
        opened: {
          date: '1893-07-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1900-02-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        harbourBridgeWynyardTunnel,
        {
          segment: wynyardTunnel,
          type: 'tunnel'
        }
      ],
      history: {
        opened: {
          date: '1932-03-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../../../trackTypes";

const northCarlton = [
  [-37.79747, 144.97051],
  [-37.77980, 144.97348]
];

export const north6: Line = {
  name: 'North6',
  state: 'VIC',
  segments: [
    {
      segments: [northCarlton],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1936-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};

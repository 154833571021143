export enum COLOUR {
  yellow = '#f4fc03',
  blue = '#3e5bfa',
  red = '#ff1f0a',
  green = '#3fff0a',
  purple = '#a228fa',
  pink = '#db58bf',
  darkGrey = '#545453',
  lightGrey = '#a09fa1',
  offWhite = '#f5f3f2',
  yellowTinge = '#a5a683',
  orange = '#f5842f'
};

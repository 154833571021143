import { Line } from "../../../trackTypes";

const paramattaWharf = [
  [-33.8124, 151.0004],
  [-33.8128, 151.0003],
  [-33.8149, 151.0092],
  [-33.8162, 151.0135],
  [-33.8170, 151.0177],
  [-33.8180, 151.0222],
  [-33.8184, 151.0232],
  [-33.8204, 151.0256],
  [-33.8208, 151.0273],
  [-33.8206, 151.0411],
  [-33.8210, 151.0433],
  [-33.8221, 151.0453],
  [-33.8237, 151.0472]
];

const paramattaStation = [
  [-33.8181, 151.0056],
  [-33.8170, 151.0038],
  [-33.8169, 151.0034],
  [-33.8164, 151.0033],
  [-33.8150, 151.0028],
  [-33.8123, 151.0038],
  [-33.8110, 151.0043],
  [-33.8103, 151.0048],
  [-33.8095, 151.0051],
  [-33.8078, 151.0055],
  [-33.8060, 151.0058],
  [-33.8045, 151.0058],
  [-33.8037, 151.0058],
  [-33.8032, 151.0057],
  [-33.8014, 151.0048],
  [-33.8002, 151.0042],
  [-33.7988, 151.0033],
  [-33.7950, 151.0009],
  [-33.7936, 151.0001],
  [-33.7914, 150.9977]
];

export const paramattaTrams: Line = {
  name: 'Paramatta (Trams)',
  state: 'NSW',
  segments: [
    {
      segments: [paramattaWharf],
      history: {
        opened: {
          date: '1883-10-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1943-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paramattaStation],
      history: {
        opened: {
          date: '1902-08-18',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1927-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};

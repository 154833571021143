import { Line } from "../../../../trackTypes";

const elizabethStreetBrunswick = [
  [-37.81786, 144.96480],
  [-37.81228, 144.96227],
  [-37.80165, 144.95738],
  [-37.80140, 144.95735],
  [-37.78456, 144.95941]
];

const brunswickExtension = [
  [-37.78456, 144.95941],
  [-37.77814, 144.96022],
  [-37.77703, 144.96046],
  [-37.75568, 144.96408]
];

const brunswickCoburg = [
  [-37.75568, 144.96408],
  [-37.73838, 144.96712],
  [-37.73809, 144.96710],
  [-37.73407, 144.96539]
];

const coburgNorthCoburg = [
  [-37.73407, 144.96539],
  [-37.73044, 144.96383],
  [-37.73016, 144.96379],
  [-37.72757, 144.96367]
];

const connector = [
  [-37.75545, 144.96412],
  [-37.75666, 144.97487]
];

const morelandRoad = [
  [-37.75545, 144.96412],
  [-37.75518, 144.96218]
];

const brunswickDepot = [
  [-37.75518, 144.96218],
  [-37.75713, 144.96181]
];

export const north4: Line = {
  name: 'North4',
  state: 'VIC',
  segments: [
    {
      segments: [elizabethStreetBrunswick],
      history: {
        opened: {
          date: '1887-10-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [brunswickExtension],
      history: {
        opened: {
          date: '1887-10-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1936-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [brunswickCoburg],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1916-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [coburgNorthCoburg],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [connector],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [morelandRoad],
      history: {
        opened: {
          date: '1936-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [brunswickDepot],
      history: {
        opened: {
          date: '1957-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};

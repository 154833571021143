import { Line } from "../../../trackTypes";

//central perth
const carbarnThomasStreetHayStreet = [
  [-31.9604, 115.8781],
  [-31.9471, 115.8374]
];

const colinStreetSpur = [
  [-31.9487, 115.8421],
  [-31.9527, 115.8403]
];

//south perth lines
const victoriaPark = [
  [-31.9604, 115.8781],
  [-31.9616, 115.8776],
  [-31.9674, 115.8874],
  [-31.9699, 115.8912],
  [-31.9725, 115.8958],
  [-31.9739, 115.8970],
  [-31.9750, 115.8977],
  [-31.9775, 115.8986],
  [-31.9883, 115.9054],
  [-31.9888, 115.9059]
];

const welshpool = [
  [-31.9888, 115.9059],
  [-31.9936, 115.9124],
  [-31.9938, 115.9133],
  [-31.9940, 115.9213]
];

const southPerthZoo = [
  [-31.9676, 115.8878],
  [-31.9741, 115.8827],
  [-31.9835, 115.8750],
  [-31.9834, 115.8742],
  [-31.9815, 115.8720],
  [-31.9815, 115.8676],
  [-31.9792, 115.8533],
  [-31.9742, 115.8512],
  [-31.9713, 115.8534]
];

const como = [
  [-31.9792, 115.8533],
  [-31.9938, 115.8596],
  [-31.9938, 115.8573],
  [-31.9950, 115.8573]
];

//west perth
const subiaco = [
  [-31.9471, 115.8374],
  [-31.9470, 115.8240],
  [-31.9588, 115.8238]
];

const nedlands = [
  [-31.9588, 115.8238],
  [-31.9627, 115.8193],
  [-31.9705, 115.8135],
  [-31.9753, 115.8134],
  [-31.9772, 115.8138]
];

const crawleyNedlandsBaths = [
  [-31.9772, 115.8138],
  [-31.9794, 115.8144],
  [-31.9803, 115.8145],
  [-31.9874, 115.8145],
  [-31.9894, 115.8162]
];

//north perth lines
const esplanadeBarrackStreet = [
  [-31.9595, 115.8579],
  [-31.9586, 115.8584],
  [-31.9517, 115.8616],
  [-31.9509, 115.8617],
  [-31.9425, 115.8670],
  [-31.9344, 115.8721]
];

const northPerth = [
  [-31.9425, 115.8670],
  [-31.9381, 115.8573],
  [-31.9363, 115.8584],
  [-31.9329, 115.8591],
  [-31.9329, 115.8591]
];

const northPerthAngoveStreet = [
  [-31.9329, 115.8591],
  [-31.9307, 115.8591],
  [-31.9296, 115.8569]
];

const angoveStreetCharlesStreet = [
  [-31.9296, 115.8569],
  [-31.9269, 115.8516]
];

const mtLawley = [
  [-31.9344, 115.8721],
  [-31.9266, 115.8630]
];

//inglewood line
const inglewood = [
  [-31.9344, 115.8721],
  [-31.9293, 115.8764],
  [-31.9203, 115.8873]
];

const dundasStreetSailsburyStreet = [
  [-31.9203, 115.8873],
  [-31.9170, 115.8915]
];

const sailsburyStreetGrandPromenard = [
  [-31.9170, 115.8915],
  [-31.9160, 115.8927],
  [-31.9156, 115.8934]
];

//leederville =
const leedervilleCbd = [
  [-31.9475, 115.8638],
  [-31.9462, 115.8610]
];

const leederville = [
  [-31.9392, 115.8457],
  [-31.9462, 115.8610],
  [-31.9374, 115.8418],
  [-31.9374, 115.8412],
  [-31.9319, 115.8412]
];

const wembley = [
  [-31.9374, 115.8412],
  [-31.9387, 115.8412],
  [-31.9387, 115.8277],
  [-31.9407, 115.8276],
  [-31.9407, 115.8247]
];

const wembleyExtension = [
  [-31.9407, 115.8247],
  [-31.9407, 115.8103]
];

const oxfordStreetMtHawthorne = [
  [-31.9319, 115.8412],
  [-31.9224, 115.8412],
  [-31.9219, 115.8399],
  [-31.9200, 115.8360]
];

const mtHawthorneosbornePark = [
  [-31.9200, 115.8360],
  [-31.9167, 115.8331],
  [-31.9143, 115.8288],
  [-31.9142, 115.8282],
  [-31.8965, 115.8282]
];

//maylands
const lincolnStreet = [
  [-31.9475, 115.8638],
  [-31.9498, 115.8689],
  [-31.9459, 115.8719],
  [-31.9435, 115.8735]
];

const maylands = [
  [-31.9435, 115.8735],
  [-31.9384, 115.8769],
  [-31.9362, 115.8815],
  [-31.9327, 115.8878],
  [-31.9307, 115.8919],
  [-31.9258, 115.9101],
  [-31.9254, 115.9120]
];

//cbd
const murrayStreetDeviation = [
  [-31.9518, 115.8516],
  [-31.9507, 115.8521],
  [-31.9535, 115.8608],
  [-31.9541, 115.8627],
  [-31.9552, 115.8622]
];

//mount's bay road
const pointLewis = [
  [-31.9570, 115.8591],
  [-31.9560, 115.8559],
  [-31.9560, 115.8554],
  [-31.9551, 115.8527],
  [-31.9551, 115.8506],
  [-31.9553, 115.8490],
  [-31.9561, 115.8477],
  [-31.9571, 115.8467],
  [-31.9588, 115.8459],
  [-31.9597, 115.8460],
  [-31.9615, 115.8464],
  [-31.9620, 115.8460]
];

const cityBaths = [
  [-31.9620, 115.8460],
  [-31.9630, 115.8427],
  [-31.9634, 115.8419],
  [-31.9641, 115.8414],
  [-31.9646, 115.8405],
  [-31.9652, 115.8400],
  [-31.9660, 115.8396],
  [-31.9676, 115.8385],
  [-31.9688, 115.8374],
  [-31.9696, 115.8361],
  [-31.9700, 115.8346],
  [-31.9705, 115.8340],
  [-31.9711, 115.8336],
  [-31.9716, 115.8330],
  [-31.9721, 115.8315],
  [-31.9723, 115.8307],
  [-31.9724, 115.8291]
];

const nedlandsConnection = [
  [-31.9724, 115.8291],
  [-31.9726, 115.8274],
  [-31.9732, 115.8258],
  [-31.9742, 115.8244],
  [-31.9750, 115.8233],
  [-31.9764, 115.8218],
  [-31.9778, 115.8209],
  [-31.9789, 115.8206],
  [-31.9814, 115.8204],
  [-31.9823, 115.8206],
  [-31.9828, 115.8208],
  [-31.9844, 115.8218],
  [-31.9854, 115.8219],
  [-31.9862, 115.8214],
  [-31.9865, 115.8209],
  [-31.9866, 115.8199],
  [-31.9867, 115.8193],
  [-31.9871, 115.8185],
  [-31.9876, 115.8179],
  [-31.9887, 115.8170],
  [-31.9894, 115.8162]
];

//wellingtonStreet
const wellingtonStreet = [
  [-31.9560, 115.8559],
  [-31.9512, 115.8580],
  [-31.9512, 115.8580],
  [-31.9550, 115.8697],
  [-31.9531, 115.8706],
  [-31.9532, 115.8729],
  [-31.9502, 115.8730],
  [-31.9500, 115.8732],
  [-31.9499, 115.8800]
];

export const perthTrams: Line = {
  name: 'Perth Trams',
  state: 'WA',
  segments: [
    {
      segments: [carbarnThomasStreetHayStreet],
      history: {
        opened: {
          date: '1899-09-28',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-04-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [colinStreetSpur],
      history: {
        opened: {
          date: '1899-09-28',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1930-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaPark],
      history: {
        opened: {
          date: '1905-07-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [welshpool],
      history: {
        opened: {
          date: '1942-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southPerthZoo],
      history: {
        opened: {
          date: '1922-08-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [como],
      history: {
        opened: {
          date: '1922-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [subiaco],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-04-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nedlands],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [crawleyNedlandsBaths],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1938-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northPerth],
      history: {
        opened: {
          date: '1906-12-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northPerthAngoveStreet],
      history: {
        opened: {
          date: '1910-03-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [angoveStreetCharlesStreet],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [inglewood],
      history: {
        opened: {
          date: '1916-09-09',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dundasStreetSailsburyStreet],
      history: {
        opened: {
          date: '1933-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sailsburyStreetGrandPromenard],
      history: {
        opened: {
          date: '1943-03-08',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [leederville],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [esplanadeBarrackStreet],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-04-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mtLawley],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oxfordStreetMtHawthorne],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1951-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mtHawthorneosbornePark],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1948-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wembley],
      history: {
        opened: {
          date: '1926-11-20',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wembleyExtension],
      history: {
        opened: {
          date: '1934-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-01-01',
           status: 'closed'
        }]
      }
    },
    {
      segments: [lincolnStreet],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1951-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maylands],
      history: {
        opened: {
          date: '1928-03-11',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1951-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [leedervilleCbd],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [murrayStreetDeviation],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pointLewis],
      history: {
        opened: {
          date: '1905-001-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1938-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cityBaths],
      history: {
        opened: {
          date: '1915-001-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1938-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nedlandsConnection],
      history: {
        opened: {
          date: '1929-07-08',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1938-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wellingtonStreet],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};

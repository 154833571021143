import { Line } from "../../../../trackTypes";

const victoriaStreet = [
  [-37.78382, 144.93654],
  [-37.78843, 144.93315]
];

const racecourseRoad = [
  [-37.78938, 144.94215],
  [-37.78843, 144.93315]
];

const maribyrnong = [
  [-37.78843, 144.93315],
  [-37.78835, 144.93224],
  [-37.78840, 144.93183],
  [-37.78739, 144.92226],
  [-37.78742, 144.92130],
  [-37.78058, 144.91473],
  [-37.77173, 144.91618],
  [-37.77021, 144.90251]
];

const explosivesFactory = [
  [-37.77021, 144.90251],
  [-37.76866, 144.88944],
  [-37.76866, 144.88911],
  [-37.76895, 144.88699],
  [-37.76891, 144.88660],
  [-37.76938, 144.88314],
  [-37.76940, 144.88269],
  [-37.76880, 144.87789]
];

const ordnanceFactory = [
  [-37.76938, 144.88314],
  [-37.76948, 144.88297],
  [-37.76962, 144.88288],
  [-37.77498, 144.88188]
];

const ammunitionFactory = [
  [-37.79118, 144.88952],
  [-37.78023, 144.89162]
];

const maidstone = [
  [-37.77498, 144.88188],
  [-37.77508, 144.88193],
  [-37.77513, 144.88203],
  [-37.77555, 144.88547],
  [-37.77563, 144.88562],
  [-37.77580, 144.88568],
  [-37.77886, 144.88510],
  [-37.77908, 144.88518],
  [-37.77924, 144.88544],
  [-37.77999, 144.89139],
  [-37.78008, 144.89157],
  [-37.78023, 144.89162]
];

const mooneePondsConnector = [
  [-37.77173, 144.91618],
  [-37.77256, 144.92393],
  [-37.76711, 144.92485]
];

export const west3: Line = {
  name: 'West3',
  state: 'VIC',
  segments: [
    {
      segments: [
        {
          date: '1928-01-01',
          original: [victoriaStreet],
          deviation: [racecourseRoad]
        },
        maribyrnong
      ],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [explosivesFactory],
      history: {
        opened: {
          date: '1940-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [ordnanceFactory],
      history: {
        opened: {
          date: '1941-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [ammunitionFactory],
      history: {
        opened: {
          date: '1942-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [maidstone],
      history: {
        opened: {
          date: '1954-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [mooneePondsConnector],
      history: {
        opened: {
          date: '1942-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};

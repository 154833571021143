import { Line } from "../../../trackTypes";

const cityDepoLoop = [
  [-27.4694, 153.0221],
  [-27.4700, 153.0212],
  [-27.4715, 153.0226],
  [-27.4719, 153.0233]
];

const redHill = [
  [-27.4587, 152.9993],
  [-27.4585, 152.9999],
  [-27.4584, 153.0005],
  [-27.4580, 153.0012],
  [-27.4562, 153.0015],
  [-27.4554, 153.0023],
  [-27.4525, 153.0044],
  [-27.4534, 153.0059],
  [-27.4546, 153.0068],
  [-27.4555, 153.0071],
  [-27.4561, 153.0077],
  [-27.4566, 153.0091],
  [-27.4566, 153.0099],
  [-27.4570, 153.0108],
  [-27.4585, 153.0126],
  [-27.4596, 153.0145],
  [-27.4596, 153.0145],
  [-27.4639, 153.0144],
  [-27.4640, 153.0143],
  [-27.4649, 153.0132]
];

const paddington = [
  [-27.4734, 153.0192],
  [-27.4709, 153.0235],
  [-27.4667, 153.0194],
  [-27.4660, 153.0167],
  [-27.4659, 153.0159],
  [-27.4659, 153.0160],
  [-27.4663, 153.0132],
  [-27.4662, 153.0129],
  [-27.4660, 153.0126],
  [-27.4656, 153.0126],
  [-27.4653, 153.0128],
  [-27.4649, 153.0132],
  [-27.4637, 153.0119],
  [-27.4624, 153.0091],
  [-27.4622, 153.0079],
  [-27.4616, 153.0059],
  [-27.4611, 153.0043],
  [-27.4605, 153.0033],
  [-27.4604, 153.0025],
  [-27.4588, 153.0009],
  [-27.4587, 153.0007],
  [-27.4587, 152.9993],
  [-27.4588, 152.9990],
  [-27.4592, 152.9985],
  [-27.4585, 152.9964],
  [-27.4583, 152.9947],
  [-27.4584, 152.9944],
  [-27.4589, 152.9939],
  [-27.4597, 152.9911],
  [-27.4595, 152.9902],
  [-27.4596, 152.9890],
  [-27.4595, 152.9885],
  [-27.4587, 152.9882],
  [-27.4583, 152.9878],
  [-27.4581, 152.9873],
  [-27.4580, 152.9865]
];

const ascot = [
  [-27.4709, 153.0235],
  [-27.4651, 153.0310],
  [-27.4647, 153.0312],
  [-27.4637, 153.0314],
  [-27.4618, 153.0311],
  [-27.4612, 153.0313],
  [-27.4606, 153.0317],
  [-27.4593, 153.0318],
  [-27.4542, 153.0368],
  [-27.4539, 153.0373],
  [-27.4509, 153.0402],
  [-27.4490, 153.0425],
  [-27.4468, 153.0424],
  [-27.4452, 153.0427],
  [-27.4425, 153.0445],
  [-27.4410, 153.0460],
  [-27.4413, 153.0472],
  [-27.4411, 153.0479],
  [-27.4398, 153.0490],
  [-27.4397, 153.0512],
  [-27.4393, 153.0528],
  [-27.4391, 153.0561],
  [-27.4392, 153.0573],
  [-27.4396, 153.0585],
  [-27.4398, 153.0614],
  [-27.4392, 153.0643],
  [-27.4311, 153.0656]
];

const clayfield = [
  [-27.4410, 153.0460],
  [-27.4408, 153.0450],
  [-27.4402, 153.0441],
  [-27.4399, 153.0433],
  [-27.4391, 153.0428],
  [-27.4383, 153.0426],
  [-27.4377, 153.0427],
  [-27.4371, 153.0429],
  [-27.4359, 153.0436],
  [-27.4350, 153.0437],
  [-27.4339, 153.0432],
  [-27.4323, 153.0429],
  [-27.4319, 153.0426],
  [-27.4307, 153.0417],
  [-27.4303, 153.0416],
  [-27.4296, 153.0418],
  [-27.4294, 153.0420],
  [-27.4288, 153.0428],
  [-27.4284, 153.0442],
  [-27.4280, 153.0452],
  [-27.4278, 153.0459],
  [-27.4282, 153.0477],
  [-27.4276, 153.0493],
  [-27.4270, 153.0502],
  [-27.4264, 153.0505],
  [-27.4224, 153.0512],
  [-27.4195, 153.0543]
];

const kelvinGrove = [
  [-27.4659, 153.0160],
  [-27.4601, 153.0148],
  [-27.4591, 153.0146],
  [-27.4544, 153.0120],
  [-27.4514, 153.0109],
  [-27.4499, 153.0110],
  [-27.4489, 153.0109],
  [-27.4465, 153.0092],
  [-27.4456, 153.0082],
  [-27.4428, 153.0069],
  [-27.4399, 153.0069],
  [-27.4383, 153.0072]
];

const toowong = [
  [-27.4660, 153.0126],
  [-27.4691, 153.0036],
  [-27.4703, 153.0001],
  [-27.4785, 152.9893],
  [-27.4786, 152.9859],
  [-27.4782, 152.9840],
  [-27.4782, 152.9827],
  [-27.4803, 152.9825],
  [-27.4825, 152.9821],
  [-27.4833, 152.9822],
  [-27.4835, 152.9838]
];

const rosalie = [
  [-27.4691, 153.0036],
  [-27.4668, 153.0013],
  [-27.4659, 152.9994],
  [-27.4662, 152.9970],
  [-27.4653, 152.9969],
  [-27.4641, 152.9961],
  [-27.4638, 152.9937],
  [-27.4640, 152.9935]
];

const kedron = [
  [-27.4618, 153.0311],
  [-27.4593, 153.0308],
  [-27.4588, 153.0309],
  [-27.4583, 153.0312],
  [-27.4576, 153.0312],
  [-27.4558, 153.0317],
  [-27.4541, 153.0295],
  [-27.4510, 153.0287],
  [-27.4506, 153.0287],
  [-27.4355, 153.0315],
  [-27.4325, 153.0320],
  [-27.4312, 153.0318],
  [-27.4300, 153.0318],
  [-27.4293, 153.0326],
  [-27.4282, 153.0327],
  [-27.4270, 153.0322],
  [-27.4268, 153.0322],
  [-27.4259, 153.0325],
  [-27.4247, 153.0334],
  [-27.4204, 153.0341],
  [-27.4173, 153.0362],
  [-27.4161, 153.0365],
  [-27.4157, 153.0365],
  [-27.4144, 153.0357],
  [-27.4130, 153.0342]
];

const ashgrove = [
  [-27.4525, 153.0044],
  [-27.4517, 153.0037],
  [-27.4514, 153.0032],
  [-27.4514, 153.0029],
  [-27.4514, 153.0023],
  [-27.4511, 153.0012],
  [-27.4503, 153.0006],
  [-27.4497, 153.0005],
  [-27.4487, 152.9998],
  [-27.4477, 152.9995],
  [-27.4469, 152.9988],
  [-27.4462, 152.9974],
  [-27.4456, 152.9968],
  [-27.4455, 152.9964],
  [-27.4455, 152.9947],
  [-27.4453, 152.9931],
  [-27.4457, 152.9916]
];

const ashgroveExtension = [
  [-27.4457, 152.9916],
  [-27.4458, 152.9911],
  [-27.4459, 152.9894],
  [-27.4456, 152.9882],
  [-27.4462, 152.9860],
  [-27.4465, 152.9849],
  [-27.4471, 152.9842],
  [-27.4473, 152.9831],
  [-27.4479, 152.9817],
  [-27.4478, 152.9810],
  [-27.4479, 152.9799],
  [-27.4492, 152.9784],
  [-27.4490, 152.9774],
  [-27.4488, 152.9772]
];

const exhibitionLoop = [
  [-27.4616, 153.0264],
  [-27.4611, 153.0265],
  [-27.4558, 153.0317],
  [-27.4558, 153.0317],
  [-27.4530, 153.0345],
  [-27.4519, 153.0343],
  [-27.4507, 153.0330],
  [-27.4518, 153.0305],
  [-27.4526, 153.0291]
];

const lutwyche = [
  [-27.4130, 153.0342],
  [-27.4118, 153.0336],
  [-27.4109, 153.0334],
  [-27.4095, 153.0325],
  [-27.4085, 153.0323],
  [-27.4072, 153.0325],
  [-27.4028, 153.0316],
  [-27.4008, 153.0314],
  [-27.3996, 153.0315],
  [-27.3992, 153.0315]
];

const orielPark = [
  [-27.4311, 153.0656],
  [-27.4302, 153.0589],
  [-27.4301, 153.0580],
  [-27.4297, 153.0581]
];

const newFarm = [
  [-27.4644, 153.0426],
  [-27.4662, 153.0409],
  [-27.4683, 153.0415],
  [-27.4703, 153.0430],
  [-27.4671, 153.0461],
  [-27.4716, 153.0519]
];

const merthyrRoad = [
  [-27.4558, 153.0317],
  [-27.4671, 153.0461],
  [-27.4629, 153.0502],
  [-27.4622, 153.0502]
];

const newmarket = [
  [-27.4383, 153.0072],
  [-27.4359, 153.0063],
  [-27.4326, 153.0051],
  [-27.4313, 153.0050]
];

const grange = [
  [-27.4268, 153.0322],
  [-27.4265, 153.0302],
  [-27.4267, 153.0294],
  [-27.4264, 153.0279],
  [-27.4265, 153.0275],
  [-27.4269, 153.0266],
  [-27.4267, 153.0255],
  [-27.4273, 153.0238],
  [-27.4258, 153.0137]
];

const kalinga = [
  [-27.4157, 153.0365],
  [-27.4155, 153.0367],
  [-27.4155, 153.0370],
  [-27.4156, 153.0379],
  [-27.4161, 153.0387],
  [-27.4150, 153.0404],
  [-27.4148, 153.0413],
  [-27.4145, 153.0416],
  [-27.4136, 153.0420],
  [-27.4138, 153.0430],
  [-27.4097, 153.0438],
  [-27.4101, 153.0467],
  [-27.4083, 153.0470]
];

const rainworth = [
  [-27.4640, 152.9935],
  [-27.4656, 152.9917],
  [-27.4662, 152.9899],
  [-27.4674, 152.9882],
  [-27.4673, 152.9878],
  [-27.4671, 152.9873],
  [-27.4673, 152.9867],
  [-27.4670, 152.9856]
];

const bardon = [
  [-27.4580, 152.9865],
  [-27.4580, 152.9858],
  [-27.4581, 152.9853],
  [-27.4589, 152.9847],
  [-27.4597, 152.9832],
  [-27.4600, 152.9825],
  [-27.4611, 152.9825],
  [-27.4612, 152.9822],
  [-27.4611, 152.9816],
  [-27.4625, 152.9800],
  [-27.4617, 152.9789],
  [-27.4607, 152.9781],
  [-27.4595, 152.9779],
  [-27.4592, 152.9759],
  [-27.4595, 152.9758]
];

const doomben = [
  [-27.4311, 153.0656],
  [-27.4319, 153.0716]
];

const stafford = [
  [-27.4204, 153.0341],
  [-27.4200, 153.0321],
  [-27.4194, 153.0273],
  [-27.4191, 153.0269],
  [-27.4191, 153.0261],
  [-27.4118, 153.0274],
  [-27.4109, 153.0204]
];

const chermside = [
  [-27.3992, 153.0315],
  [-27.3944, 153.0308],
  [-27.3925, 153.0311],
  [-27.3899, 153.0314],
  [-27.3885, 153.0311],
  [-27.3870, 153.0311],
  [-27.3859, 153.0309]
];

const enoggera = [
  [-27.4313, 153.0050],
  [-27.4304, 153.0053],
  [-27.4298, 153.0051],
  [-27.4284, 153.0034],
  [-27.4251, 153.0008],
  [-27.4238, 152.9972],
  [-27.4237, 152.9958],
  [-27.4227, 152.9917],
  [-27.4222, 152.9899]
];

//cbd
const wharfStreet = [
  [-27.4656, 153.0303],
  [-27.4616, 153.0264],
];

const gregoryTerrace = [
  [-27.4627, 153.0242],
  [-27.4622, 153.0231],
  [-27.4620, 153.0226],
  [-27.4620, 153.0213],
  [-27.4611, 153.0187],
  [-27.4606, 153.0178],
  [-27.4605, 153.0173],
  [-27.4605, 153.0164],
  [-27.4596, 153.0145]
];

const upperEdwardStreet = [
  [-27.4683, 153.0269],
  [-27.4660, 153.0247],
  [-27.4655, 153.0247],
  [-27.4648, 153.0250],
  [-27.4627, 153.0242],
  [-27.4625, 153.0258],
  [-27.4622, 153.0262],
  [-27.4616, 153.0264],
];

const lowerEdwardStreet = [
  [-27.4683, 153.0269],
  [-27.4720, 153.0305]
];

const adelaideStreet = [
  [-27.4701, 153.0228],
  [-27.4635, 153.0314]
];

const annStreet = [
  [-27.4641, 153.0288],
  [-27.4620, 153.0315],
  [-27.4534, 153.0400],
  [-27.4526, 153.0405],
  [-27.4501, 153.0413]
];

const commercialRoad = [
  [-27.4527, 153.0405],
  [-27.4542, 153.0468],
  [-27.4520, 153.0488]
];

export const brisbaneTramsNorth: Line = {
  name: 'Brisbane Trams (North)',
  state: 'QLD',
  segments: [
    {
      segments: [merthyrRoad],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [adelaideStreet],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [exhibitionLoop],
      history: {
        opened: {
          date: '1923-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stafford],
      history: {
        opened: {
          date: '1940-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kalinga],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [doomben],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bardon],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [chermside],
      history: {
        opened: {
          date: '1947-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lutwyche],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [grange],
      history: {
        opened: {
          date: '1928-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [enoggera],
      history: {
        opened: {
          date: '1949-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rainworth],
      history: {
        opened: {
          date: '1930-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rosalie],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [orielPark],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ashgrove],
      history: {
        opened: {
          date: '1924-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ashgroveExtension],
      history: {
        opened: {
          date: '1935-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [clayfield],
      history: {
        opened: {
          date: '1901-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newmarket],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kelvinGrove],
      history: {
        opened: {
          date: '1901-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [toowong],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paddington],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [annStreet],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [commercialRoad],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kedron],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newFarm],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wharfStreet],
      history: {
        opened: {
          date: '1902-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [redHill],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ascot],
      history: {
        opened: {
          date: '1899-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [upperEdwardStreet],
      history: {
        opened: {
          date: '1902-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gregoryTerrace],
      history: {
        opened: {
          date: '1902-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lowerEdwardStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cityDepoLoop],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}

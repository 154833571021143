import React from "react";
import styled from "styled-components";

const Tooltip = styled.span`
  border: 1px solid white;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Info = ({ tooltip }: { tooltip: string }) => {
  return (
    <Tooltip title={tooltip}>i</Tooltip>
  );
};

export default Info;

import { Line } from "../../../../trackTypes";

const line = [
  [-37.86558, 145.02603],
  [-37.88689, 145.02199]
];

const brightonCemetery = [
  [-37.88689, 145.02199],
  [-37.90114, 145.01927]
];

const eastBrighton = [
  [-37.90114, 145.01927],
  [-37.91395, 145.01696],
  [-37.91439, 145.01679]
];

export const south6: Line = {
  name: 'South6',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [brightonCemetery],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [eastBrighton],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};

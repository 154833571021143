import { Line } from "../../trackTypes";

const ouyenMurrayville = [
  [-35.0733, 142.3181],
  [-35.0738, 142.3180],
  [-35.0752, 142.3175],
  [-35.0760, 142.3169],
  [-35.0769, 142.3159],
  [-35.0807, 142.3104],
  [-35.0815, 142.3069],
  [-35.0814, 142.3053],
  [-35.0798, 142.2982],
  [-35.0796, 142.2964],
  [-35.0796, 142.2511],
  [-35.0786, 142.2465],
  [-35.0696, 142.2275],
  [-35.0687, 142.2245],
  [-35.0683, 142.2217],
  [-35.0683, 142.2194],
  [-35.0727, 142.1943],
  [-35.0737, 142.1910],
  [-35.0807, 142.1783],
  [-35.0822, 142.1765],
  [-35.0863, 142.1728],
  [-35.0881, 142.1702],
  [-35.0893, 142.1677],
  [-35.0899, 142.1648],
  [-35.0910, 142.1520],
  [-35.0916, 142.1498],
  [-35.1086, 142.1062],
  [-35.1096, 142.1028],
  [-35.1197, 142.0528],
  [-35.1208, 142.0500],
  [-35.1384, 142.0180],
  [-35.1394, 142.0152],
  [-35.1428, 141.9988],
  [-35.1431, 141.9964],
  [-35.1435, 141.9837],
  [-35.1437, 141.9814],
  [-35.1444, 141.9794],
  [-35.1480, 141.9699],
  [-35.1487, 141.9671],
  [-35.1555, 141.9205],
  [-35.1564, 141.9173],
  [-35.1578, 141.9140],
  [-35.1584, 141.9120],
  [-35.1587, 141.9104],
  [-35.1588, 141.9056],
  [-35.1590, 141.9033],
  [-35.1598, 141.9007],
  [-35.1601, 141.8992],
  [-35.1645, 141.8648],
  [-35.1645, 141.8629],
  [-35.1645, 141.8532],
  [-35.1648, 141.8506],
  [-35.1669, 141.8428],
  [-35.1672, 141.8404],
  [-35.1672, 141.8388],
  [-35.1668, 141.8352],
  [-35.1668, 141.8323],
  [-35.1674, 141.8300],
  [-35.1681, 141.8280],
  [-35.1692, 141.8256],
  [-35.1698, 141.8235],
  [-35.1701, 141.8212],
  [-35.1701, 141.8189],
  [-35.1637, 141.7613],
  [-35.1636, 141.7595],
  [-35.1635, 141.7236],
  [-35.1637, 141.7209],
  [-35.1685, 141.7023],
  [-35.1693, 141.7002],
  [-35.1719, 141.6951],
  [-35.1727, 141.6926],
  [-35.1740, 141.6866],
  [-35.1753, 141.6782],
  [-35.1762, 141.6757],
  [-35.1769, 141.6744],
  [-35.1779, 141.6729],
  [-35.1790, 141.6704],
  [-35.1814, 141.6606],
  [-35.1817, 141.6576],
  [-35.1816, 141.6542],
  [-35.1817, 141.6520],
  [-35.1820, 141.6500],
  [-35.1859, 141.6344],
  [-35.1867, 141.6321],
  [-35.1880, 141.6299],
  [-35.1917, 141.6256],
  [-35.1930, 141.6236],
  [-35.1937, 141.6218],
  [-35.1963, 141.6118],
  [-35.1994, 141.6027],
  [-35.1998, 141.6010],
  [-35.1999, 141.5992],
  [-35.1992, 141.5882],
  [-35.1978, 141.5768],
  [-35.1977, 141.5750],
  [-35.1984, 141.5675],
  [-35.1988, 141.5654],
  [-35.2049, 141.5479],
  [-35.2055, 141.5446],
  [-35.2057, 141.5368],
  [-35.2064, 141.5332],
  [-35.2074, 141.5308],
  [-35.2089, 141.5288],
  [-35.2104, 141.5260],
  [-35.2110, 141.5235],
  [-35.2116, 141.5149],
  [-35.2120, 141.5131],
  [-35.2167, 141.4911],
  [-35.2178, 141.4881],
  [-35.2241, 141.4765],
  [-35.2253, 141.4730],
  [-35.2294, 141.4127],
  [-35.2333, 141.3761],
  [-35.2341, 141.3736],
  [-35.2551, 141.3203],
  [-35.2557, 141.3178],
  [-35.2621, 141.2655],
  [-35.2626, 141.2401],
  [-35.2648, 141.2099],
  [-35.2648, 141.2083],
  [-35.2632, 141.1910],
  [-35.2615, 141.1838]
];

const murrayvillSaBorder = [
  [-35.2615, 141.1838],
  [-35.2605, 141.1797],
  [-35.2604, 141.1789],
  [-35.2603, 141.1777],
  [-35.2622, 141.1370],
  [-35.2622, 141.1350],
  [-35.2620, 141.1330],
  [-35.2592, 141.1110],
  [-35.2585, 141.1081],
  [-35.2496, 141.0788],
  [-35.2493, 141.0767],
  [-35.2492, 141.0753],
  [-35.2497, 141.0678],
  [-35.2495, 141.0650],
  [-35.2424, 141.0350],
  [-35.2418, 141.0314],
  [-35.2417, 141.0259],
  [-35.2413, 141.0232],
  [-35.2402, 141.0204],
  [-35.2360, 141.0133],
  [-35.2352, 141.0108],
  [-35.2349, 141.0079],
  [-35.2366, 140.9857],
  [-35.2366, 140.9691],
  [-35.2369, 140.9672],
  [-35.2372, 140.9659]
];

export const panitya: Line = {
  name: 'Panitya',
  state: 'VIC',
  segments: [
    {
      segments: [ouyenMurrayville],
      history: {
        opened: {
          date: '1912-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [murrayvillSaBorder],
      history: {
        opened: {
          date: '1915-07-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-06-27',
          status: 'closed'
        }]
      }
    }
  ]
}

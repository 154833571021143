import { Line } from "../../../trackTypes";

const line = [
  [-28.8784, 121.3309],
  [-28.8849, 121.3309],
  [-28.8927, 121.3299],
  [-28.8935, 121.3300],
  [-28.8957, 121.3308],
  [-28.8959, 121.3308],
  [-28.9074, 121.3309],
  [-28.9087, 121.3308],
  [-28.9140, 121.3308]
];

export const leonoraTrams: Line = {
  name: 'Leonora Trams',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1907-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1915-01-01',
          electrified: undefined
        }, {
          date: '1921-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../trackTypes";

const stageOne = [
  [-35.2781, 149.1292],
  [-35.2434, 149.1348],
  [-35.2417, 149.1356],
  [-35.2405, 149.1371],
  [-35.2346, 149.1455],
  [-35.2344, 149.1457],
  [-35.2341, 149.1455],
  [-35.2335, 149.1450],
  [-35.2330, 149.1448],
  [-35.2303, 149.1446],
  [-35.2258, 149.1435],
  [-35.2252, 149.1435],
  [-35.2246, 149.1436],
  [-35.2226, 149.1442],
  [-35.2220, 149.1446],
  [-35.2192, 149.1451],
  [-35.2172, 149.1452],
  [-35.2161, 149.1455],
  [-35.2144, 149.1464],
  [-35.2131, 149.1466],
  [-35.2112, 149.1469],
  [-35.2077, 149.1477],
  [-35.1917, 149.1514],
  [-35.1902, 149.1512],
  [-35.1888, 149.1503],
  [-35.1881, 149.1493],
  [-35.1877, 149.1479],
  [-35.1856, 149.1353]
];

const stage2a = [
  [-35.2781, 149.1292],
  [-35.2795, 149.1290],
  [-35.2794, 149.1278],
  [-35.2794, 149.1274],
  [-35.2796, 149.1270],
  [-35.2815, 149.1253],
  [-35.2818, 149.1252],
  [-35.2820, 149.1253],
  [-35.2840, 149.1264],
  [-35.2842, 149.1267],
  [-35.2843, 149.1270],
  [-35.2844, 149.1283],
  [-35.2893, 149.1274]
];

export const trams: Line = {
  name: 'Canberra Light Rail',
  state: 'ACT',
  segments: [
    {
      segments: [stageOne],
      history: {
        opened: {
          date: '2019-04-20',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [stage2a],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    }
  ]
}

import { Line } from "../../trackTypes";

const parkesNorthWestConnection = [
  [-33.1139, 148.1208],
  [-33.1163, 148.1208],
  [-33.1178, 148.1202],
  [-33.1191, 148.1190],
  [-33.1322, 148.1004],
  [-33.1333, 148.0993],
  [-33.1350, 148.0984],
  [-33.1376, 148.0979],
  [-33.1394, 148.0974],
  [-33.1410, 148.0966],
  [-33.1424, 148.0951],
  [-33.1432, 148.0934],
  [-33.1436, 148.0910]
];

const parkesJunction = [
  [-33.1440, 148.1030],
  [-33.1430, 148.1008],
  [-33.1418, 148.0993],
  [-33.1403, 148.0983],
  [-33.1392, 148.0980],
  [-33.1376, 148.0979]
];

export const inlandRailNsw: Line = {
  name: 'Inland Rail NSW',
  state: 'NSW',
  segments: [
    {
      segments: [parkesNorthWestConnection, parkesJunction],
      history: {
        opened: {
          date: '2020-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};

import { Line } from "../../../../trackTypes";

const springStreet = [
  [-37.81136, 144.97304],
  [-37.81527, 144.97485]
];

const spencerStreetCliftonHill = [
  [-37.81709, 144.95326],
  [-37.81136, 144.97304],
  [-37.81088, 144.97285],
  [-37.81026, 144.97279],
  [-37.80536, 144.97363],
  [-37.80637, 144.98300],
  [-37.79112, 144.98563],
  [-37.79081, 144.98582],
  [-37.79064, 144.98606],
  [-37.78830, 144.99169],
  [-37.78808, 144.99206],
  [-37.78566, 144.99451]
];

const cliftonHillNorthcote = [
  [-37.78566, 144.99451],
  [-37.78440, 144.99587],
  [-37.78393, 144.99615],
  [-37.76842, 144.99921],
  [-37.76683, 144.99919],
  [-37.76035, 145.00031],
  [-37.75251, 145.00162]
];

export const north2: Line = {
  name: 'North2',
  state: 'VIC',
  segments: [
    {
      segments: [springStreet],
      history: {
        opened: {
          date: '1994-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [spencerStreetCliftonHill],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1940-01-01',
          status: 'closed'
        }, {
          date: '1955-01-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [cliftonHillNorthcote],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1893-01-01',
          status: 'closed'
        }, {
          date: '1894-01-01',
          status: 'open'
        }, {
          date: '1897-01-01',
          status: 'closed'
        }, {
          date: '1901-01-01',
          status: 'open'
        }, {
          date: '1940-01-01',
          status: 'closed'
        }, {
          date: '1955-01-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};

import { Line } from "../../../trackTypes";

const lightRail = [
  [-32.9242, 151.7596],
  [-32.9256, 151.7629],
  [-32.9264, 151.7654],
  [-32.9267, 151.7676],
  [-32.9269, 151.7679],
  [-32.9271, 151.7680],
  [-32.9273, 151.7684],
  [-32.9267, 151.7742],
  [-32.9262, 151.7783],
  [-32.9263, 151.7818],
  [-32.9264, 151.7824],
  [-32.9274, 151.7863]
];

export const newCastleLightRail: Line = {
  name: 'New Castle Light Rail',
  state: 'NSW',
  segments: [
    {
      segments: [lightRail],
      history: {
        opened: {
          date: '2019-02-17',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        }
      }
    }
  ]
};

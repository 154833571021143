import { Line } from "../../../trackTypes";

const eastWest = [
  [-32.7323, 151.5461],
  [-32.7323, 151.5469],
  [-32.7327, 151.5469],
  [-32.7327, 151.5461],
  [-32.7323, 151.5461],
  [-32.7312, 151.5462],
  [-32.7280, 151.5459],
  [-32.7276, 151.5506],
  [-32.7276, 151.5510],
  [-32.7278, 151.5512],
  [-32.7310, 151.5532],
  [-32.7314, 151.5536],
  [-32.7325, 151.5561],
  [-32.7333, 151.5573],
  [-32.7338, 151.5578],
  [-32.7382, 151.5617],
  [-32.7390, 151.5622],
  [-32.7395, 151.5627],
  [-32.7404, 151.5638],
  [-32.7405, 151.5641],
  [-32.7407, 151.5652],
  [-32.7410, 151.5658],
  [-32.7416, 151.5665],
  [-32.7420, 151.5674],
  [-32.7421, 151.5683],
  [-32.7420, 151.5688],
  [-32.7415, 151.5709],
  [-32.7415, 151.5714],
  [-32.7417, 151.5722],
  [-32.7422, 151.5734],
  [-32.7431, 151.5746],
  [-32.7432, 151.5749],
  [-32.7432, 151.5775],
  [-32.7433, 151.5780],
  [-32.7436, 151.5784],
  [-32.7451, 151.5802],
  [-32.7466, 151.5815],
  [-32.7447, 151.5846],
  [-32.7444, 151.5859],
  [-32.7479, 151.5889],
  [-32.7494, 151.5904],
  [-32.7502, 151.5891],
  [-32.7515, 151.5903],
  [-32.7527, 151.5913],
  [-32.7522, 151.5922],
  [-32.7510, 151.5912],
  [-32.7515, 151.5903]
];

const highStreetStation = [
  [-32.7404, 151.5638],
  [-32.7412, 151.5647]
];

const westMaitlandStationBranch = [
  [-32.7314, 151.5536],
  [-32.7372, 151.5521],
  [-32.7376, 151.5520],
  [-32.7379, 151.5530]
];

export const maitlandTrams: Line = {
  name: 'Maitland (Trams)',
  state: 'NSW',
  segments: [
    {
      segments: [eastWest, highStreetStation],
      history: {
        opened: {
          date: '1909-02-08',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westMaitlandStationBranch],
      history: {
        opened: {
          date: '1910-10-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../trackTypes";

const line = [
  [-27.9600, 153.4243],
  [-27.9588, 153.4243],
  [-27.9586, 153.4244],
  [-27.9583, 153.4247],
  [-27.9581, 153.4248],
  [-27.9574, 153.4249],
  [-27.9572, 153.4249],
  [-27.9571, 153.4249],
  [-27.9568, 153.4248],
  [-27.9566, 153.4248],
  [-27.9562, 153.4249],
  [-27.9561, 153.4249],
  [-27.9553, 153.4249],
  [-27.9550, 153.4247],
  [-27.9547, 153.4244],
  [-27.9545, 153.4243],
  [-27.9543, 153.4243],
  [-27.9541, 153.4244],
  [-27.9539, 153.4247],
  [-27.9537, 153.4251],
  [-27.9537, 153.4259],
  [-27.9539, 153.4262],
  [-27.9541, 153.4264],
  [-27.9544, 153.4266],
  [-27.9553, 153.4265],
  [-27.9556, 153.4265],
  [-27.9568, 153.4261],
  [-27.9571, 153.4261],
  [-27.9574, 153.4263],
  [-27.9576, 153.4264],
  [-27.9579, 153.4265],
  [-27.9580, 153.4265],
  [-27.9598, 153.4266],
  [-27.9606, 153.4266],
  [-27.9609, 153.4264],
  [-27.9611, 153.4261],
  [-27.9611, 153.4253],
  [-27.9609, 153.4250],
  [-27.9603, 153.4244],
  [-27.9600, 153.4243]
];

export const seaWorldMonorail: Line = {
  name: 'Sea World Monorail',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1986-08-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'monorail'
        },
        trackChange: [{
          date: '2022-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}

import { Line } from "../../../trackTypes";

const loganRoadVictoriaBridge = [
  [-27.4883, 153.0378],
  [-27.4876, 153.0374],
  [-27.4865, 153.0359],
  [-27.4863, 153.0351],
  [-27.4855, 153.0290],
  [-27.4830, 153.0263],
  [-27.4734, 153.0192],
];

const woolloongabbaLoop = [
  [-27.4883, 153.0378],
  [-27.4885, 153.0395],
  [-27.4871, 153.0398],
  [-27.4867, 153.0373],
  [-27.4865, 153.0359]
];

const woolloongabbaCutThrough = [
  [-27.4878, 153.0396],
  [-27.4875, 153.0375],
  [-27.4867, 153.0373]
];

const greenslopesExtension = [
  [-27.4883, 153.0378],
  [-27.4894, 153.0384],
  [-27.4906, 153.0400],
  [-27.4922, 153.0409],
  [-27.4942, 153.0413],
  [-27.4964, 153.0413],
  [-27.4979, 153.0423],
  [-27.4986, 153.0445],
  [-27.5013, 153.0464],
  [-27.5023, 153.0468],
  [-27.5038, 153.0472],
  [-27.5061, 153.0497],
  [-27.5071, 153.0503],
  [-27.5080, 153.0513],
  [-27.5056, 153.0550]
];

const hollandParkExtension = [
  [-27.5080, 153.0513],
  [-27.5098, 153.0529],
  [-27.5101, 153.0530],
  [-27.5108, 153.0530],
  [-27.5120, 153.0530],
  [-27.5131, 153.0537],
  [-27.5192, 153.0613]
];

const coorparooExtension = [
  [-27.4986, 153.0445],
  [-27.4987, 153.0455],
  [-27.4989, 153.0501],
  [-27.4988, 153.0512],
  [-27.4984, 153.0525],
  [-27.4979, 153.0531],
  [-27.4968, 153.0547],
  [-27.4959, 153.0570],
  [-27.4951, 153.0579],
  [-27.4926, 153.0625]
];

const campHillExtension = [
  [-27.4926, 153.0625],
  [-27.4921, 153.0644],
  [-27.4916, 153.0676],
  [-27.4911, 153.0695],
  [-27.4918, 153.0719],
  [-27.4925, 153.0730],
  [-27.4926, 153.0745],
  [-27.4929, 153.0755],
  [-27.4931, 153.0772]
];

const belmontExtension = [
  [-27.4931, 153.0772],
  [-27.4930, 153.0836],
  [-27.4932, 153.0843],
  [-27.4936, 153.0848],
  [-27.4954, 153.0862],
  [-27.4957, 153.0867],
  [-27.4959, 153.0872],
  [-27.4953, 153.0910],
  [-27.4958, 153.0951]
];

const cavendishRoadExtension = [
  [-27.4945, 153.0592],
  [-27.4992, 153.0621],
  [-27.4995, 153.0622],
  [-27.5020, 153.0617],
  [-27.5053, 153.0613],
  [-27.5071, 153.0637],
  [-27.5076, 153.0638]
];

const greyStreet = [
  [-27.4830, 153.0263],
  [-27.4827, 153.0244],
  [-27.4739, 153.0183],
  [-27.4734, 153.0192]
];

const russellStreet = [
  [-27.4757, 153.01955],
  [-27.4752, 153.0205]
];

const mountGravattExtension = [
  [-27.5192, 153.0613],
  [-27.5220, 153.0650],
  [-27.5274, 153.0701],
  [-27.5326, 153.0742],
  [-27.5367, 153.0781]
];

const ipswichRoad = [
  [-27.4865, 153.0359],
  [-27.4885, 153.0359],
  [-27.4935, 153.0345],
  [-27.4947, 153.0345],
  [-27.4990, 153.0355],
  [-27.5011, 153.0350],
  [-27.5024, 153.0344],
  [-27.5034, 153.0342],
  [-27.5046, 153.0343],
  [-27.5062, 153.0346],
  [-27.5080, 153.0338],
  [-27.5107, 153.0321],
  [-27.5162, 153.0300],
  [-27.5181, 153.0293],
  [-27.5186, 153.0287],
  [-27.5212, 153.0263]
];

const eastBrisbane = [
  [-27.4871, 153.0398],
  [-27.4874, 153.0420],
  [-27.4856, 153.0423],
  [-27.4854, 153.0428],
  [-27.4835, 153.0431],
  [-27.4833, 153.0432],
  [-27.4792, 153.0440],
];

const balmorlExtension = [
  [-27.4792, 153.0440],
  [-27.4797, 153.0465],
  [-27.4768, 153.0492],
  [-27.4765, 153.0497],
  [-27.4749, 153.0545],
  [-27.4733, 153.0573],
  [-27.4731, 153.0581],
  [-27.4725, 153.0594],
  [-27.4726, 153.0610],
  [-27.4718, 153.0635],
  [-27.4697, 153.0636],
  [-27.4678, 153.0643],
  [-27.4661, 153.0644],
  [-27.4602, 153.0631],
  [-27.4580, 153.0633],
  [-27.4557, 153.0637],
  [-27.4543, 153.0631],
  [-27.4520, 153.0625]
];

const duttonPark = [
  [-27.4739, 153.0183],
  [-27.4769, 153.0128],
  [-27.4773, 153.0127],
  [-27.4817, 153.0171],
  [-27.4819, 153.0188],
  [-27.4831, 153.0197],
  [-27.4850, 153.0197],
  [-27.4871, 153.0193],
  [-27.4880, 153.0187],
  [-27.4889, 153.0185],
  [-27.4905, 153.0187],
  [-27.4941, 153.0228],
  [-27.4950, 153.0248],
  [-27.4966, 153.0262],
  [-27.4968, 153.0269],
  [-27.4980, 153.0275],
  [-27.4992, 153.0280],
  [-27.5014, 153.0297]
];

const cornwallStreet = [
  [-27.5004, 153.0289],
  [-27.5001, 153.0292],
  [-27.5009, 153.0351]
];

const westEnd = [
  [-27.4773, 153.0127],
  [-27.4809, 153.0120],
  [-27.4804, 153.0087],
  [-27.4806, 153.0080],
  [-27.4804, 153.0073],
  [-27.4868, 153.0061],
  [-27.4865, 153.0041],
  [-27.4893, 153.0035],
  [-27.4897, 153.0032]
];

const moorookaExtension = [
  [-27.5212, 153.0263],
  [-27.5248, 153.0230],
  [-27.5271, 153.0226],
  [-27.5288, 153.0234],
  [-27.5318, 153.0240],
  [-27.5329, 153.0248]
];

const sailsburyExtension = [
  [-27.5329, 153.0248],
  [-27.5332, 153.0250],
  [-27.5338, 153.0250],
  [-27.5348, 153.0242],
  [-27.5357, 153.0231],
  [-27.5370, 153.0223],
  [-27.5385, 153.0226],
  [-27.5417, 153.0214],
  [-27.5436, 153.0222],
  [-27.5448, 153.0303]
];

const oKeefeStreet = [
  [-27.4969, 153.0350],
  [-27.4979, 153.0423]
];

export const brisbaneTramsSouth: Line = {
  name: 'Brisbane Trams (South)',
  state: 'QLD',
  segments: [
    {
      segments: [loganRoadVictoriaBridge],
      history: {
        opened: {
          date: '1897-06-21',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [woolloongabbaLoop],
      history: {
        opened: {
          date: '1897-06-21',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [woolloongabbaCutThrough],
      history: {
        opened: {
          date: '1897-06-21',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greenslopesExtension],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hollandParkExtension],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [campHillExtension],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cavendishRoadExtension],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [coorparooExtension],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [belmontExtension],
      history: {
        opened: {
          date: '1948-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greyStreet],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [russellStreet],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westEnd],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mountGravattExtension],
      history: {
        opened: {
          date: '1951-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eastBrisbane],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [balmorlExtension],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ipswichRoad],
      history: {
        opened: {
          date: '1899-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sailsburyExtension],
      history: {
        opened: {
          date: '1940-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [duttonPark],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cornwallStreet],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oKeefeStreet],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [moorookaExtension],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}

import { Line } from "../../../trackTypes";

const castlecliff = [
  [-39.9145, 175.0503],
  [-39.9173, 175.0494],
  [-39.9191, 175.0497],
  [-39.9232, 175.0515],
  [-39.9245, 175.0518],
  [-39.9274, 175.0539],
  [-39.9353, 175.0459],
  [-39.9386, 175.0456],
  [-39.9399, 175.0459],
  [-39.9402, 175.0446],
  [-39.9408, 175.0431],
  [-39.9427, 175.0405],
  [-39.9443, 175.0397],
  [-39.9451, 175.0390],
  [-39.9487, 175.0330],
  [-39.9490, 175.0318],
  [-39.9481, 175.0216],
  [-39.9484, 175.0207],
  [-39.9495, 175.0152],
  [-39.9478, 175.0108],
  [-39.9475, 175.0083],
  [-39.9465, 175.0040],
  [-39.9457, 175.0028],
  [-39.9453, 175.0014],
  [-39.9439, 174.9989],
  [-39.9432, 174.9961],
  [-39.9431, 174.9942],
  [-39.9429, 174.9929],
  [-39.9432, 174.9884],
  [-39.9363, 174.9818],
  [-39.9385, 174.9776]
];

const eastTown = [
  [-39.9278, 175.0435],
  [-39.9209, 175.0504],
  [-39.9198, 175.0559],
  [-39.9180, 175.0597],
  [-39.9174, 175.0597],
  [-39.9172, 175.0632],
  [-39.9177, 175.0651],
  [-39.9173, 175.0772]
];

const aramoho = [
  [-39.9431, 175.0449],
  [-39.9407, 175.0495],
  [-39.9348, 175.0553],
  [-39.9254, 175.0394],
  [-39.9145, 175.0503],
  [-39.9130, 175.0515],
  [-39.9113, 175.0531],
  [-39.9083, 175.0554]
];

const railwayBridgeQuickAvenue = [
  [-39.9083, 175.0554],
  [-39.9079, 175.0558],
  [-39.9060, 175.0580],
  [-39.9014, 175.0660],
  [-39.9000, 175.0681],
  [-39.8979, 175.0708],
  [-39.8951, 175.0757]
];

const quickAvenueAramohoCemetery = [
  [-39.8951, 175.0757],
  [-39.8948, 175.0764],
  [-39.8939, 175.0784],
  [-39.8934, 175.0814],
  [-39.8944, 175.0894],
  [-39.8948, 175.0913]
];

export const whanganuiTrams: Line = {
  name: 'Whanganui Trams',
  state: 'NZ',
  segments: [
    {
      segments: [eastTown],
      history: {
        opened: {
          date: '1914-11-03',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-09-24',
          status: 'closed'
        }]
      }
    },
    {
      segments: [castlecliff],
      history: {
        opened: {
          date: '1912-10-17',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-09-24',
          status: 'closed'
        }]
      }
    },
    {
      segments: [aramoho],
      history: {
        opened: {
          date: '1908-12-11',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-07-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [railwayBridgeQuickAvenue],
      history: {
        opened: {
          date: '1915-04-28',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-07-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [quickAvenueAramohoCemetery],
      history: {
        opened: {
          date: '1923-09-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-07-22',
          status: 'closed'
        }]
      }
    }
  ]
}

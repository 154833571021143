import { Line } from "../../../trackTypes";

const sectionOne = [
  [-31.9734, 141.4504],
  [-31.9679, 141.4458],
  [-31.9667, 141.4477],
  [-31.9647, 141.4510],
  [-31.9644, 141.4517],
  [-31.9642, 141.4529],
  [-31.9639, 141.4536],
  [-31.9620, 141.4567],
  [-31.9629, 141.4576],
  [-31.9569, 141.4676],
  [-31.9496, 141.4616],
  [-31.9417, 141.4743]
];

const sectionTwo = [
  [-31.9667, 141.4477],
  [-31.9636, 141.4451],
  [-31.9648, 141.4431],
  [-31.9604, 141.4394],
  [-31.9515, 141.4540],
  [-31.9498, 141.4526],
  [-31.9461, 141.4586],
  [-31.9496, 141.4616]
];

export const brokenHillTrams: Line = {
  name: 'Broken Hill (Trams)',
  state: 'NSW',
  segments: [
    {
      segments: [sectionOne, sectionTwo],
      history: {
        opened: {
          date: '1902-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};

import styled from 'styled-components';

const Button = styled.button`
  border-radius: 1rem;
  font-size: 1rem;
  border: 1px solid grey;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0);
  color: white;

  &:hover {
    background-color: rgba(102, 102, 102, 0.125)
  }
`;

export default Button;

import { Line } from "../../../../trackTypes";

const connector = [
  [-37.86092, 145.01528],
  [-37.86089, 145.01539],
  [-37.86076, 145.01576],
  [-37.86076, 145.01600],
  [-37.86236, 145.02834]
];

const northSouth = [
  [-37.80770, 145.03816],
  [-37.83997, 145.03223],
  [-37.84645, 145.03104],
  [-37.85661, 145.02929]
];

const eastMalvern = [
  [-37.85661, 145.02929],
  [-37.86236, 145.02834],
  [-37.86508, 145.05007]
];

export const east5: Line = {
  name: 'East5',
  state: 'VIC',
  segments: [
    {
      segments: [connector],
      history: {
        opened: {
          date: '1928-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [northSouth],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [eastMalvern],
      history: {
        opened: {
          date: '1910-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};

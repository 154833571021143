import { Line } from "../../../trackTypes";

//CABLE TRAMS
const stuartStreet = [
  [-45.8740, 170.5034],
  [-45.8727, 170.4984],
  [-45.8701, 170.4968],
  [-45.8691, 170.4956],
  [-45.8664, 170.4906],
  [-45.8659, 170.4871]
];

const mornington = [
  [-45.8779, 170.5013],
  [-45.8825, 170.4861],
  [-45.8825, 170.4853],
  [-45.8818, 170.4823]
];

const maryhillExtension = [
  [-45.8818, 170.4823],
  [-45.8833, 170.4817],
  [-45.8888, 170.4790]
];

const elginRoadExtension = [
  [-45.8818, 170.4823],
  [-45.8813, 170.4806],
  [-45.8807, 170.4765],
  [-45.8832, 170.4754],
  [-45.8836, 170.4756],
  [-45.8878, 170.4739]
];

const kaikorai = [
  [-45.8774, 170.5017],
  [-45.8769, 170.5000],
  [-45.8748, 170.4986],
  [-45.8735, 170.4939],
  [-45.8732, 170.4936],
  [-45.8725, 170.4936],
  [-45.8712, 170.4905],
  [-45.8722, 170.4896],
  [-45.8723, 170.4888],
  [-45.8721, 170.4877],
  [-45.8719, 170.4869],
  [-45.8708, 170.4819],
  [-45.8704, 170.4812],
  [-45.8696, 170.4808],
  [-45.8673, 170.4803]
];

//ELECTRIC TRAMS
const maoriHill = [
  [-45.8712, 170.4905],
  [-45.8706, 170.4910],
  [-45.8702, 170.4911],
  [-45.8677, 170.4897],
  [-45.8673, 170.4898],
  [-45.8666, 170.4912],
  [-45.8637, 170.4944],
  [-45.8600, 170.4966],
  [-45.8596, 170.4971],
  [-45.8584, 170.4986],
  [-45.8584, 170.5003],
  [-45.8581, 170.5012],
  [-45.8571, 170.5029],
  [-45.8564, 170.5060]
];

const normanby = [
  [-45.8778, 170.5015],
  [-45.8660, 170.5081],
  [-45.8547, 170.5145],
  [-45.8545, 170.5149],
  [-45.8545, 170.5166],
  [-45.8551, 170.5188],
  [-45.8503, 170.5259],
  [-45.8499, 170.5267],
  [-45.8474, 170.5342],
  [-45.8433, 170.5411]
];

const howeStreet = [
  [-45.8586, 170.5123],
  [-45.8598, 170.5166],
  [-45.8756, 170.5077],
  [-45.8762, 170.5071],
  [-45.8778, 170.5015]
];

const opoho = [
  [-45.8551, 170.5188],
  [-45.8572, 170.5269],
  [-45.8567, 170.5278],
  [-45.8548, 170.5300],
  [-45.8538, 170.5316]
];

const andersonsBay = [
  [-45.8841, 170.4980],
  [-45.8881, 170.5001],
  [-45.8918, 170.5033],
  [-45.8942, 170.5046],
  [-45.8971, 170.5092],
  [-45.8987, 170.5122],
  [-45.8987, 170.5126],
  [-45.8979, 170.5140],
  [-45.8978, 170.5146],
  [-45.8976, 170.5216],
  [-45.8964, 170.5230],
  [-45.8961, 170.5253],
  [-45.8947, 170.5270],
  [-45.8946, 170.5275],
  [-45.8947, 170.5280],
  [-45.8959, 170.5301],
  [-45.8970, 170.5311]
];

const caversham = [
  [-45.8778, 170.5015],
  [-45.8841, 170.4980],
  [-45.8873, 170.4961],
  [-45.8890, 170.4956],
  [-45.8908, 170.4941],
  [-45.8914, 170.4943],
  [-45.8917, 170.4940],
  [-45.8920, 170.4928],
  [-45.8919, 170.4918],
  [-45.8918, 170.4894],
  [-45.8920, 170.4887],
  [-45.8924, 170.4884],
  [-45.8929, 170.4884],
  [-45.8932, 170.4881],
  [-45.8959, 170.4817],
  [-45.8962, 170.4810],
  [-45.8959, 170.4768]
];

const hillsideRoad = [
  [-45.8932, 170.4983],
  [-45.8989, 170.4846]
];

const cavershamSecondary = [
  [-45.8989, 170.4846],
  [-45.8987, 170.4839],
  [-45.8959, 170.4817]
];

const stClair = [
  [-45.8989, 170.4846],
  [-45.9043, 170.4877],
  [-45.9076, 170.4871],
  [-45.9110, 170.4900],
  [-45.9123, 170.4867]
];

const kingEdwardStreet = [
  [-45.8873, 170.4961],
  [-45.8891, 170.4960],
  [-45.8901, 170.4957],
  [-45.8932, 170.4983],
  [-45.9019, 170.5056]
];

const stKilda = [
  [-45.9019, 170.5056],
  [-45.9060, 170.5091],
  [-45.9055, 170.5115]
];

const forburyPark = [
  [-45.9019, 170.5056],
  [-45.9046, 170.4992],
  [-45.9049, 170.4984],
  [-45.9078, 170.5008],
  [-45.9075, 170.5017],
  [-45.9046, 170.4992]
];

const tahunaPark = [
  [-45.8983, 170.5113],
  [-45.9029, 170.5133],
  [-45.9040, 170.5131],
  [-45.9048, 170.5125],
  [-45.9055, 170.5115]
];

const loganPark = [
  [-45.8660, 170.5081],
  [-45.8684, 170.5168],
  [-45.8666, 170.5178],
  [-45.8678, 170.5226]
];

export const dunedinTrams: Line = {
  name: 'Dunedin Trams',
  state: 'NZ',
  segments: [
    {
      segments: [stuartStreet],
      history: {
        opened: {
          date: '1900-10-06',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-07-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mornington],
      history: {
        opened: {
          date: '1883-03-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-03-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maryhillExtension],
      history: {
        opened: {
          date: '1885-03-18',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1955-10-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [elginRoadExtension],
      history: {
        opened: {
          date: '1906-10-06',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1910-01-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kaikorai],
      history: {
        opened: {
          date: '1881-02-06',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1951-10-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maoriHill],
      history: {
        opened: {
          date: '1900-10-23',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1936-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [normanby],
      history: {
        opened: {
          date: '1903-12-24',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1951-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [howeStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [opoho],
      history: {
        opened: {
          date: '1924-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [andersonsBay],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [caversham],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hillsideRoad],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cavershamSecondary],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1938-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stClair],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingEdwardStreet],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stKilda],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [forburyPark],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tahunaPark],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [loganPark],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1943-05-01',
          status: 'closed'
        }]
      }
    }
  ]
}

import { Line } from "../../../trackTypes";

const milfordBayswater = [
  [-36.8195, 174.7671],
  [-36.8178, 174.7687],
  [-36.8172, 174.7689],
  [-36.8167, 174.7688],
  [-36.8162, 174.7689],
  [-36.8160, 174.7693],
  [-36.8147, 174.7762],
  [-36.8082, 174.7901],
  [-36.7986, 174.7855],
  [-36.7980, 174.7847],
  [-36.7973, 174.7789],
  [-36.7892, 174.7722],
  [-36.7766, 174.7753],
  [-36.7750, 174.7742],
  [-36.7723, 174.7636],
  [-36.7819, 174.7534],
  [-36.7826, 174.7530],
  [-36.7898, 174.7617],
  [-36.7885, 174.7635],
  [-36.7855, 174.7693],
  [-36.7892, 174.7722]
];

const devonport = [
  [-36.8321, 174.7968],
  [-36.8303, 174.7978],
  [-36.8289, 174.7978],
  [-36.8286, 174.7976],
  [-36.8284, 174.7968],
  [-36.8261, 174.7965],
  [-36.8257, 174.7967],
  [-36.8254, 174.7972],
  [-36.8248, 174.7982],
  [-36.8242, 174.8003],
  [-36.8229, 174.8026],
  [-36.8225, 174.8044],
  [-36.8210, 174.8050],
  [-36.8214, 174.8055],
  [-36.8207, 174.8065]
];

const victoriaAvenue = [
  [-36.8807, 174.7981],
  [-36.8770, 174.7985],
  [-36.8763, 174.7982],
  [-36.8754, 174.7983],
  [-36.8748, 174.7983],
  [-36.8734, 174.7989],
  [-36.8695, 174.7998],
  [-36.8653, 174.8001],
  [-36.8643, 174.8000]
];

const carlawPark = [
  [-36.8513, 174.7764],
  [-36.8520, 174.7757],
  [-36.8522, 174.7751],
  [-36.8536, 174.7738]
];

const greenlaneHospital = [
  [-36.8933, 174.7744],
  [-36.8930, 174.7818]
];

const pointChevalier = [
  [-36.8643, 174.7373],
  [-36.8638, 174.7345],
  [-36.8640, 174.7339],
  [-36.8647, 174.7331],
  [-36.8677, 174.7284],
  [-36.8680, 174.7276],
  [-36.8681, 174.7260],
  [-36.8679, 174.7186],
  [-36.8689, 174.7138],
  [-36.8706, 174.7102],
  [-36.8705, 174.7098],
  [-36.8697, 174.7097],
  [-36.8599, 174.7023],
  [-36.8595, 174.7023],
  [-36.8543, 174.7041],
  [-36.8527, 174.7042]
];

const pencarrowAvenue = [
  [-36.8650, 174.7605],
  [-36.8659, 174.7614],
  [-36.8668, 174.7617],
  [-36.8672, 174.7617],
  [-36.8680, 174.7610],
  [-36.8695, 174.7603],
  [-36.8701, 174.7604],
  [-36.8718, 174.7620],
  [-36.8721, 174.7621],
  [-36.8727, 174.7620],
  [-36.8743, 174.7622],
  [-36.8745, 174.7621],
  [-36.8750, 174.7617],
  [-36.8755, 174.7616],
  [-36.8762, 174.7615],
  [-36.8770, 174.7612],
  [-36.8787, 174.7613],
  [-36.8794, 174.7612],
  [-36.8805, 174.7619],
  [-36.8824, 174.7621],
  [-36.8842, 174.7610],
  [-36.8846, 174.7610],
  [-36.8854, 174.7612],
  [-36.8878, 174.7605]
];

const threeKings = [
  [-36.8878, 174.7605],
  [-36.8995, 174.7567],
  [-36.9018, 174.7592],
  [-36.9031, 174.7594],
  [-36.9088, 174.7586]
];

const herbertRoad = [
  [-36.8681, 174.7536],
  [-36.8826, 174.7488]
];

const mountRoskill = [
  [-36.8826, 174.7488],
  [-36.9043, 174.7418]
];

const edenPark = [
  [-36.8715, 174.7470],
  [-36.8726, 174.7467],
  [-36.8729, 174.7465],
  [-36.8729, 174.7460],
  [-36.8727, 174.7446],
  [-36.8731, 174.7433],
  [-36.8738, 174.7422],
  [-36.8740, 174.7436]
];

const calgaryStreet = [
  [-36.8738, 174.7422],
  [-36.8746, 174.7418],
  [-36.8916, 174.7363]
];

const owairaka = [
  [-36.8916, 174.7363],
  [-36.8920, 174.7362],
  [-36.8928, 174.7365],
  [-36.8931, 174.7364],
  [-36.8969, 174.7335],
  [-36.8939, 174.7273]
];

const herneBay = [
  [-36.8473, 174.7442],
  [-36.8450, 174.7402],
  [-36.8455, 174.7342],
  [-36.8482, 174.7309],
  [-36.8483, 174.7283]
];

const zoo = [
  [-36.8643, 174.7373],
  [-36.8635, 174.7372],
  [-36.8622, 174.7350],
  [-36.8620, 174.7329],
  [-36.8634, 174.7300],
  [-36.8634, 174.7296],
  [-36.8622, 174.7260],
  [-36.8616, 174.7248],
  [-36.8601, 174.7219]
];

const garnetRoad = [
  [-36.8616, 174.7248],
  [-36.8582, 174.7230],
  [-36.8538, 174.7180]
];

const greatNorthRoad = [
  [-36.8596, 174.7527],
  [-36.8646, 174.7465],
  [-36.8670, 174.7395],
  [-36.8669, 174.7391],
  [-36.8660, 174.7377],
  [-36.8643, 174.7373]
];

const richmondRoad = [
  [-36.8563, 174.7467],
  [-36.8567, 174.7459],
  [-36.8568, 174.7454],
  [-36.8572, 174.7410],
  [-36.8563, 174.7395],
  [-36.8550, 174.7389],
  [-36.8547, 174.7386],
  [-36.8545, 174.7382],
  [-36.8546, 174.7373],
  [-36.8536, 174.7343],
  [-36.8536, 174.7338],
  [-36.8554, 174.7299],
  [-36.8564, 174.7301],
  [-36.8584, 174.7304],
  [-36.8597, 174.7326],
  [-36.8620, 174.7330]
];

const centralRoad = [
  [-36.8639, 174.7609],
  [-36.8650, 174.7605],
  [-36.8658, 174.7594],
  [-36.8659, 174.7582],
  [-36.8662, 174.7576],
  [-36.8676, 174.7557],
  [-36.8681, 174.7536],
  [-36.8691, 174.7500],
  [-36.8698, 174.7488],
  [-36.8713, 174.7475],
  [-36.8715, 174.7470],
  [-36.8719, 174.7452]
];

const morningside = [
  [-36.8719, 174.7452],
  [-36.8728, 174.7421],
  [-36.8729, 174.7412],
  [-36.8732, 174.7405],
  [-36.8738, 174.7366]
];

const gladstoneRoad = [
  [-36.8738, 174.7366],
  [-36.8739, 174.7358],
  [-36.8749, 174.7345],
  [-36.8756, 174.7323],
  [-36.8801, 174.7256]
];

const avondale = [
  [-36.8801, 174.7256],
  [-36.8801, 174.7255],
  [-36.8819, 174.7202],
  [-36.8823, 174.7176],
  [-36.8837, 174.7162],
  [-36.8840, 174.7155],
  [-36.8927, 174.7066],
  [-36.8931, 174.7064],
  [-36.8952, 174.7041],
  [-36.8963, 174.7021],
  [-36.8965, 174.7010],
  [-36.8940, 174.7009],
  [-36.8945, 174.6991]
];

const khyberPassRoad = [
  [-36.8639, 174.7609],
  [-36.8676, 174.7781]
];

const ellerslieRacecourse = [
  [-36.8754, 174.7771],
  [-36.8793, 174.7823],
  [-36.8835, 174.7857],
  [-36.8869, 174.7892],
  [-36.8906, 174.7942],
  [-36.8866, 174.8009]
];

const greenLaneRoad = [
  [-36.8698, 174.7775],
  [-36.8725, 174.7807],
  [-36.8752, 174.7851],
  [-36.8769, 174.7887],
  [-36.8772, 174.7890],
  [-36.8781, 174.7893],
  [-36.8789, 174.7901],
  [-36.8804, 174.7948],
  [-36.8807, 174.7981],
  [-36.8811, 174.8019],
  [-36.8817, 174.8063],
  [-36.8823, 174.8080]
];

const uplandRoad = [
  [-36.8823, 174.8080],
  [-36.8823, 174.8085],
  [-36.8821, 174.8092],
  [-36.8803, 174.8118]
];

const meadowbank = [
  [-36.8803, 174.8118],
  [-36.8801, 174.8125],
  [-36.8804, 174.8147],
  [-36.8793, 174.8186],
  [-36.8796, 174.8209],
  [-36.8794, 174.8217],
  [-36.8769, 174.8252],
  [-36.8759, 174.8269]
];

const onehungaWharf = [
  [-36.8676, 174.7781],
  [-36.8716, 174.7770],
  [-36.8754, 174.7771],
  [-36.8807, 174.7759],
  [-36.8867, 174.7755],
  [-36.8933, 174.7744],
  [-36.8955, 174.7738],
  [-36.8967, 174.7739],
  [-36.8995, 174.7730],
  [-36.9119, 174.7761],
  [-36.9164, 174.7784],
  [-36.9159, 174.7844],
  [-36.9280, 174.7861],
  [-36.9299, 174.7876],
  [-36.9305, 174.7875],
  [-36.9312, 174.7868],
  [-36.9315, 174.7858],
  [-36.9315, 174.7847],
];

const ponsonbyRoad = [
  [-36.8473, 174.7442],
  [-36.8484, 174.7438],
  [-36.8534, 174.7454],
  [-36.8563, 174.7467],
  [-36.8596, 174.7527]
];

const victoriaStreet = [
  [-36.8473, 174.7442],
  [-36.8475, 174.7457],
  [-36.8484, 174.7478],
  [-36.8480, 174.7521],
  [-36.8481, 174.7567],
  [-36.8476, 174.7600],
  [-36.8479, 174.7616]
];

const greatNorthRoadHorse = [
  [-36.8596, 174.7527],
  [-36.8589, 174.7537],
  [-36.8575, 174.7580],
  [-36.8577, 174.7590]
];

const alfredStreet = [
  [-36.8639, 174.7609],
  [-36.8620, 174.7614],
  [-36.8580, 174.7638],
  [-36.8547, 174.7675],
  [-36.8520, 174.7701]
];

const symondsStreet = [
  [-36.8520, 174.7701],
  [-36.8493, 174.7734],
  [-36.8487, 174.7735],
  [-36.8482, 174.7732],
  [-36.8474, 174.7722],
  [-36.8457, 174.7713]
];

const parnellRoad = [
  [-36.8446, 174.7666],
  [-36.8455, 174.7698],
  [-36.8457, 174.7713],
  [-36.8458, 174.7721],
  [-36.8477, 174.7740],
  [-36.8490, 174.7745],
  [-36.8513, 174.7764],
  [-36.8520, 174.7781],
  [-36.8523, 174.7785],
  [-36.8590, 174.7829],
  [-36.8604, 174.7827],
  [-36.8623, 174.7816],
  [-36.8647, 174.7791],
  [-36.8676, 174.7781]
];

const pittStreet = [
  [-36.8584, 174.7635],
  [-36.8579, 174.7622],
  [-36.8579, 174.7614],
  [-36.8579, 174.7604],
  [-36.8577, 174.7590],
  [-36.8538, 174.7585],
  [-36.8504, 174.7606],
  [-36.8498, 174.7608],
  [-36.8508, 174.7645]
];

const queenStreet = [
  [-36.8539, 174.7682],
  [-36.8524, 174.7670],
  [-36.8511, 174.7654],
  [-36.8508, 174.7645],
  [-36.8486, 174.7654],
  [-36.8446, 174.7666],
  [-36.8435, 174.7670]
];

const queenStreetSouth = [
  [-36.8579, 174.7614],
  [-36.8508, 174.7645]
];

const farmersFreeTram = [
  [-36.8498, 174.7608],
  [-36.8479, 174.7616],
  [-36.8445, 174.7632]
];

const cityDepo = [
  [-36.8481, 174.7511],
  [-36.8451, 174.7529],
  [-36.8461, 174.7566],
  [-36.8445, 174.7632],
  [-36.8441, 174.7648],
  [-36.8446, 174.7666]
];

const wynyardLoop = [
  [-36.8442, 174.7545],
  [-36.8448, 174.7568],
  [-36.8412, 174.7582],
  [-36.8406, 174.7559],
  [-36.8442, 174.7545]
];

const motat = [
  [-36.8670, 174.7275],
  [-36.8670, 174.7278],
  [-36.8672, 174.7280],
  [-36.8675, 174.7279],
  [-36.8677, 174.7279],
  [-36.8678, 174.7276],
  [-36.8680, 174.7261],
  [-36.8680, 174.7256]
];

const greatNorthRoadExtension = [
  [-36.8680, 174.7256],
  [-36.8678, 174.7197],
  [-36.8672, 174.7191]
];

const zooExtension = [
  [-36.8672, 174.7191],
  [-36.8648, 174.7192]
];

const aviationHangerExtention = [
  [-36.8648, 174.7192],
  [-36.8642, 174.7192],
  [-36.8638, 174.7188],
  [-36.8621, 174.7181],
  [-36.8617, 174.7180],
  [-36.8599, 174.7162]
];

export const aucklandTrams: Line = {
  name: 'Auckland Trams',
  state: 'NZ',
  segments: [
    {
      segments: [milfordBayswater],
      history: {
        opened: {
          date: '1910-12-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1927-04-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [devonport],
      history: {
        opened: {
          date: '1886-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1888-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaAvenue],
      history: {
        opened: {
          date: '1913-06-09',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1955-04-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [carlawPark],
      history: {
        opened: {
          date: '1929-10-05',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-12-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greenlaneHospital],
      history: {
        opened: {
          date: '1939-04-23',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-12-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pointChevalier],
      history: {
        opened: {
          date: '1930-07-27',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pencarrowAvenue],
      history: {
        opened: {
          date: '1908-05-16',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-03-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [threeKings],
      history: {
        opened: {
          date: '1931-03-28',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-03-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [herbertRoad],
      history: {
        opened: {
          date: '1908-08-27',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-09-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mountRoskill],
      history: {
        opened: {
          date: '1930-02-02',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-09-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [edenPark],
      history: {
        opened: {
          date: '1925-09-12',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-07-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [calgaryStreet],
      history: {
        opened: {
          date: '1925-03-02',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-08-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [owairaka],
      history: {
        opened: {
          date: '1931-02-21',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-08-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [herneBay],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-12-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1949-09-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [zoo],
      history: {
        opened: {
          date: '1923-12-17',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-03-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [garnetRoad],
      history: {
        opened: {
          date: '1931-05-10',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-03-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greatNorthRoad],
      history: {
        opened: {
          date: '1903-05-11',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [richmondRoad],
      history: {
        opened: {
          date: '1931-02-08',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-03-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [centralRoad],
      history: {
        opened: {
          date: '1903-05-02',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [morningside],
      history: {
        opened: {
          date: '1912-07-08',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gladstoneRoad],
      history: {
        opened: {
          date: '1915-09-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [avondale],
      history: {
        opened: {
          date: '1932-01-31',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [khyberPassRoad],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-12-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1956-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ellerslieRacecourse],
      history: {
        opened: {
          date: '1923-06-17',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1955-01-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greenLaneRoad],
      history: {
        opened: {
          date: '1906-08-29',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-08-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [uplandRoad],
      history: {
        opened: {
          date: '1913-05-10',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-08-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [meadowbank],
      history: {
        opened: {
          date: '1930-06-08',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-08-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [onehungaWharf],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1903-09-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1956-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ponsonbyRoad],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-11-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1951-11-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaStreet],
      history: {
        opened: {
          date: '1903-10-27',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1949-09-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greatNorthRoadHorse],
      history: {
        opened: {
          date: '1884-11-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-11-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [alfredStreet],
      history: {
        opened: {
          date: '1885-11-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-12-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1956-01-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [symondsStreet],
      history: {
        opened: {
          date: '1921-02-07',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [parnellRoad],
      history: {
        opened: {
          date: '1903-01-31',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pittStreet],
      history: {
        opened: {
          date: '1884-11-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-11-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [queenStreet],
      history: {
        opened: {
          date: '1884-11-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-11-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1956-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [queenStreetSouth],
      history: {
        opened: {
          date: '1896-10-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-11-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1956-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [farmersFreeTram],
      history: {
        opened: {
          date: '1936-05-06',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cityDepo],
      history: {
        opened: {
          date: '1884-11-04',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-12-11',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wynyardLoop],
      history: {
        opened: {
          date: '2011-08-06',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [motat],
      history: {
        opened: {
          date: '1967-12-16',
          tracks: 2,
          gauge: 'dual',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [greatNorthRoadExtension],
      history: {
        opened: {
          date: '1980-12-10',
          tracks: 2,
          gauge: 'dual',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [zooExtension],
      history: {
        opened: {
          date: '1981-12-05',
          tracks: 2,
          gauge: 'dual',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [aviationHangerExtention],
      history: {
        opened: {
          date: '2008-04-27',
          tracks: 2,
          gauge: 'dual',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    }
  ]
}

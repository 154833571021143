import { Line } from "../../trackTypes";

const brisbaneStreetKingsBridge = [
  [-41.4381, 147.1374],
  [-41.4371, 147.1365],
  [-41.4407, 147.1300],
  [-41.4404, 147.1286],
  [-41.4405, 147.1274]
];

const kingsBridgeTrevallym = [
  [-41.4405, 147.1274],
  [-41.4399, 147.1268],
  [-41.4393, 147.1270],
  [-41.4390, 147.1269],
  [-41.4383, 147.1262],
  [-41.4379, 147.1260],
  [-41.4376, 147.1257],
  [-41.4375, 147.1255],
  [-41.4375, 147.1249],
  [-41.4373, 147.1246],
  [-41.4367, 147.1240],
  [-41.4352, 147.1224],
  [-41.4349, 147.1219],
  [-41.4349, 147.1215],
  [-41.4350, 147.1208],
  [-41.4353, 147.1198]
];

const cataractHill = [
  [-41.4407, 147.1300],
  [-41.4419, 147.1308],
  [-41.4440, 147.1329],
  [-41.4453, 147.1333],
  [-41.4455, 147.1333],
  [-41.4465, 147.1328],
  [-41.4475, 147.1326],
  [-41.4483, 147.1330],
  [-41.4500, 147.1307]
];

const brisbaneStreetDavidStreet = [
  [-41.4381, 147.1374],
  [-41.4362, 147.1407],
  [-41.4344, 147.1425],
  [-41.4333, 147.1437],
  [-41.4323, 147.1459],
  [-41.4316, 147.1467],
  [-41.4314, 147.1471],
  [-41.4314, 147.1481],
  [-41.4315, 147.1486],
  [-41.4359, 147.1566],
  [-41.4416, 147.1609]
];

const newstead = [
  [-41.4416, 147.1609],
  [-41.4420, 147.1613],
  [-41.4454, 147.1626]
];

const highStreet = [
  [-41.4323, 147.1459],
  [-41.4319, 147.1469],
  [-41.4319, 147.1472],
  [-41.4321, 147.1474],
  [-41.4385, 147.1472],
  [-41.4430, 147.1478],
  [-41.4434, 147.1480],
  [-41.4448, 147.1495]
];

const highStreetExtension = [
  [-41.4448, 147.1495],
  [-41.4465, 147.1515],
  [-41.4484, 147.1522]
];

const brisbaneStreetMcKenzieStreet = [
  [-41.4344, 147.1425],
  [-41.4310, 147.1392],
  [-41.4298, 147.1384],
  [-41.4235, 147.1363],
  [-41.4176, 147.1343],
  [-41.4128, 147.1329],
  [-41.4101, 147.1322]
];

const mcKensieRacecourse = [
  [-41.4101, 147.1322],
  [-41.4094, 147.1319],
  [-41.4086, 147.1328],
  [-41.4081, 147.1331],
  [-41.4070, 147.1334],
  [-41.4067, 147.1334],
  [-41.4062, 147.1330],
  [-41.4053, 147.1358]
];

const mobrayHeights = [
  [-41.4062, 147.1330],
  [-41.4025, 147.1309],
  [-41.3990, 147.1289]
];

const sandhill = [
  [-41.4381, 147.1374],
  [-41.4391, 147.1386],
  [-41.4402, 147.1393],
  [-41.4409, 147.1376],
  [-41.4449, 147.1390],
  [-41.4479, 147.1395],
  [-41.4517, 147.1426]
];

const sandhillCarrVillaCemetery = [
  [-41.4517, 147.1426],
  [-41.4551, 147.1456],
  [-41.4581, 147.1487],
  [-41.4592, 147.1515],
  [-41.4592, 147.1520],
  [-41.4590, 147.1523],
  [-41.4587, 147.1528],
  [-41.4586, 147.1532],
  [-41.4587, 147.1536],
  [-41.4594, 147.1553],
  [-41.4602, 147.1556],
  [-41.4608, 147.1555],
  [-41.4612, 147.1555],
  [-41.4615, 147.1559],
  [-41.4620, 147.1565],
  [-41.4639, 147.1582],
  [-41.4650, 147.1590],
  [-41.4690, 147.1611],
  [-41.4676, 147.1643]
];

const kingsWharf = [
  [-41.4371, 147.1365],
  [-41.4340, 147.1335],
  [-41.4325, 147.1334],
  [-41.4306, 147.1330],
  [-41.4275, 147.1318],
  [-41.4289, 147.1248],
  [-41.4317, 147.1272],
  [-41.4306, 147.1330]
];

export const launcestonTrams: Line = {
  name: 'Launceston Trams',
  state: 'TAS',
  segments: [
    {
      segments: [brisbaneStreetKingsBridge, brisbaneStreetMcKenzieStreet],
      history: {
        opened: {
          date: '1911-08-04',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingsBridgeTrevallym],
      history: {
        opened: {
          date: '1912-07-16',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cataractHill],
      history: {
        opened: {
          date: '1915-05-15',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mcKensieRacecourse],
      history: {
        opened: {
          date: '1916-01-26',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mobrayHeights],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brisbaneStreetDavidStreet],
      history: {
        opened: {
          date: '1911-08-04',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-12-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newstead],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-12-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingsWharf],
      history: {
        opened: {
          date: '1919-02-24',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [highStreet],
      history: {
        opened: {
          date: '1914-01-17',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [highStreetExtension],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandhill],
      history: {
        opened: {
          date: '1911-10-27',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandhillCarrVillaCemetery],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-12-01',
          status: 'closed'
        }]
      }
    }
  ]
}

import React from 'react';
import styled from "styled-components";
import Button from './Button';

const ModalCard = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #171717;
  color: white;
  min-height: 200px;
  min-width: 200px;
`;

const ExitButton = styled(Button)`
  position: absolute;
  right: 1rem;
`;

type ModalProps = {
  isOpen: boolean;
  children: React.ReactNode;
  close: Function;
};

const Modal = ({isOpen, children, close}: ModalProps) => {

  if (!isOpen) {
    return null;
  }

  return(
    <ModalCard>
      <ExitButton onClick={() => close()}>X</ExitButton>
      {children}
    </ModalCard>
  );
};

export default Modal;

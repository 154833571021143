import { Line } from "../../../trackTypes";

const line = [
  [-17.9719, 122.2386],
  [-17.9700, 122.2367],
  [-17.9695, 122.2366],
  [-17.9656, 122.2370],
  [-17.9649, 122.2373],
  [-17.9625, 122.2398],
  [-17.9621, 122.2400],
  [-17.9551, 122.2414],
  [-17.9553, 122.2430],
  [-17.9555, 122.2444],
  [-17.9515, 122.2451],
  [-17.9512, 122.2439],
  [-17.9544, 122.2433],
  [-17.9553, 122.2430]
];

/**
 * opened as horse tramway
 * converted to steam tram in 1910
 */

export const broomeTrams: Line = {
  name: 'Broome Trams',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1966-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../../trackTypes";

const manlyCurlCurl = [
  [-33.7925, 151.2855],
  [-33.7877, 151.2844],
  [-33.7873, 151.2842],
  [-33.7871, 151.2839],
  [-33.7867, 151.2812],
  [-33.7862, 151.2809],
  [-33.7849, 151.2811],
  [-33.7838, 151.2809],
  [-33.7829, 151.2804],
  [-33.7815, 151.2784],
  [-33.7790, 151.2748],
  [-33.7775, 151.2740],
  [-33.7770, 151.2735],
  [-33.7756, 151.2707],
  [-33.7744, 151.2701],
  [-33.7737, 151.2688],
  [-33.7732, 151.2683],
  [-33.7725, 151.2681],
  [-33.7709, 151.2685],
  [-33.7701, 151.2685],
  [-33.7685, 151.2681],
  [-33.7680, 151.2681],
  [-33.7650, 151.2704],
  [-33.7641, 151.2713],
  [-33.7619, 151.2717],
  [-33.7613, 151.2722],
  [-33.7611, 151.2730],
  [-33.7612, 151.2761],
  [-33.7609, 151.2773],
  [-33.7595, 151.2790],
  [-33.7584, 151.2807],
  [-33.7570, 151.2819]
];

const manlySpit = [
  [-33.7958, 151.2875],
  [-33.7949, 151.2816],
  [-33.7952, 151.2811],
  [-33.7957, 151.2810],
  [-33.7956, 151.2804],
  [-33.7960, 151.2803],
  [-33.7959, 151.2796],
  [-33.7965, 151.2794],
  [-33.7965, 151.2791],
  [-33.7958, 151.2791],
  [-33.7959, 151.2780],
  [-33.7959, 151.2763],
  [-33.7954, 151.2743],
  [-33.7951, 151.2739],
  [-33.7950, 151.2732],
  [-33.7948, 151.2726],
  [-33.7950, 151.2717],
  [-33.7950, 151.2689],
  [-33.7937, 151.2606],
  [-33.7960, 151.2528],
  [-33.7980, 151.2524],
  [-33.7984, 151.2525],
  [-33.7993, 151.2530],
  [-33.7997, 151.2532],
  [-33.8005, 151.2527],
  [-33.8005, 151.2521],
  [-33.8003, 151.2518],
  [-33.8001, 151.2518],
  [-33.7998, 151.2521],
  [-33.7996, 151.2522],
  [-33.7994, 151.2520],
  [-33.7993, 151.2512],
  [-33.7993, 151.2508],
  [-33.7996, 151.2504],
  [-33.7997, 151.2497],
  [-33.7999, 151.2493],
  [-33.8002, 151.2491],
  [-33.8011, 151.2490],
  [-33.8013, 151.2486],
  [-33.8013, 151.2475],
  [-33.8014, 151.2467]
];

const manlyHarbord = [
  [-33.7815, 151.2784],
  [-33.7812, 151.2788],
  [-33.7811, 151.2792],
  [-33.7810, 151.2798],
  [-33.7808, 151.2803],
  [-33.7800, 151.2814],
  [-33.7798, 151.2818],
  [-33.7798, 151.2822],
  [-33.7786, 151.2824],
  [-33.7789, 151.2851],
  [-33.7786, 151.2857],
  [-33.7812, 151.2890]
];

const curlCurlNarrabeen = [
  [-33.7570, 151.2819],
  [-33.7541, 151.2833],
  [-33.7511, 151.2887],
  [-33.7469, 151.2910],
  [-33.7456, 151.2928],
  [-33.7447, 151.2959],
  [-33.7447, 151.3017],
  [-33.7444, 151.3024],
  [-33.7430, 151.3036],
  [-33.7425, 151.3036],
  [-33.7400, 151.3028],
  [-33.7337, 151.3019],
  [-33.7275, 151.2993],
  [-33.7166, 151.2974],
  [-33.7127, 151.2972]
];

const belgraveStreet = [
  [-33.8006, 151.2838],
  [-33.7989, 151.2845],
  [-33.7957, 151.2852],
  [-33.7952, 151.2851],
  [-33.7930, 151.2856],
  [-33.7925, 151.2855]
];

const corsoNorthSteyne = [
  [-33.7990, 151.2845],
  [-33.7971, 151.2880],
  [-33.7938, 151.2869],
  [-33.7928, 151.2869],
  [-33.7925, 151.2855]
];

export const manlyTrams: Line = {
  name: 'Manly (Trams)',
  state: 'NSW',
  segments: [
    {
      segments: [manlyCurlCurl],
      history: {
        opened: {
          date: '1903-02-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-01-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1939-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [curlCurlNarrabeen],
      history: {
        opened: {
          date: '1913-12-08',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-01-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1939-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [manlyHarbord],
      history: {
        opened: {
          date: '1926-12-08',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [manlySpit],
      history: {
        opened: {
          date: '1911-01-09',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [belgraveStreet],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [corsoNorthSteyne],
      history: {
        opened: {
          date: '1903-02-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-01-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1914-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};

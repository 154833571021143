import { Line } from "../../../trackTypes";

//L1 line
const centralStationWentworthPark = [
  [-33.8801, 151.2072],
  [-33.8809, 151.2070],
  [-33.8820, 151.2062],
  [-33.8825, 151.2073],
  [-33.8819, 151.2078],
  [-33.8803, 151.2082],
  [-33.8798, 151.2058],
  [-33.8795, 151.2051],
  [-33.8790, 151.2009],
  [-33.8787, 151.2006]
];
//L1 line

//L2
const cbdTunnel = [
  [-33.8810, 151.2047],
  [-33.8829, 151.2080],
  [-33.8833, 151.2081],
  [-33.8849, 151.2069],
  [-33.8856, 151.2070],
  [-33.8865, 151.2083],
  [-33.8880, 151.2119],
  [-33.8895, 151.2149],
  [-33.8900, 151.2180]
];

const tunnel = [
  [-33.8900, 151.2180],
  [-33.8903, 151.2202],
  [-33.8906, 151.2209],
  [-33.8914, 151.2213]
];

const tunnelRandwick = [
  [-33.8914, 151.2213],
  [-33.8993, 151.2227],
  [-33.9008, 151.2229],
  [-33.9014, 151.2231],
  [-33.9036, 151.2258],
  [-33.9040, 151.2267],
  [-33.9048, 151.2279],
  [-33.9060, 151.2309],
  [-33.9065, 151.2311],
  [-33.9075, 151.2331],
  [-33.9082, 151.2339],
  [-33.9101, 151.2348],
  [-33.9110, 151.2356],
  [-33.9162, 151.2347],
  [-33.9171, 151.2409]
];

const circularQuay = [
  [-33.8612, 151.2083],
  [-33.8614, 151.2103]
];
//L2

//L3
const kingsford = [
  [-33.8993, 151.2227],
  [-33.9000, 151.2225],
  [-33.9018, 151.2228],
  [-33.9041, 151.2239],
  [-33.9050, 151.2240],
  [-33.9110, 151.2231],
  [-33.9115, 151.2232],
  [-33.9120, 151.2235],
  [-33.9134, 151.2250],
  [-33.9138, 151.2252],
  [-33.9178, 151.2261],
  [-33.9190, 151.2264],
  [-33.9225, 151.2270],
  [-33.9241, 151.2278],
  [-33.9244, 151.2281],
  [-33.9252, 151.2295]
];
//L3

const paramattaLightRail = [
  [-33.8078, 150.9874],
  [-33.8063, 150.9884],
  [-33.8029, 150.9919],
  [-33.8022, 150.9925],
  [-33.8031, 150.9938],
  [-33.8026, 150.9948],
  [-33.8016, 150.9977],
  [-33.8015, 150.9983],
  [-33.8014, 150.9993],
  [-33.8015, 150.9997],
  [-33.8016, 151.0004],
  [-33.8016, 151.0008],
  [-33.8002, 151.0042],
  [-33.8014, 151.0048],
  [-33.8032, 151.0057],
  [-33.8037, 151.0058],
  [-33.8045, 151.0058],
  [-33.8060, 151.0058],
  [-33.8078, 151.0055],
  [-33.8095, 151.0051],
  [-33.8103, 151.0048],
  [-33.8110, 151.0043],
  [-33.8123, 151.0038],
  [-33.8150, 151.0028],
  [-33.8170, 151.0118],
  [-33.8159, 151.0122],
  [-33.8165, 151.0142],
  [-33.8173, 151.0158],
  [-33.8177, 151.0174],
  [-33.8172, 151.0176],
  [-33.8170, 151.0179],
  [-33.8180, 151.0225],
  [-33.8183, 151.0231],
  [-33.8187, 151.0235],
  [-33.8190, 151.0242],
  [-33.8192, 151.0246],
  [-33.8200, 151.0320],
  [-33.8210, 151.0319]
];

export const sydneyLightRail: Line = {
  name: 'Sydney Light Rail',
  state: 'NSW',
  segments: [
    {
      segments: [centralStationWentworthPark],
      history: {
        opened: {
          date: '1997-08-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [
        cbdTunnel,
        {
          segment: tunnel,
          type: 'tunnel'
        },
        tunnelRandwick
      ],
      history: {
        opened: {
          date: '2019-12-14',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [circularQuay],
      history: {
        opened: {
          date: '2019-12-14',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [kingsford],
      history: {
        opened: {
          date: '2020-04-03',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [paramattaLightRail],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    }
  ]
};

import { Line } from "../../../trackTypes";

const rockdaleBrightonLeSands = [
  [-33.9526, 151.1369],
  [-33.9608, 151.1562],
  [-33.9644, 151.1547]
];

const kogarahSansSouci = [
  [-33.9706, 151.1363],
  [-33.9701, 151.1352],
  [-33.9659, 151.1312],
  [-33.9645, 151.1322],
  [-33.9629, 151.1327],
  [-33.9627, 151.1331],
  [-33.9658, 151.1357],
  [-33.9662, 151.1358],
  [-33.9662, 151.1367],
  [-33.9689, 151.1362],
  [-33.9706, 151.1363],
  [-33.9758, 151.1365],
  [-33.9818, 151.1367],
  [-33.9858, 151.1359],
  [-33.9927, 151.1317],
  [-34.0016, 151.1262],
  [-34.0026, 151.1264],
  [-34.0045, 151.1312],
  [-34.0041, 151.1341],
  [-34.0034, 151.1358],
  [-34.0030, 151.1381],
  [-33.9980, 151.1394],
  [-33.9939, 151.1401],
  [-33.9927, 151.1317]
];

const arncliffeBexley = [
  [-33.9364, 151.1469],
  [-33.9354, 151.1474],
  [-33.9343, 151.1464],
  [-33.9416, 151.1321],
  [-33.9436, 151.1295],
  [-33.9450, 151.1283],
  [-33.9462, 151.1279],
  [-33.9482, 151.1279],
  [-33.9507, 151.1257],
  [-33.9527, 151.1222],
  [-33.9528, 151.1215],
  [-33.9527, 151.1209],
  [-33.9530, 151.1199],
  [-33.9536, 151.1192],
  [-33.9544, 151.1190]
];

const sutherlandCronulla = [
  [-34.0326, 151.0571],
  [-34.0297, 151.0592],
  [-34.0298, 151.0622],
  [-34.0316, 151.0671],
  [-34.0323, 151.0747],
  [-34.0314, 151.0779],
  [-34.0322, 151.0826],
  [-34.0322, 151.0837],
  [-34.0329, 151.0882],
  [-34.0329, 151.0902],
  [-34.0344, 151.1048],
  [-34.0352, 151.1134],
  [-34.0372, 151.1179],
  [-34.0431, 151.1230],
  [-34.0439, 151.1291],
  [-34.0464, 151.1347],
  [-34.0472, 151.1375],
  [-34.0513, 151.1532],
  [-34.0554, 151.1516],
  [-34.0580, 151.1521],
  [-34.0584, 151.1524],
  [-34.0596, 151.1549],
  [-34.0613, 151.1537],
  [-34.0630, 151.1534],
  [-34.0638, 151.1536]
];

export const sydneyTramsSouth: Line = {
  name: 'Sydney Trams (South)',
  state: 'NSW',
  segments: [
    {
      segments: [rockdaleBrightonLeSands],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1900-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sutherlandCronulla],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1932-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [arncliffeBexley],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kogarahSansSouci],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../../../trackTypes";

const stKildaStationElwood = [
  [-37.8592, 144.9770],
  [-37.8640, 144.9815],
  [-37.8641, 144.9817],
  [-37.8702, 144.9806],
  [-37.8753, 144.9837],
  [-37.8756, 144.9838],
  [-37.8823, 144.9825],
  [-37.8877, 144.9914],
  [-37.8917, 144.9906]
];

const elwoodMiddleBrighton = [
  [-37.8917, 144.9906],
  [-37.9077, 144.9876],
  [-37.9082, 144.9874],
  [-37.9086, 144.9868],
  [-37.9090, 144.9865],
  [-37.9121, 144.9857]
];

const middleBrightonBrightonBeachStation = [
  [-37.9121, 144.9857],
  [-37.9128, 144.9858],
  [-37.9143, 144.9867],
  [-37.9156, 144.9871],
  [-37.9170, 144.9872],
  [-37.9189, 144.9879],
  [-37.9206, 144.9881],
  [-37.9223, 144.9878],
  [-37.9232, 144.9877],
  [-37.9241, 144.9874],
  [-37.9258, 144.9871],
  [-37.9264, 144.9873],
  [-37.9273, 144.9885]
];

const sandringhamStationBlackRock = [
  [-37.9488, 145.0049],
  [-37.9517, 145.0043],
  [-37.9522, 145.0079],
  [-37.9563, 145.0115],
  [-37.9577, 145.0112],
  [-37.9579, 145.0113],
  [-37.9580, 145.0115],
  [-37.9591, 145.0195],
  [-37.9754, 145.0161]
];

const blackRockBeaumaris = [
  [-37.9754, 145.0161],
  [-37.9756, 145.0176],
  [-37.9804, 145.0197],
  [-37.9816, 145.0290],
  [-37.9862, 145.0282],
  [-37.9867, 145.0327],
  [-37.9893, 145.0322],
  [-37.9899, 145.0370],
  [-37.9903, 145.0369],
  [-37.9907, 145.0403]
];

export const melbourneVicRailTrams: Line = {
  name: 'Melbourne Trams (Vic Rail)',
  state: 'VIC',
  segments: [
    {
      segments: [stKildaStationElwood],
      history: {
        opened: {
          date: '1906-05-05',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1959-02-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [elwoodMiddleBrighton],
      history: {
        opened: {
          date: '1906-12-22',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [middleBrightonBrightonBeachStation],
      history: {
        opened: {
          date: '1906-12-22',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandringhamStationBlackRock],
      history: {
        opened: {
          date: '1919-03-10',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1931-08-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [blackRockBeaumaris],
      history: {
        opened: {
          date: '1926-09-01',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1931-08-31',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../../trackTypes";

const sharedCbd = [
  [-46.4075, 168.3456],
  [-46.4075, 168.3473],
  [-46.4130, 168.3473],
  [-46.4130, 168.3537],
  [-46.4179, 168.3537]
];

const waikiwi = [
  [-46.4075, 168.3473],
  [-46.3905, 168.3473],
  [-46.3752, 168.3473]
];

const rugbyParkStadium = [
  [-46.4179, 168.3537],
  [-46.4178, 168.3614]
];

const georgetown = [
  [-46.4178, 168.3614],
  [-46.4201, 168.3614],
  [-46.4201, 168.3667],
  [-46.4206, 168.3668],
  [-46.4206, 168.3694],
  [-46.4225, 168.3695]
];

const northInvercargill = [
  [-46.4085, 168.3473],
  [-46.4085, 168.3642],
  [-46.4032, 168.3641],
  [-46.4031, 168.3646],
  [-46.3984, 168.3646],
  [-46.3983, 168.3655],
  [-46.3948, 168.3656]
];

const southInvercargill = [
  [-46.4179, 168.3537],
  [-46.4269, 168.3537],
  [-46.4269, 168.3614],
  [-46.4325, 168.3614]
];

export const invercargillTrams: Line = {
  name: 'Invercargill Trams',
  state: 'NZ',
  segments: [
    {
      segments: [sharedCbd],
      history: {
        opened: {
          date: '1912-03-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-05-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waikiwi],
      history: {
        opened: {
          date: '1912-03-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rugbyParkStadium],
      history: {
        opened: {
          date: '1912-03-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1951-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [georgetown],
      history: {
        opened: {
          date: '1912-03-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1951-07-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northInvercargill],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southInvercargill],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-05-31',
          status: 'closed'
        }]
      }
    }
  ]
}

import { Line } from "../../../trackTypes";

const adamstown = [
  [-32.9248, 151.7371],
  [-32.9290, 151.7312],
  [-32.9309, 151.7281],
  [-32.9315, 151.7271],
  [-32.9325, 151.7262]
];

const adamstownExtension = [
  [-32.9325, 151.7262],
  [-32.9352, 151.7256]
];

const carrington = [
  [-32.9263, 151.7619],
  [-32.9252, 151.7615],
  [-32.9249, 151.7616],
  [-32.9177, 151.7587],
  [-32.9159, 151.7657],
  [-32.9142, 151.7652],
  [-32.9118, 151.7647],
  [-32.9114, 151.7676],
  [-32.9093, 151.7672],
  [-32.9073, 151.7669],
  [-32.9070, 151.7690],
  [-32.9090, 151.7694],
  [-32.9093, 151.7672]
];

const glebe = [
  [-32.9274, 151.7672],
  [-32.9282, 151.7672],
  [-32.9298, 151.7666],
  [-32.9387, 151.7585],
  [-32.9386, 151.7538],
  [-32.9384, 151.7511],
  [-32.9387, 151.7457],
  [-32.9394, 151.7439],
  [-32.9399, 151.7431],
  [-32.9401, 151.7430]
];

const mayfield = [
  [-32.9240, 151.7561],
  [-32.9232, 151.7546],
  [-32.9220, 151.7534],
  [-32.9215, 151.7524],
  [-32.9211, 151.7522],
  [-32.9205, 151.7522],
  [-32.9203, 151.7521],
  [-32.9200, 151.7517],
  [-32.9184, 151.7504],
  [-32.9119, 151.7462],
  [-32.9109, 151.7458],
  [-32.9096, 151.7460],
  [-32.9091, 151.7463],
  [-32.9083, 151.7476],
  [-32.9079, 151.7480],
  [-32.9073, 151.7481]
];

const mayfieldExtension = [
  [-32.9073, 151.7481],
  [-32.9036, 151.7450],
  [-32.8973, 151.7365]
];

const merewether = [
  [-32.9267, 151.7748],
  [-32.9300, 151.7730],
  [-32.9401, 151.7637]
];

const merewetherExtension = [
  [-32.9401, 151.7637],
  [-32.9414, 151.7574],
  [-32.9463, 151.7518],
  [-32.9489, 151.7550]
];

const portWaratah = [
  [-32.9177, 151.7587],
  [-32.9165, 151.7582],
  [-32.9146, 151.7581],
  [-32.9125, 151.7585],
  [-32.9117, 151.7528]
];

const portWaratahExtension = [
  [-32.9117, 151.7528],
  [-32.9103, 151.7523],
  [-32.9095, 151.7524],
  [-32.9083, 151.7524],
  [-32.9043, 151.7534],
  [-32.9043, 151.7525],
  [-32.9035, 151.7518],
  [-32.9026, 151.7520],
  [-32.9000, 151.7545],
  [-32.8995, 151.7555],
  [-32.8985, 151.7594]
];

const portWaratahWharf = [
  [-32.9000, 151.7545],
  [-32.8979, 151.7570],
  [-32.8974, 151.7586],
  [-32.8969, 151.7689]
];

const racecourse = [
  [-32.9242, 151.7373],
  [-32.9249, 151.7371],
  [-32.9325, 151.7358],
  [-32.9331, 151.7401],
  [-32.9338, 151.7400],
  [-32.9331, 151.7357],
  [-32.9325, 151.7358]
];

const waratahJunctionWallsendSpeersPointJunction = [
  [-32.9071, 151.6627],
  [-32.9104, 151.6605],
  [-32.9111, 151.6597],
  [-32.9117, 151.6586],
  [-32.9139, 151.6570],
  [-32.9144, 151.6568],
  [-32.9154, 151.6567],
  [-32.9157, 151.6565],
  [-32.9160, 151.6560],
  [-32.9162, 151.6545],
  [-32.9164, 151.6539],
  [-32.9169, 151.6535],
  [-32.9172, 151.6532],
  [-32.9172, 151.6527],
  [-32.9171, 151.6515],
  [-32.9173, 151.6509],
  [-32.9178, 151.6505],
  [-32.9183, 151.6505],
  [-32.9188, 151.6505],
  [-32.9191, 151.6501],
  [-32.9193, 151.6494],
  [-32.9189, 151.6482],
  [-32.9189, 151.6476],
  [-32.9196, 151.6455],
  [-32.9197, 151.6448],
  [-32.9195, 151.6440],
  [-32.9198, 151.6429],
  [-32.9198, 151.6429],
  [-32.9211, 151.6421],
  [-32.9216, 151.6419],
  [-32.9225, 151.6419],
  [-32.9228, 151.6417],
  [-32.9232, 151.6411],
  [-32.9234, 151.6390],
  [-32.9240, 151.6380],
  [-32.9252, 151.6373]
];

const speersPoint = [
  [-32.9252, 151.6373],
  [-32.9259, 151.6370],
  [-32.9278, 151.6366],
  [-32.9303, 151.6362],
  [-32.9309, 151.6358],
  [-32.9344, 151.6317],
  [-32.9359, 151.6282],
  [-32.9383, 151.6258],
  [-32.9395, 151.6251],
  [-32.9401, 151.6248],
  [-32.9476, 151.6233],
  [-32.9517, 151.6234],
  [-32.9566, 151.6219],
  [-32.9568, 151.6219],
  [-32.9603, 151.6231],
  [-32.9650, 151.6225],
  [-32.9648, 151.6221]
];

const westwaratahJunctionWallsend = [
  [-32.9252, 151.6373],
  [-32.9254, 151.6369],
  [-32.9258, 151.6366],
  [-32.9260, 151.6358],
  [-32.9261, 151.6344],
  [-32.9259, 151.6304],
  [-32.9251, 151.6274],
  [-32.9241, 151.6247],
  [-32.9236, 151.6222],
  [-32.9236, 151.6215],
  [-32.9242, 151.6189],
  [-32.9243, 151.6181],
  [-32.9234, 151.6128],
  [-32.9219, 151.6095],
  [-32.9216, 151.6085],
  [-32.9213, 151.6081],
  [-32.9195, 151.6067],
  [-32.9193, 151.6063],
  [-32.9191, 151.6052],
  [-32.9189, 151.6037],
  [-32.9191, 151.6022],
  [-32.9201, 151.6005],
  [-32.9202, 151.6001],
  [-32.9200, 151.5994],
  [-32.9198, 151.5992],
  [-32.9181, 151.5982],
  [-32.9167, 151.5964],
  [-32.9161, 151.5960],
  [-32.9145, 151.5956],
  [-32.9139, 151.5953],
  [-32.9138, 151.5947],
  [-32.9139, 151.5940],
  [-32.9148, 151.5919],
  [-32.9149, 151.5912],
  [-32.9147, 151.5907],
  [-32.9148, 151.5900],
  [-32.9151, 151.5885],
  [-32.9150, 151.5879],
  [-32.9147, 151.5869],
  [-32.9146, 151.5865],
  [-32.9147, 151.5860],
  [-32.9147, 151.5854],
  [-32.9147, 151.5851],
  [-32.9145, 151.5848],
  [-32.9140, 151.5839],
  [-32.9114, 151.5814],
  [-32.9112, 151.5813],
  [-32.9109, 151.5811],
  [-32.9096, 151.5817],
  [-32.9092, 151.5816],
  [-32.9089, 151.5814],
  [-32.9077, 151.5803],
  [-32.9066, 151.5788],
  [-32.9057, 151.5774],
  [-32.9051, 151.5767],
  [-32.9043, 151.5763],
  [-32.9035, 151.5764],
  [-32.9023, 151.5777],
  [-32.9022, 151.5785]
];

const cbdWaratahJunction = [
  [-32.9263, 151.7774],
  [-32.9265, 151.7789],
  [-32.9283, 151.7865],
  [-32.9286, 151.7864],
  [-32.9288, 151.7865],
  [-32.9289, 151.7867],
  [-32.9289, 151.7884],
  [-32.9290, 151.7888],
  [-32.9292, 151.7891],
  [-32.9289, 151.7900],
  [-32.9287, 151.7900],
  [-32.9284, 151.7897],
  [-32.9281, 151.7891],
  [-32.9264, 151.7821],
  [-32.9263, 151.7810],
  [-32.9262, 151.7782],
  [-32.9267, 151.7748],
  [-32.9274, 151.7672],
  [-32.9263, 151.7619],
  [-32.9251, 151.7592],
  [-32.9245, 151.7573],
  [-32.9240, 151.7561],
  [-32.9240, 151.7553],
  [-32.9247, 151.7513],
  [-32.9244, 151.7492],
  [-32.9234, 151.7430],
  [-32.9235, 151.7425],
  [-32.9242, 151.7406],
  [-32.9242, 151.7386],
  [-32.9242, 151.7373],
  [-32.9245, 151.7367],
  [-32.9246, 151.7358],
  [-32.9236, 151.7333],
  [-32.9237, 151.7327],
  [-32.9241, 151.7315],
  [-32.9240, 151.7306]
];

const waratahJunctionWallsend = [
  [-32.9240, 151.7306],
  [-32.9231, 151.7233],
  [-32.9211, 151.7193],
  [-32.9208, 151.7185],
  [-32.9206, 151.7162],
  [-32.9205, 151.7156],
  [-32.9187, 151.7126],
  [-32.9182, 151.7121],
  [-32.9155, 151.7110],
  [-32.9142, 151.7101],
  [-32.9139, 151.7095],
  [-32.9127, 151.7017],
  [-32.9125, 151.7008],
  [-32.9066, 151.6911],
  [-32.9048, 151.6900],
  [-32.9003, 151.6874],
  [-32.8974, 151.6851],
  [-32.8968, 151.6844],
  [-32.8966, 151.6832],
  [-32.8969, 151.6807],
  [-32.8979, 151.6774],
  [-32.8984, 151.6760],
  [-32.8985, 151.6746],
  [-32.8993, 151.6736],
  [-32.8997, 151.6733],
  [-32.9008, 151.6721],
  [-32.9013, 151.6712],
  [-32.9014, 151.6708],
  [-32.9012, 151.6694],
  [-32.9017, 151.6694],
  [-32.9027, 151.6695],
  [-32.9047, 151.6657],
  [-32.9071, 151.6627]
];

const waratah = [
  [-32.9240, 151.7306],
  [-32.9234, 151.7307],
  [-32.9208, 151.7329],
  [-32.9206, 151.7331],
  [-32.9201, 151.7343],
  [-32.9165, 151.7321],
  [-32.9120, 151.7331],
  [-32.9111, 151.7322],
  [-32.9105, 151.7319],
  [-32.9096, 151.7314],
  [-32.9085, 151.7299],
  [-32.9077, 151.7284],
  [-32.9068, 151.7275],
  [-32.9051, 151.7281],
  [-32.9040, 151.7216],
  [-32.9003, 151.7224]
];

const gordonStreetDepo = [
  [-32.9247, 151.7513],
  [-32.9258, 151.7511],
  [-32.9255, 151.7490],
  [-32.9244, 151.7492]
];

export const newcastleTrams: Line = {
  name: 'Newcastle Trams',
  state: 'NSW',
  segments: [
    {
      segments: [adamstown],
      history: {
        opened: {
          date: '1900-08-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1925-02-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-04-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [adamstownExtension],
      history: {
        opened: {
          date: '1938-08-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-04-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mayfield],
      history: {
        opened: {
          date: '1893-04-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1923-12-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1948-09-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mayfieldExtension],
      history: {
        opened: {
          date: '1901-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1923-12-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1948-09-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [carrington],
      history: {
        opened: {
          date: '1912-09-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-08-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1938-11-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glebe],
      history: {
        opened: {
          date: '1894-04-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1924-11-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-02-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [merewether],
      history: {
        opened: {
          date: '1902-11-03',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1924-07-27',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-02-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [merewetherExtension],
      history: {
        opened: {
          date: '1903-09-21',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1924-07-27',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-02-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cbdWaratahJunction],
      history: {
        opened: {
          date: '1887-07-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1925-12-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-06-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waratahJunctionWallsendSpeersPointJunction],
      history: {
        opened: {
          date: '1887-07-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1925-12-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1931-05-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [speersPoint],
      history: {
        opened: {
          date: '1912-01-15',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1931-05-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westwaratahJunctionWallsend],
      history: {
        opened: {
          date: '1910-09-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1930-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waratahJunctionWallsend],
      history: {
        opened: {
          date: '1887-07-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1925-12-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1949-11-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waratah],
      history: {
        opened: {
          date: '1915-01-20',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1925-04-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-06-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [racecourse],
      history: {
        opened: {
          date: '1907-04-27',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1925-11-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-04-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portWaratah],
      history: {
        opened: {
          date: '1914-07-20',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-10-11',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1938-11-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portWaratahExtension],
      history: {
        opened: {
          date: '1916-12-16',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-10-11',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1938-11-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portWaratahWharf],
      history: {
        opened: {
          date: '1920-02-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1921-01-01', //only opened for a trial,
          status: 'closed'
        }]
      }
    },
    {
      segments: [gordonStreetDepo],
      history: {
        opened: {
          date: '1918-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-06-11',
          status: 'closed'
        }]
      }
    }
  ]
};

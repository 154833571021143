import { Line } from "../../../trackTypes";

const eagleJunctionAscot = [
  [-27.4155, 153.0510],
  [-27.4157, 153.0524],
  [-27.4167, 153.0556],
  [-27.4171, 153.0564],
  [-27.4223, 153.0629],
  [-27.4229, 153.0633],
  [-27.4236, 153.0635],
  [-27.4279, 153.0628],
  [-27.4285, 153.0628],
  [-27.4290, 153.0631],
  [-27.4298, 153.0637]
];

const ascotEagleFarm = [
  [-27.4298, 153.0637],
  [-27.4305, 153.0644],
  [-27.4309, 153.0654],
  [-27.4310, 153.0665],
  [-27.4309, 153.0706],
  [-27.4310, 153.0722],
  [-27.4316, 153.0769],
  [-27.4315, 153.0792],
  [-27.4312, 153.0806],
  [-27.4302, 153.0829]
];

const eagleFarmPinkenba = [
  [-27.4302, 153.0829],
  [-27.4295, 153.0848],
  [-27.4293, 153.0858],
  [-27.4281, 153.0991],
  [-27.4281, 153.1000],
  [-27.4294, 153.1056],
  [-27.4302, 153.1118],
  [-27.4301, 153.1132],
  [-27.4296, 153.1153],
  [-27.4266, 153.1218]
];

export const doomben: Line = {
  name: 'Doomben',
  state: 'QLD',
  segments: [
    {
      segments: [eagleJunctionAscot],
      history: {
        opened: {
          date: '1882-09-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-02-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [ascotEagleFarm],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-02-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [eagleFarmPinkenba],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}

import { Line } from "../../../../trackTypes";

const richmond = [
  [-37.81056, 145.00065],
  [-37.81870, 144.99914],
  [-37.83241, 144.99673],
  [-37.83487, 144.99655],
  [-37.83949, 144.99562]
];

const  prahran = [
  [-37.83949, 144.99562],
  [-37.84687, 144.99413],
  [-37.85186, 144.99336],
  [-37.85765, 144.99229],
  [-37.86818, 144.99039]
];

const domainRoad = [
  [-37.83283, 144.97231],
  [-37.83413, 144.98253],
  [-37.83775, 144.98178]
];

const toorakRoad = [
  [-37.83607, 144.97546],
  [-37.83642, 144.97589],
  [-37.83691, 144.97672],
  [-37.83715, 144.97744],
  [-37.83775, 144.98178]
];

const anzacSouthYarra = [
  [-37.83775, 144.98178],
  [-37.83949, 144.99562]
];

export const south2: Line = {
  name: 'South2',
  state: 'VIC',
  segments: [
    {
      segments: [richmond],
      history: {
        opened: {
          date: '1924-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [prahran],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1926-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        {
          date: '2017-01-01',
          original: [domainRoad],
          deviation: [toorakRoad]
        },
        anzacSouthYarra
      ],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1927-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};

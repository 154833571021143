import { Line } from "../../../../trackTypes";

const cbd = [
  [-34.92576, 138.59973],
  [-34.92609, 138.59389],
  [-34.92614, 138.59378],
  [-34.92624, 138.59371],
  [-34.92747, 138.59380]
];

const morphettStreetNorth = [
  [-34.92895, 138.59391],
  [-34.92747, 138.59380]
];

const morphettStreet = [
  [-34.92895, 138.59391],
  [-34.93043, 138.59401]
];

const gougerStreet = [
  [-34.93043, 138.59401],
  [-34.93074, 138.58800]
];

const westTerrace = [
  [-34.93074, 138.58800],
  [-34.93352, 138.58818]
];

const goodwoodRoadNorth = [
  [-34.93352, 138.58818],
  [-34.94186, 138.58893]
];

const goodwoodRoadSouth = [
  [-34.94186, 138.58893],
  [-34.94373, 138.58907]
];

const goodwoodRoadSouth2 = [
  [-34.94373, 138.58907],
  [-34.94998, 138.58954]
];

const goodwood = [
  [-34.94998, 138.58954],
  [-34.95561, 138.58997]
];

const clarencePark = [
  [-34.95561, 138.58997],
  [-34.96595, 138.59076]
];

const railwayterrace = [
  [-34.94366, 138.59933],
  [-34.94397, 138.59904],
  [-34.94421, 138.59880],
  [-34.94469, 138.59837],
  [-34.94545, 138.59757],
  [-34.94595, 138.59692],
  [-34.94636, 138.59630],
  [-34.94673, 138.59565],
  [-34.94976, 138.58967],
  [-34.94987, 138.58960],
  [-34.94998, 138.58954]
];

const wayvilleWest = [
  [-34.94186, 138.58893],
  [-34.94217, 138.58319]
];

const keswick = [
  [-34.94217, 138.58319],
  [-34.94220, 138.58303],
  [-34.94230, 138.58284],
  [-34.94586, 138.57977]
];

const angusRoad = [
  [-34.96595, 138.59076],
  [-34.97440, 138.59138]
];

const showgroundsLoop = [
  [-34.94196, 138.58634],
  [-34.94387, 138.58650],
  [-34.94373, 138.58907]
];

const springbankRd = [
  [-34.97440, 138.59138],
  [-34.99062, 138.59266]
];

export const south3: Line = {
  name: 'South3',
  state: 'SA',
  segments: [
    {
      segments: [cbd],
      history: {
        opened: {
          date: '1882-11-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1912-12-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [morphettStreetNorth],
      history: {
        opened: {
          date: '1882-11-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1912-12-23',
          status: 'closed'
        }, {
          date: '1952-02-09',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1956-04-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [morphettStreet],
      history: {
        opened: {
          date: '1882-11-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-10-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gougerStreet],
      history: {
        opened: {
          date: '1882-11-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1912-12-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westTerrace],
      history: {
        opened: {
          date: '1882-11-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-10-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goodwoodRoadNorth],
      history: {
        opened: {
          date: '1882-11-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1912-12-23',
          status: 'closed'
        }, {
          date: '1918-09-17',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goodwoodRoadSouth],
      history: {
        opened: {
          date: '1882-11-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1912-12-23',
          status: 'closed'
        }, {
          date: '1925-09-05',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1955-09-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goodwoodRoadSouth2],
      history: {
        opened: {
          date: '1882-11-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1912-12-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goodwood],
      history: {
        opened: {
          date: '1882-11-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1912-12-23',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [clarencePark],
      history: {
        opened: {
          date: '1882-11-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1915-03-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [railwayterrace],
      history: {
        opened: {
          date: '1912-12-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wayvilleWest],
      history: {
        opened: {
          date: '1918-09-17',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [keswick],
      history: {
        opened: {
          date: '1918-09-17',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1939-03-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [angusRoad],
      history: {
        opened: {
          date: '1923-06-03',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [showgroundsLoop],
      history: {
        opened: {
          date: '1925-09-05',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-09-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [springbankRd],
      history: {
        opened: {
          date: '1927-09-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../../trackTypes";

const fitzroyWeymouthStreet = [
  [-39.0505, 174.1046],
  [-39.0492, 174.1022],
  [-39.0498, 174.1003],
  [-39.0572, 174.0760],
  [-39.0584, 174.0717],
  [-39.0565, 174.0708],
  [-39.0565, 174.0688],
  [-39.0577, 174.0650]
];

const weymouthStreetBreakwater = [
  [-39.0577, 174.0650],
  [-39.0594, 174.0593],
  [-39.0603, 174.0556],
  [-39.0608, 174.0533],
  [-39.0607, 174.0522],
  [-39.0608, 174.0516],
  [-39.0610, 174.0510],
  [-39.0622, 174.0488],
  [-39.0629, 174.0458],
  [-39.0630, 174.0448],
  [-39.0628, 174.0405],
  [-39.0630, 174.0391],
  [-39.0628, 174.0382],
  [-39.0600, 174.0327],
  [-39.0596, 174.0322],
  [-39.0583, 174.0313]
];

const egmontStreetMorleyStreet = [
  [-39.0584, 174.0717],
  [-39.0611, 174.0630]
];

const morleyStreetDavidStreet = [
  [-39.0611, 174.0630],
  [-39.0668, 174.0659],
  [-39.0670, 174.0658],
  [-39.0673, 174.0648],
  [-39.0711, 174.0623],
  [-39.0739, 174.0587]
];

const pukekuraPark = [
  [-39.0572, 174.0760],
  [-39.0623, 174.0786],
  [-39.0625, 174.0785]
];

export const newPlymouthTrams: Line = {
  name: 'New Plymouth Trams',
  state: 'NZ',
  segments: [
    {
      segments: [pukekuraPark],
      history: {
        opened: {
          date: '1924-07-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fitzroyWeymouthStreet],
      history: {
        opened: {
          date: '1916-03-10',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [weymouthStreetBreakwater],
      history: {
        opened: {
          date: '1916-04-21',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [egmontStreetMorleyStreet],
      history: {
        opened: {
          date: '1916-05-20',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [morleyStreetDavidStreet],
      history: {
        opened: {
          date: '1923-11-20',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}

import { Line } from "../../trackTypes";

const flindersStreetSanridgeBridge = [
  [-37.8189, 144.9642],
  [-37.8210, 144.9619],
  [-37.8253, 144.9570]
];

const sandridgeBridgeStKildaJunction = [
  [-37.8253, 144.9570],
  [-37.8266, 144.9555]
];

const lightRailConversion = [
  [-37.8266, 144.9555],
  [-37.8269, 144.9550],
  [-37.8296, 144.9494],
  [-37.8373, 144.9367],
  [-37.8381, 144.9354],
  [-37.8388, 144.9345],
  [-37.8396, 144.9337],
  [-37.8409, 144.9327]
];

const stationPier = [
  [-37.8409, 144.9327],
  [-37.8417, 144.9321],
  [-37.8469, 144.9303]
];

const princesPier = [
  [-37.8373, 144.9367],
  [-37.8375, 144.9359],
  [-37.8375, 144.9351],
  [-37.8371, 144.9298],
  [-37.8373, 144.9288],
  [-37.8378, 144.9281],
  [-37.8449, 144.9256]
];

export const portMelbourne: Line = {
  name: 'Port Melbourne',
  state: 'VIC',
  segments: [
    {
      segments: [flindersStreetSanridgeBridge],
      history: {
        opened: {
          date: '1854-09-13',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1857-05-13',
          tracks: 4
        }, {
          date: '1987-10-11',
          status: 'closed'
        }, {
          date: '1919-10-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [sandridgeBridgeStKildaJunction],
      history: {
        opened: {
          date: '1854-09-13',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1857-05-13',
          tracks: 4
        }, {
          date: '1919-10-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1987-11-21',
          trackType: 'tram',
          gauge: 'standard',
          tracks: 2
        }]
      }
    },
    {
      segments: [stationPier],
      history: {
        opened: {
          date: '1854-09-13',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-11',
          status: 'closed'
        }, {
          date: '1927-01-10',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [princesPier],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-11',
          status: 'closed'
        }, {
          date: '1921-05-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-08-17',
          electrified: undefined
        }, {
          date: '1961-03-21',
          tracks: 1
        }]
      }
    },
    {
      segments: [lightRailConversion],
      history: {
        opened: {
          date: '1854-09-13',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1919-10-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1987-11-21',
          trackType: 'tram',
          gauge: 'standard'
        }]
      }
    }
  ]
}

import React, { useState } from "react";
import styled from "styled-components";
import { MapContainer, TileLayer } from "react-leaflet";
import { lines } from './trackPolyines/lines';
import Checkbox from "./components/Checkbox";
import { MapSegment } from './components/MapLine';
import { darkMap } from './mapUtils/tileLayers';
import SideMenu from "./components/SlideMenu";
import Legend from "./components/Legend";
import DonateButton from "./components/DonateButton";

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 1rem;
  height: 100%;
`;

const ToolbarItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
`;

const ToolbarText = styled.span`
  font-size: 1rem;

  @media (max-width: 600px) {
    font-size: 0.6rem;
  }

  animation: flashText 0.75s linear 3;
    @keyframes flashText {
      50% {
        color: yellow;
      }
    }
`;

const Toolbar = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1000000;
  background-color: black;
  width: 100%;
  padding: 0.5rem 0 0.5rem 0;
`;

const TimelineSlider = styled.input`
  margin: 0 1rem 0 1rem;
  width: calc(100% - 2rem);
`;

function History() {
  const [dateRange, setDateRange] = useState("1850");
  const [showAllLines, setShowAllLines] = useState(false);
  const [showUnderConstruction, setShowUnderConstruction] = useState(false);

  return (
    <div>
      <MapContainer center={[-29.3833, 146.1428]} zoom={4} scrollWheelZoom={true} style={{ height: "100vh" }}>
        <TileLayer
          url={darkMap.url}
          attribution={darkMap.attribution}
        />
        {lines.map((line) => {
          return line.segments.map((segment, index) => {
            const date = new Date(dateRange);
            return (
              <MapSegment
                key={`${line.name}${index}`}
                section={segment}
                date={date}
                showAllLines={showAllLines}
                showUnderConstruction={showUnderConstruction}
                name={line.name}
              />
            );
          });
        })}
      </MapContainer>
      <Toolbar>
        <TimelineSlider
          type="range"
          min="1850"
          max="2024"
          value={dateRange}
          onChange={(event) => setDateRange(event.target.value)}
        />
        <ToolbarContainer>
        <ToolbarItem>
          <ToolbarText>{dateRange}{' '}</ToolbarText>
        </ToolbarItem>
        <ToolbarItem>
          <ToolbarText>Don't Hide Lines If Closed</ToolbarText>
          <Checkbox
            checked={showAllLines}
            onChange={()=>setShowAllLines(!showAllLines)}
          />
        </ToolbarItem>
        <ToolbarItem>
          <ToolbarText>Show Lines Under Construction</ToolbarText>
          <Checkbox
            checked={showUnderConstruction}
            onChange={()=>setShowUnderConstruction(!showUnderConstruction)}
          />
        </ToolbarItem>
        <div style={{display: 'flex', flexGrow: '2', paddingRight: '1rem', justifyContent: 'end'}}>
          <DonateButton />
        </div>
        </ToolbarContainer>
      </Toolbar>
      <SideMenu>
        <Legend />
      </SideMenu>
    </div>
  );
}

export default History;

import { Line } from "../../trackTypes";

//maquarie group
const maquarieStreetLine = [
  [-42.8783, 147.3315],
  [-42.8788, 147.3341],
  [-42.8791, 147.3343],
  [-42.8799, 147.3338],
  [-42.8827, 147.3302],
  [-42.8827, 147.3302],
  [-42.8840, 147.3286],
  [-42.8886, 147.3227],
  [-42.8908, 147.3198],
  [-42.8918, 147.3184],
  [-42.8927, 147.3154],
  [-42.8936, 147.3116],
  [-42.8946, 147.3088],
  [-42.8954, 147.3045],
  [-42.8954, 147.3036],
  [-42.8954, 147.3031],
  [-42.8950, 147.3015],
  [-42.8950, 147.3009],
  [-42.8956, 147.2972],
  [-42.8959, 147.2953]
];

const proctorsRoadBranch = [
  [-42.8908, 147.3198],
  [-42.8921, 147.3218],
  [-42.8924, 147.3224],
  [-42.8928, 147.3225],
  [-42.8934, 147.3226],
  [-42.8954, 147.3231],
  [-42.8958, 147.3208],
  [-42.8980, 147.3177],
  [-42.8987, 147.3171],
  [-42.8995, 147.3168],
  [-42.9005, 147.3169],
  [-42.9011, 147.3172]
];

const proctorsExtension = [
  [-42.9011, 147.3172],
  [-42.9024, 147.3189]
];

//elizabeth group
const elizabethStreetLine = [
  [-42.8827, 147.3302],
  [-42.8813, 147.3271],
  [-42.8782, 147.3204],
  [-42.8778, 147.3193],
  [-42.8775, 147.3190],
  [-42.8753, 147.3177],
  [-42.8741, 147.3165],
  [-42.8722, 147.3145],
  [-42.8715, 147.3138],
  [-42.8702, 147.3116],
  [-42.8697, 147.3114],
  [-42.8689, 147.3119],
  [-42.8683, 147.3119],
  [-42.8662, 147.3111],
  [-42.8644, 147.3106],
  [-42.8636, 147.3107],
  [-42.8630, 147.3108],
  [-42.8616, 147.3100],
  [-42.8591, 147.3052],
  [-42.8585, 147.3045],
  [-42.8567, 147.3038],
  [-42.8560, 147.3036],
  [-42.8556, 147.3033],
  [-42.8515, 147.2986],
  [-42.8482, 147.2961]
];

const glenorchyBranch = [
  [-42.8482, 147.2961],
  [-42.8400, 147.2901],
  [-42.8373, 147.2881],
  [-42.8362, 147.2869],
  [-42.8345, 147.2835],
  [-42.8343, 147.2830]
];

const glenorchyExtension = [
  [-42.8343, 147.2830],
  [-42.8333, 147.2775],
  [-42.8329, 147.2745]
];

const glenorchyExtension2 = [
  [-42.8329, 147.2745],
  [-42.8334, 147.2743]
];

const lenahValleyBranch = [
  [-42.8677, 147.3117],
  [-42.8671, 147.3061],
  [-42.8665, 147.3040],
  [-42.8664, 147.3031],
  [-42.8665, 147.3018],
  [-42.8661, 147.2982],
  [-42.8654, 147.2952],
  [-42.8632, 147.2910],
  [-42.8628, 147.2893],
  [-42.8628, 147.2888]
];

const springfieldbranch = [
  [-42.8400, 147.2901],
  [-42.8437, 147.2842]
];

//sandy group
const sandyBayLine = [
  [-42.8840, 147.3286],
  [-42.8845, 147.3296],
  [-42.8861, 147.3276],
  [-42.8863, 147.3276],
  [-42.8866, 147.3281],
  [-42.8874, 147.3291],
  [-42.8884, 147.3301],
  [-42.8891, 147.3303],
  [-42.8894, 147.3303],
  [-42.8900, 147.3300],
  [-42.8905, 147.3293],
  [-42.8915, 147.3270],
  [-42.8918, 147.3265],
  [-42.8928, 147.3258],
  [-42.8934, 147.3257],
  [-42.8942, 147.3260],
  [-42.8947, 147.3266],
  [-42.8953, 147.3276],
  [-42.8957, 147.3281],
  [-42.8981, 147.3292],
  [-42.8998, 147.3307],
  [-42.9018, 147.3337],
  [-42.9024, 147.3347],
  [-42.9029, 147.3357],
  [-42.9033, 147.3363],
  [-42.9037, 147.3369],
  [-42.9046, 147.3378],
  [-42.9052, 147.3391],
  [-42.9057, 147.3399],
  [-42.9060, 147.3409],
  [-42.9071, 147.3428],
  [-42.9079, 147.3438],
  [-42.9085, 147.3457],
  [-42.9100, 147.3496],
  [-42.9106, 147.3507],
  [-42.9111, 147.3513],
  [-42.9116, 147.3525]
];

const sandyBayExtension = [
  [-42.9116, 147.3525],
  [-42.9126, 147.3539],
  [-42.9141, 147.3573],
  [-42.9143, 147.3589]
];

//liverpool group
const liverpoolStreetLine = [
  [-42.8813, 147.3271],
  [-42.8872, 147.3207],
  [-42.8865, 147.3196],
  [-42.8869, 147.3190],
  [-42.8872, 147.3173],
  [-42.8872, 147.3153],
  [-42.8868, 147.3128],
  [-42.8868, 147.3133],
  [-42.8866, 147.3136],
  [-42.8853, 147.3163],
  [-42.8837, 147.3149],
  [-42.8840, 147.3139],
  [-42.8841, 147.3129],
  [-42.8839, 147.3117],
  [-42.8830, 147.3102],
  [-42.8820, 147.3097],
  [-42.8809, 147.3098],
  [-42.8800, 147.3105],
  [-42.8796, 147.3113],
  [-42.8775, 147.3094]
];

const liverpoolExtension = [
  [-42.8775, 147.3094],
  [-42.8778, 147.3088],
  [-42.8764, 147.3074]
];

const liverpoolConnector = [
  [-42.8886, 147.3227],
  [-42.8872, 147.3207]
];

//north hobart group
const northHobartLine = [
  [-42.8783, 147.3315],
  [-42.8775, 147.3300],
  [-42.8764, 147.3277],
  [-42.8769, 147.3269],
  [-42.8735, 147.3220],
  [-42.8729, 147.3217],
  [-42.8724, 147.3217],
  [-42.8719, 147.3220],
  [-42.8716, 147.3224],
  [-42.8675, 147.3182]
];

export const hobartTrams: Line = {
  name: 'Hobart Trams',
  state: 'TAS',
  segments: [
    {
      segments: [maquarieStreetLine],
      history: {
        opened: {
          date: '1893-09-23',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-04-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [proctorsRoadBranch],
      history: {
        opened: {
          date: '1922-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-04-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [proctorsExtension],
      history: {
        opened: {
          date: '1934-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-04-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [elizabethStreetLine],
      history: {
        opened: {
          date: '1893-09-23',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glenorchyBranch],
      history: {
        opened: {
          date: '1923-03-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glenorchyExtension],
      history: {
        opened: {
          date: '1931-04-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glenorchyExtension2],
      history: {
        opened: {
          date: '1937-12-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [springfieldbranch],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lenahValleyBranch],
      history: {
        opened: {
          date: '1922-09-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandyBayLine],
      history: {
        opened: {
          date: '1893-09-23',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandyBayExtension],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [liverpoolStreetLine],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [liverpoolExtension],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [liverpoolConnector],
      history: {
        opened: {
          date: '1951-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northHobartLine],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}

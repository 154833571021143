import { Line } from "../../../trackTypes";

const depoAccess = [
  [-37.55145, 143.82029],
  [-37.55151, 143.82105],
  [-37.55146, 143.82135],
  [-37.55128, 143.82201],
  [-37.55120, 143.82212],
  [-37.55103, 143.82214]
];

const heritageLine = [
  [-37.54382, 143.82731],
  [-37.54405, 143.82647],
  [-37.54465, 143.82465],
  [-37.54497, 143.82402],
  [-37.54526, 143.82364],
  [-37.54554, 143.82338],
  [-37.54604, 143.82309],
  [-37.54703, 143.82275],
  [-37.54762, 143.82259],
  [-37.55005, 143.82205],
  [-37.55038, 143.82203],
  [-37.55074, 143.82207],
  [-37.55103, 143.82214],
  [-37.55180, 143.82241],
  [-37.55216, 143.82252],
  [-37.55237, 143.82254],
  [-37.55277, 143.82251],
  [-37.55332, 143.82236],
  [-37.55355, 143.82235]
];

const lakeWendoureeLoop = [
  [-37.55355, 143.82235],
  [-37.55546, 143.82256],
  [-37.55587, 143.82253],
  [-37.55692, 143.82228],
  [-37.55705, 143.82231],
  [-37.55714, 143.82237],
  [-37.55792, 143.82423],
  [-37.55812, 143.82489],
  [-37.56005, 143.84141],
  [-37.55805, 143.84178],
  [-37.55773, 143.84203],
  [-37.55598, 143.84233],
  [-37.55470, 143.84262],
  [-37.55410, 143.84272],
  [-37.55381, 143.84282],
  [-37.55363, 143.84300],
  [-37.55287, 143.84422],
  [-37.55264, 143.84448],
  [-37.55135, 143.84507],
  [-37.55083, 143.84516],
  [-37.54947, 143.84520],
  [-37.54655, 143.84441],
  [-37.54624, 143.84422],
  [-37.54550, 143.84330],
  [-37.54537, 143.84307],
  [-37.54529, 143.84276],
  [-37.54524, 143.84217],
  [-37.54517, 143.84179],
  [-37.54442, 143.83942],
  [-37.54382, 143.83725],
  [-37.54377, 143.83692],
  [-37.54378, 143.83672],
  [-37.54389, 143.83596],
  [-37.54394, 143.83569],
  [-37.54392, 143.83533],
  [-37.54340, 143.83102],
  [-37.54338, 143.83025],
  [-37.54361, 143.82822],
  [-37.54382, 143.82731]
];

//sebastopol line
const sturtStreetDarlingStreet = [
  [-37.56076, 143.84746],
  [-37.57556, 143.84479]
];

const darlingStreetRubiconStreet = [
  [-37.57556, 143.84479],
  [-37.57650, 143.84399],
  [-37.57704, 143.84360],
  [-37.58059, 143.84150],
  [-37.58203, 143.84093]
];

const rubiconStreetSebastopolTownHall = [
  [-37.58203, 143.84093],
  [-37.58260, 143.84082],
  [-37.59738, 143.84114]
];

const sebastopolTownHallRoyalMailHotel = [
  [-37.59738, 143.84114],
  [-37.59999, 143.84115],
  [-37.60730, 143.84014]
];

const sturtStreetSkiptonStreet = [
  [-37.56179, 143.85627],
  [-37.56699, 143.85531],
  [-37.56720, 143.85522],
  [-37.56735, 143.85505],
  [-37.57025, 143.85019],
  [-37.57067, 143.84961],
  [-37.57150, 143.84863],
  [-37.57330, 143.84671],
  [-37.57556, 143.84479]
];

//mount pleasant line
const sturtStreetGladstoneStreet = [
  [-37.56274, 143.86440],
  [-37.56279, 143.86451],
  [-37.56285, 143.86459],
  [-37.56387, 143.86543],
  [-37.56401, 143.86567],
  [-37.56485, 143.86729],
  [-37.56499, 143.86736],
  [-37.56510, 143.86732],
  [-37.56526, 143.86710],
  [-37.56850, 143.86415],
  [-37.56913, 143.86343],
  [-37.57382, 143.85783]
];

const gladstoneStreetCobdenStreet = [
  [-37.57382, 143.85783],
  [-37.57583, 143.85543]
];

//cemetary line
const sturtStreetMacarthurStreet = [
  [-37.56196, 143.85775],
  [-37.55115, 143.85975]
];

const macarthurStreetGregoryStreet = [
  [-37.55115, 143.85975],
  [-37.54574, 143.86075]
];

const gregoryStreetNewCemetary = [
  [-37.54574, 143.86075],
  [-37.53699, 143.86242]
];

const lakeWendoureeDrummondStreet = [
  [-37.54947, 143.84520],
  [-37.54994, 143.84944]
];

const macArthurStreetSturtStreet = [
  [-37.56076, 143.84746],
  [-37.54994, 143.84944]
];

const drummondStreetLydiardStreet = [
  [-37.54994, 143.84944],
  [-37.55115, 143.85975]
];

//east line
const lakeWendoureeVictoriaStreet = [
  [-37.56005, 143.84141],
  [-37.56076, 143.84746],
  [-37.56179, 143.85627],
  [-37.56196, 143.85775],
  [-37.56274, 143.86440],
  [-37.56275, 143.86528],
  [-37.56253, 143.86686],
  [-37.56161, 143.87247],
  [-37.56138, 143.87362],
  [-37.55901, 143.88415]
];

export const ballaratTrams: Line = {
  name: 'Ballarat Trams',
  state: 'VIC',
  segments: [
    {
      segments: [depoAccess],
      history: {
        opened: {
          date: '1887-12-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-08-18',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-01',
          status: 'closed'
        }, {
          date: '1975-02-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [heritageLine],
      history: {
        opened: {
          date: '1887-12-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-08-18',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-01',
          status: 'closed'
        }, {
          date: '1975-02-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [lakeWendoureeLoop],
      history: {
        opened: {
          date: '1887-12-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-08-18',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sturtStreetDarlingStreet],
      history: {
        opened: {
          date: '1888-04-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1913-08-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [darlingStreetRubiconStreet],
      history: {
        opened: {
          date: '1888-04-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1908-03-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rubiconStreetSebastopolTownHall],
      history: {
        opened: {
          date: '1893-03-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1913-08-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sebastopolTownHallRoyalMailHotel],
      history: {
        opened: {
          date: '1893-04-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1913-08-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sturtStreetSkiptonStreet],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1908-03-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1937-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sturtStreetGladstoneStreet],
      history: {
        opened: {
          date: '1905-08-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1906-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gladstoneStreetCobdenStreet],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sturtStreetMacarthurStreet],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [macarthurStreetGregoryStreet],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gregoryStreetNewCemetary],
      history: {
        opened: {
          date: '1937-08-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lakeWendoureeDrummondStreet],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [macArthurStreetSturtStreet],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [drummondStreetLydiardStreet],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lakeWendoureeVictoriaStreet],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-08-18',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    }
  ]
}

import { Line } from "../../../trackTypes";

const clydeRosehill = [
  [-33.8355, 151.0164],
  [-33.8348, 151.0159],
  [-33.8337, 151.0157],
  [-33.8330, 151.0159],
  [-33.8324, 151.0164],
  [-33.8310, 151.0185],
  [-33.8296, 151.0200],
  [-33.8281, 151.0209],
  [-33.8263, 151.0215],
  [-33.8231, 151.0221],
  [-33.8211, 151.0223],
  [-33.8205, 151.0227],
  [-33.8198, 151.0234],
];

const rosehillCamellia = [
  [-33.8198, 151.0234],
  [-33.8191, 151.0240],
  [-33.8188, 151.0242]
];

const camelliaCarlingford = [
  [-33.8188, 151.0242],
  [-33.8159, 151.0251],
  [-33.8123, 151.0272],
  [-33.8108, 151.0284],
  [-33.8091, 151.0299],
  [-33.8083, 151.0304],
  [-33.8049, 151.0317],
  [-33.8043, 151.0322],
  [-33.8039, 151.0328],
  [-33.8025, 151.0362],
  [-33.8021, 151.0376],
  [-33.8017, 151.0383],
  [-33.8015, 151.0386],
  [-33.8002, 151.0397],
  [-33.7995, 151.0399],
  [-33.7988, 151.0399],
  [-33.7974, 151.0395],
  [-33.7967, 151.0395],
  [-33.7961, 151.0398],
  [-33.7931, 151.0419],
  [-33.7925, 151.0426],
  [-33.7917, 151.0444],
  [-33.7912, 151.0451],
  [-33.7902, 151.0455],
  [-33.7892, 151.0454],
  [-33.7873, 151.0446],
  [-33.7863, 151.0445],
  [-33.7841, 151.0452],
  [-33.7833, 151.0458],
  [-33.7820, 151.0471]
];

const sandownLine = [
  [-33.8198, 151.0234],
  [-33.8194, 151.0243],
  [-33.8193, 151.0252],
  [-33.8203, 151.0355],
  [-33.8203, 151.0362],
  [-33.8200, 151.0369],
  [-33.8197, 151.0374],
  [-33.8187, 151.0386],
  [-33.8184, 151.0392],
  [-33.8183, 151.0396],
  [-33.8186, 151.0421],
];

const sandownBranchSouth = [
  [-33.8203, 151.0362],
  [-33.8204, 151.0370],
  [-33.8207, 151.0379],
  [-33.8208, 151.0384],
  [-33.8208, 151.0412],
  [-33.8209, 151.0422],
  [-33.8212, 151.0433],
  [-33.8218, 151.0448]
];

export const carlingford: Line = {
  name: 'Carlingford',
  state: 'NSW',
  segments: [
    {
      segments: [clydeRosehill],
      history: {
        opened: {
          date: '1888-11-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1936-12-12',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1902-06-19',
          tracks: 2
        }, {
          date: '2020-01-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rosehillCamellia],
      history: {
        opened: {
          date: '1888-11-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-08-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2020-01-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [camelliaCarlingford],
      history: {
        opened: {
          date: '1896-04-20',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-08-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2020-01-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandownLine],
      history: {
        opened: {
          date: '1888-11-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-08-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2002-12-01',
          electrified: undefined
        }, {
          date: '2019-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandownBranchSouth],
      history: {
        opened: {
          date: '1888-11-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-08-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2002-12-01',
          electrified: undefined
        }, {
          date: '2019-07-01',
          status: 'closed'
        }]
      }
    }
  ]
};

import { Line } from "../../../trackTypes";

const kalgoorlieBoulder = [
  [-30.7459, 121.4741],
  [-30.7468, 121.4753],
  [-30.7481, 121.4764],
  [-30.7585, 121.4863],
  [-30.7622, 121.4871],
  [-30.7694, 121.4889],
  [-30.7717, 121.4887],
  [-30.7818, 121.4887]
];

const hannanStreet = [
  [-30.7440, 121.4774],
  [-30.7431, 121.4772],
  [-30.7459, 121.4741],
  [-30.7492, 121.4706],
  [-30.7529, 121.4665],
  [-30.7587, 121.4600]
];

const maritanaStreet = [
  [-30.7402, 121.4672],
  [-30.7459, 121.4741]
];

const collinsStreet = [
  [-30.7402, 121.4672],
  [-30.7487, 121.4579]
];

const lamingtonExtension = [
  [-30.7402, 121.4672],
  [-30.7386, 121.4652],
  [-30.7385, 121.4645],
  [-30.7353, 121.4605],
  [-30.7316, 121.4646]
];

const railwayStation = [
  [-30.7466, 121.4673],
  [-30.7492, 121.4706]
];

const kalgoorlieRacecourse = [
  [-30.7529, 121.4665],
  [-30.7546, 121.4687],
  [-30.7570, 121.4661],
  [-30.7576, 121.4658],
  [-30.7585, 121.4656]
];

const southKalgoorlie = [
  [-30.7546, 121.4687],
  [-30.7589, 121.4738],
  [-30.7652, 121.4752],
  [-30.7754, 121.4814],
  [-30.7818, 121.4815]
];

const burtStreet = [
  [-30.7818, 121.4710],
  [-30.7818, 121.4815],
  [-30.7818, 121.4887],
  [-30.7818, 121.4911],
  [-30.7815, 121.4931],
  [-30.7794, 121.4986]
];

const hopkinsStreet = [
  [-30.7892, 121.4887],
  [-30.7892, 121.4710]
];

const kamballie = [
  [-30.7818, 121.4887],
  [-30.7892, 121.4887],
  [-30.7904, 121.4887],
  [-30.7904, 121.4956],
  [-30.7916, 121.4956],
  [-30.7916, 121.4999]
];

const boulderBlock = [
  [-30.7585, 121.4863],
  [-30.7672, 121.4956],
  [-30.7680, 121.4959],
  [-30.7683, 121.4967],
  [-30.7702, 121.4985],
  [-30.7726, 121.4994],
  [-30.7748, 121.4991],
  [-30.7773, 121.4983],
  [-30.7782, 121.4984],
  [-30.7790, 121.4992]
];

export const kalgoorlieTrams: Line = {
  name: 'Kalgoorlie Trams',
  state: 'WA',
  segments: [
    {
      segments: [kalgoorlieBoulder],
      history: {
        opened: {
          date: '1902-05-20',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southKalgoorlie],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1948-10-24',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hannanStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maritanaStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1949-01-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [collinsStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-05-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lamingtonExtension],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1949-01-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [railwayStation],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kalgoorlieRacecourse],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hopkinsStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kamballie],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [burtStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boulderBlock],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    }
  ]
};

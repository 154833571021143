import { Line } from "../../trackTypes";

const line = [
  [-28.0302, 153.4292],
  [-28.0306, 153.4287],
  [-28.0308, 153.4287],
  [-28.0311, 153.4290],
  [-28.0311, 153.4292],
  [-28.0310, 153.4294],
  [-28.0308, 153.4294],
  [-28.0305, 153.4292],
  [-28.0302, 153.4292],
  [-28.0298, 153.4298],
  [-28.0296, 153.4303],
  [-28.0296, 153.4307],
  [-28.0293, 153.4326],
  [-28.0293, 153.4328],
  [-28.0296, 153.4331],
  [-28.0296, 153.4332],
  [-28.0295, 153.4334],
  [-28.0294, 153.4335],
  [-28.0293, 153.4334],
  [-28.0293, 153.4328]
];

export const oasisMonorail: Line = {
  name: 'Oasis Monorail',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1989-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'monorail'
        },
        trackChange: [{
          date: '2017-01-29',
          status: 'closed'
        }]
      }
    }
  ]
}

import { Line } from "../../../../trackTypes";

const swanstonStreet = [
  [-37.81746, 144.96744],
  [-37.81156, 144.96474],
];

const collingwood = [
  [-37.81156, 144.96474],
  [-37.81083, 144.96719],
  [-37.80784, 144.96584],
  [-37.80750, 144.96577],
  [-37.79716, 144.96755],
  [-37.79747, 144.97051],
  [-37.80074, 145.00219]
];

export const east3: Line = {
  name: 'East3',
  state: 'VIC',
  segments: [
    {
      segments: [swanstonStreet],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1926-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [collingwood],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1939-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
